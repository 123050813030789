import React, { useContext, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { sanitizeRoute } from '../../Helper/calculations'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from './MurpEachRow.module.css'

const MurpEachRow = (props) => {
    const { item, count, onSuccess } = props
    const authCtx = useContext(AuthContext)
    const [isEditing, setIsEditing] = useState(false)
    const [currentUrl, setCurrentUrl] = useState(item?.pageUrl)
    const changeUrlHandler = (k) => {
        setCurrentUrl(sanitizeRoute(k))
    }
    const [isUpdating, setIsUpdating] = useState(false)

    const updateRegistrationPageUrl = async () => {
        setIsUpdating(true)
        const updateRegistrationPageUrlResponse = await fetch(BASE_URL_API + "updateRegistrationPageUrl",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    pageIdentifier: item?.pageIdentifier,
                    pageUrlToAdd: currentUrl
                })
            });

        if (!updateRegistrationPageUrlResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateRegistrationPageUrlRespo = await updateRegistrationPageUrlResponse.json()
            if (updateRegistrationPageUrlRespo.status === "success") {
                toast(updateRegistrationPageUrlRespo?.message)
                setIsEditing(false)
                onSuccess()
            } else {
                if (updateRegistrationPageUrlRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    toast(updateRegistrationPageUrlRespo?.message)
                }
            }
            console.log(updateRegistrationPageUrlRespo)
        }
        setIsUpdating(false)
    }


    return (
        <div className={isEditing === false ? styles.mainWrapper : styles.mainWrapperEditable}>
            <ToastContainer />
            <div className={styles.sn}>
                {count + 1}.
            </div>
            <div className={styles.pageUrl}>
                {isEditing === true ?
                    <input autoFocus={true} value={currentUrl}
                        onChange={(e) => changeUrlHandler(e.target.value)}
                    />
                    :
                    <div className={styles.pageUrlTxt}>
                        {`${window?.location?.origin}/ur/${item?.pageUrl}`}
                    </div>
                }
            </div>
            <div className={styles.status}>
                {item?.isActive === "1" ? <div className={styles.activeTag}>Active</div> : <div className={styles.inactiveTag}>Inactive</div>}
            </div>
            <div className={styles.actions}>
                {/* {item?.isActive === "1" ?
                    <div className={styles.deactivate}>
                        <button>De-activate</button>
                    </div>
                    :
                    <div>
                        <button>Activate</button>
                    </div>
                } */}
                {isEditing === true ?
                    <div className={styles.editing}>
                        <button className={styles.cancelEdit} onClick={() => setIsEditing(false)} >Cancel</button>
                        {isUpdating === true ? <button className={styles.updateEdit} >Updating...</button> : <button className={styles.updateEdit} onClick={() => updateRegistrationPageUrl()} >Update</button>}
                    </div>
                    :
                    <div className={styles.editNow}>
                        <button onClick={() => setIsEditing(true)} >Edit</button>
                    </div>
                }
            </div>
        </div>
    )
}

export default MurpEachRow