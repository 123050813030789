import React, { useEffect, useState } from 'react'
import { FaRupeeSign } from 'react-icons/fa';
import { MdLocalOffer } from 'react-icons/md';
import { FcExpired } from 'react-icons/fc';
import styles from '../Membership/EachCardDescriptionBuy.module.css'
import Collapsible from 'react-collapsible';
import BuyCardForm from './BuyCardForm';


const EachCardDescriptionBuy = props => {
    const item = props.selectedCard;

    const [isBuyFormOpen, setIsBuyFormOpen] = useState(false);

    const buyCardHandler = () => {
        setIsBuyFormOpen(true);
    }

    useEffect(() => {
        setIsBuyFormOpen(false)
    }, [item])
    


    return (
        <div className={styles.eachCardDetailsBigWrapper}>
            <div className={styles.cardContainer}
                
            >
                <div className={styles.cardDesignWrapper}
                    style={{
                        // animation : `rotation 2s infinite linear`
                    }}
                >
                    <div className={styles.cardDesign} style={{ backgroundImage: `url(` + item.coverImage + `)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                        {item.label} Card
                    </div>
                </div>
                <div className={styles.cardDescriptionWrapper}>
                    <div className={styles.saleableLine}>
                        <h3>
                            Get <FaRupeeSign />{parseFloat(item.worthOff) - parseFloat(item.toPay)} rupee benefit instantly!
                        </h3>
                    </div>
                </div>
                <div className={styles.actionBtnWrapper}>
                    <button className={styles.shareBtn}>Share Details</button>
                    {isBuyFormOpen === true ?
                        <button className={styles.buyBtn} onClick={() => setIsBuyFormOpen(false)}>Show Details</button>
                        :
                        <button className={styles.buyBtn} onClick={() => buyCardHandler()}>Sell Now</button>
                    }
                </div>

            </div>

            {isBuyFormOpen === false ?
                <div className={styles.termsConditionsWrapper} >
                    <div className={styles.termsContainer}
                        style={{ backgroundColor: item.backgroundColor + `60` }}
                    >
                        <div className={styles.mainTitle}>
                            {item.label}
                            <h4>
                                Get services worth <FaRupeeSign />8000/-
                            </h4>
                        </div>

                        <div className={styles.sectionWrapper}>
                            <div className={styles.sectionTitle}>
                                Benefits
                            </div>
                            <div>
                                <div className={styles.eachBenefitWrapper}>
                                    <div className={styles.eachBenefitIconWrapper}>
                                        <div className={styles.eachBenefitIcon}>
                                            <MdLocalOffer color="#ffcb08" size={24} />
                                        </div>
                                    </div>
                                    <div className={styles.eachBenefitDescriptionWrapper}>
                                        Save 1500/-
                                    </div>
                                </div>

                                <div className={styles.eachBenefitWrapper}>
                                    <div className={styles.eachBenefitIconWrapper}>
                                        <div className={styles.eachBenefitIcon}>
                                            <FcExpired color="#ffcb08" size={24} />
                                        </div>
                                    </div>
                                    <div className={styles.eachBenefitDescriptionWrapper}>
                                        Save 1500/-
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className={styles.sectionWrapper}>
                            <div className={styles.sectionTitle}>
                                Terms & Conditions
                            </div>
                            <div>
                                <ul>
                                    <li>Non-sharable / Non-transferrable</li>
                                    <li>Valid for 1 year only.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <BuyCardForm
                    item={item}
                />
            }
        </div>
    )
}

export default EachCardDescriptionBuy