import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import styles from '../../Styles/Components/Services/EachAddedAddOn.module.css'

const EachAddedAddOn = props => {
    const authCtx = useContext(AuthContext);
    let item = props.item;
    let selectedService = props.selectedService;
    const [isUpdating, setIsUpdating] = useState(false)

    let serviceDiscountedBasePrice = parseFloat(parseFloat(selectedService.basePrice) - parseFloat(parseFloat(parseFloat(selectedService.basePrice) * selectedService.offerPercentage) / 100)).toFixed(2)

    const [toBeUpdated, setToBeUpdated] = useState('');
    const [toBeUpdatedValue, setToBeUpdatedValue] = useState('');

    const updateBasePriceHandler = () => {
        setToBeUpdated('orgPrice');
        setToBeUpdatedValue(item.orgPrice)
    }
    const updateDisPriceHandler = async () => {
        setToBeUpdated('disPrice');
        setToBeUpdatedValue(item.disPrice)
    }
    // console.log(authCtx.token)

    const updateAddOnPrices = async () => {
        setIsUpdating(true)
        const updateAddOnPricesResponse = await fetch(BASE_URL_API + "updateAddOnPrices",
            {
                method: "POST",
                body: JSON.stringify({
                    token : authCtx.token,
                    labelId: item.labelId,
                    toBeUpdated: toBeUpdated,
                    toBeUpdatedValue: toBeUpdatedValue
                })
            });

        if (!updateAddOnPricesResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const updateAddOnPricesRespo = await updateAddOnPricesResponse.json();
            if (updateAddOnPricesRespo.status === "success") {
                setToBeUpdated('')

            } else {
                console.log(updateAddOnPricesRespo.message);
            }
            console.log(updateAddOnPricesRespo)
        }
        setIsUpdating(false)
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.header}>
                <div>
                    {item.label}
                </div>
                <div className={styles.psideBySide}>
                    <div onDoubleClick={() => updateBasePriceHandler()}>
                        ₹{item.orgPrice}
                    </div>
                    <div onDoubleClick={() => updateDisPriceHandler()}>
                        ₹{item.disPrice}
                    </div>
                </div>
            </div>
            {toBeUpdated != '' &&
                <div className={styles.toBeUpdatedForm}>
                    <div className={styles.updatingWhat}>
                        Update {toBeUpdated}
                    </div>
                    <div className={styles.updatingForm}>
                        <input value={toBeUpdatedValue}
                            onChange={(e) => setToBeUpdatedValue(e.target.value)}
                        />
                        {isUpdating == false &&
                            <button
                                onClick={() => updateAddOnPrices()}
                            >
                                Update
                            </button>}
                        <button onClick={() => setToBeUpdated('')}>Cancel</button>
                    </div>
                </div>
            }
            <div>
                <div className={styles.basePriceSummary}>
                    <div>
                        Services Base Price
                    </div>
                    <div className={styles.psideBySide}>
                        <div>
                            +{selectedService.basePrice}
                        </div>
                        <div>
                            +{serviceDiscountedBasePrice}
                        </div>
                    </div>
                </div>
                <div className={styles.finalPriceSummary}>
                    <div>
                        Final Price
                    </div>
                    <div className={styles.psideBySide}>
                        <div className={styles.fpOrg}

                        >
                            ₹{parseFloat(toBeUpdated=='orgPrice' ? toBeUpdatedValue : item.orgPrice) + parseFloat(selectedService.basePrice)}
                        </div>
                        <div className={styles.fpDis}>
                            ₹{parseFloat(toBeUpdated=='disPrice' ? toBeUpdatedValue : item.disPrice) + parseFloat(serviceDiscountedBasePrice)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EachAddedAddOn