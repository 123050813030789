import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from './AddNewStaff.module.css'

const AddNewStaff = (props) => {
    const { isOpen, onClose, onSuccess } = props
    window.onclick = event => event.target.id === "ansmcid" ? onClose() : null
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = JSON.parse(asCtx?.activeStore)

    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [role, setRole] = useState('')
    const [isAdding, setIsAdding] = useState(false)

    const addNewEmployee = async () => {
        setIsAdding(true)
        const addNewEmployeeResponse = await fetch(BASE_URL_API + "addNewEmployee",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    roleType: role,
                    firstName: firstName,
                    lastName: lastName,
                    mobile: phoneNumber,
                    email: email
                })
            });

        if (!addNewEmployeeResponse.ok) {
            console.log('Something went wrong : Server Error')
        } else {
            const addNewEmployeeRespo = await addNewEmployeeResponse.json()
            if (addNewEmployeeRespo.status === "success") {
                onSuccess()
            } else {
                if (addNewEmployeeRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(addNewEmployeeRespo)
        }
        setIsAdding(false)
    }



    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} id="ansmcid">
                    <div className={styles.mainContent}>
                        <div>
                            <div className={styles.title}>
                                <h2>Add New Staff</h2>
                            </div>
                            <div className={styles.subTitle}>
                                <p>
                                    Create a new employee account
                                </p>
                            </div>
                        </div>
                        <div className={styles.rowWrapper}>
                            <div className={styles.eachRow}>
                                <div>
                                    <input
                                        placeholder="Please enter first name..." autoFocus={true}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        value={firstName}
                                    />
                                </div>
                                <div>
                                    <input placeholder="Please enter last name..."
                                        onChange={(e) => setLastName(e.target.value)}
                                        value={lastName}
                                    />
                                </div>
                            </div>
                            <div className={styles.eachRow}>
                                <div>
                                    <input placeholder="Phone number"
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        value={phoneNumber}
                                    />
                                </div>
                                <div>
                                    <input placeholder="E-mail ID"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                </div>
                            </div>
                            <div className={styles.eachRow}>
                                <select onChange={(e) => setRole(e.target.value)}
                                    defaultValue={'ADMIN'}
                                >
                                    <option value={``}>Select Role</option>
                                    <option value="ADMIN">Admin</option>
                                    <option value="MANAGER">Manager</option>
                                    <option value="STAFF">Staff</option>
                                    <option value="DOCTOR">Doctor</option>
                                </select>
                            </div>
                            <div className={styles.createNowBtn}>
                                {isAdding === true ?
                                    <button>Please wait...</button>
                                    :
                                    <button onClick={() => addNewEmployee()}>Create</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddNewStaff