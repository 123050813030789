import React, { useState } from 'react'
import ManageEachStaffAttendance from './ManageEachStaffAttendance'
import ManageEachStaffHeader from './ManageEachStaffHeader'
import ManageEachStaffWork from './ManageEachStaffWork'
import styles from './ManageEachStaffWrapper.module.css'

const ManageEachStaffWrapper = props => {
    const { selectedStaff, dates } = props
    const [activeTab, setActiveTab] = useState('Attendance')
    const tabToggler = k => setActiveTab(k)

    return (
        <div className={styles.mainWrapper}>
            <ManageEachStaffHeader activeTab={activeTab} tabToggler={tabToggler} />
            {activeTab==='Attendance' &&  <ManageEachStaffAttendance dates={dates} selectedStaff={selectedStaff}  />}
            {activeTab==='Work' &&  <ManageEachStaffWork dates={dates} selectedStaff={selectedStaff}  />}
        </div>
    )
}

export default ManageEachStaffWrapper