import React, { useContext, useState } from "react";
import ActiveStoreContext from "./ActiveStoreContext";

const AuthContext = React.createContext({
    token: localStorage.getItem("token"),
    isLoggedIn: false,
    authIdentifier : localStorage.getItem("authIdentifier"),
    loginData : localStorage.getItem("loginData"),
    login: (token) => { },
    logout: () => { }
});

export const AuthContextProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [authIdentifier, setAuthIdentifier] = useState(localStorage.getItem('authIdentifier'));
    const [loginData, setLoginData] = useState(localStorage.getItem('loginData'));
    const userIsLoggedIn = !!token;
    const asCtx = useContext(ActiveStoreContext)

    const loginHandler = (token, authIdentifier, loginData) => {
        localStorage.setItem("token", token);
        localStorage.setItem("authIdentifier", authIdentifier);
        localStorage.setItem("loginData", loginData);
        setToken(token);
        setAuthIdentifier(authIdentifier);
        setLoginData(loginData)
    }

    const logoutHandler = () => {
        if (asCtx) {
            asCtx.changeStore(null); // Ensure asCtx is not null or undefined
            setToken(null);
            setAuthIdentifier(null);
            setLoginData(null);
            localStorage.removeItem("activeStore");
            localStorage.removeItem("token");
            localStorage.removeItem("authIdentifier");
            localStorage.removeItem("loginData");
        } else {
            console.error("ActiveStoreContext is not available.");
        }
    };
    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        authIdentifier : authIdentifier,
        loginData : loginData,
        login: loginHandler,
        logout: logoutHandler
    }

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export default AuthContext;