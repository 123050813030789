import React, { useState } from 'react'
import { FaUser } from 'react-icons/fa'
import { getAge } from '../../Helper/calculations'
import styles from './EachChooseableClient.module.css'

const EachChooseableClient = (props) => {
    const { item, assignClient } = props
    const [isMembersVisible, setIsMembersVisible] = useState(false)
    return (
        <div className={styles.eachClientWrapper}>
            <div className={styles.clientSummaryWrapper}>
                <div className={styles.userIcon}>
                    <FaUser size={20} color="#027FFF" />
                </div>
                <div className={styles.clientDetails} onClick={() => assignClient(item)}>
                    <div className={styles.clientName}>
                        {item?.client_name}
                    </div>
                    <div className={styles.clientPhone}>
                        {item?.mobile}
                    </div>
                    <div className={styles.clientRemarks}>
                        {item?.gender}
                    </div>
                </div>
                <div className={styles.fmCountWrapper}>
                    <div className={styles.fmCountContainer} onClick={() => setIsMembersVisible(!isMembersVisible)}>
                        +{JSON.parse(item?.relatives)?.length} family members
                    </div>
                </div>
            </div>
            {isMembersVisible === true &&
                <div className={styles.membersWrapper}>
                    <div className={styles.mwTitle}>
                        Family Members
                    </div>
                    {JSON.parse(item?.relatives)?.map((itemRelative, index) => (
                        <div key={index} className={styles.eachRelative}
                            onClick={() => {
                                const clientStoreRelationIdentifier = item?.clientStoreRelationIdentifier || 'Unknown';
                                const relativeIdentifier = itemRelative?.relativeIdentifier || 'Unknown';

                                // Safely handle firstName, middleName, and lastName concatenation
                                const firstName = itemRelative?.firstName || '';
                                const middleName = itemRelative?.middleName || '';
                                const lastName = itemRelative?.lastName || '';
                                const clientName = `${itemRelative?.salutation} ${firstName} ${middleName} ${lastName}`.trim(); // Trim to avoid extra spaces if any part is missing

                                // Use mobile from the main object, fallback to relative if not available
                                const mobile = item?.mobile || itemRelative?.mobile || 'N/A'; // Handle case if both are missing
                                const k = {
                                    clientStoreRelationIdentifier,
                                    relativeIdentifier,
                                    client_name: clientName || 'Unknown',  // Use default if no name is available
                                    mobile
                                }
                                // Only log if all identifiers are available (or log default values)
                                assignClient(k)
                            }}
                        >
                            <div className={styles.rFullName}>
                                {itemRelative?.salutation} {`${itemRelative?.firstName} ${itemRelative?.middleName} ${itemRelative?.lastName}`.trim()}
                            </div>
                            <div className={styles.rAge}>
                                {getAge(itemRelative?.dateOfBirth)} years old
                            </div>
                            <div className={styles.rGender}>
                                {itemRelative?.gender}
                            </div>
                            <div className={styles.relationToFamilyHead}>
                                {itemRelative?.relationToFamilyHead}
                            </div>
                        </div>

                    ))}
                </div>
            }
        </div>
    )
}

export default EachChooseableClient