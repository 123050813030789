import React, { useContext, useEffect, useState } from 'react'
import CSEachBookings from '../../Components/Bookings/CSEachBookings';
import { BASE_URL_API } from '../../References/URLs';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AuthContext from '../../store/auth-context';
import styles from '../Bookings/CurrentSessions.module.css'

const CurrentSessions = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [currentSessions, setCurrentSessions] = useState([]);
    const [completedSessions, setCompletedSessions] = useState([])
    const [activeTab, setActiveTab] = useState('CURRENT'); //COMPLETED

    const getCurrentSessions = async () => {
        const getCurrentSessionsResponse = await fetch(BASE_URL_API + "getCurrentSessions",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getCurrentSessionsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getCurrentSessionsRespo = await getCurrentSessionsResponse.json();
            if (getCurrentSessionsRespo.status === "success") {
                getCurrentSessionsRespo.response[0]!==null ? setCurrentSessions(getCurrentSessionsRespo.response[0]) : setCurrentSessions([])
                getCurrentSessionsRespo.response[1]!==null ? setCompletedSessions(getCurrentSessionsRespo.response[1]) : setCompletedSessions([]);

            } else {
                if (getCurrentSessionsRespo.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
            console.log(getCurrentSessionsRespo);
        }
    }

    useEffect(() => {
        if(activeStore!==null) {
            getCurrentSessions()
        }
    }, [activeStore?.storeIdentifier])

    console.log(completedSessions)

    if(activeStore===null) {
        return(
            <div className={styles.mainWrapper}>
                Please select a store!
            </div>
        )
    }

    return (
        <div className={styles.mainWrapper}>
            <div>
                <div className={styles.mainHeader}>
                    <button className={activeTab==="CURRENT" ? styles.tabBtnActive : styles.tabBtn} onClick={() => setActiveTab('CURRENT')}>Current</button>
                    <button className={activeTab==="COMPLETED" ? styles.tabBtnActive : styles.tabBtn} onClick={() => setActiveTab('COMPLETED')}>Completed</button>
                </div>
                <div className={styles.mainBody}>
                    {activeTab === 'CURRENT' &&
                        <div className={styles.eachSectionWrapper}>
                            <div>
                                {/* Active */}
                            </div>
                            <div>
                                {currentSessions.map((item, index) => (
                                    <CSEachBookings item={item} key={index} />
                                ))}
                            </div>
                        </div>
                    }
                    {activeTab === 'COMPLETED' &&
                        <div className={styles.eachSectionWrapper}>
                            <div>
                                {/* Completed */}
                            </div>
                            <div>
                                {completedSessions.map((item, index) => (
                                    <CSEachBookings item={item} key={index} />
                                ))}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default CurrentSessions