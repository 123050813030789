import React, { useEffect, useState } from 'react'
import { FaUser } from 'react-icons/fa';
import styles from '../Bookings/ServicesListAtBD.module.css'

const ServicesListAtBD = props => {
    const bookingDetails = props.bookingDetails;
    const [orgPriceTotal, setOrgPriceTotal] = useState(0)
    const [disPriceTotal, setDisPriceTotal] = useState(0)

    const calculateSum = () => {
        let tOrgPrice = 0;
        let tDisPrice = 0;
        bookingDetails?.services?.map((item, index) => {
            tOrgPrice = parseFloat(tOrgPrice) + parseFloat(parseFloat(item.totalBasePrice) * parseFloat(item.quantity));
            tDisPrice = parseFloat(tDisPrice) + parseFloat(parseFloat(item.totalDiscPrice) * parseFloat(item.quantity));
        })
        setOrgPriceTotal(tOrgPrice)
        setDisPriceTotal(tDisPrice)
    }

    useEffect(() => {
        if(bookingDetails.services!==null) {
            calculateSum()
        }
    }, [bookingDetails])

    if(bookingDetails.services===null) {
        return(
            <div>
                No service added yet!
            </div>
        )
    }

    return (
        <div className={styles.mainWrapper}>
            {bookingDetails?.services?.map((item, index) => (
                <div key={"m" + index} className={styles.eachServiceWrapper}>
                    <div className={styles.leftCol}>
                        <div className={styles.serviceNameRow}>
                            <div className={styles.serviceName}>
                                {item.serviceName} x {item.quantity}
                            </div>
                            <div className={styles.priceWrapper}>
                                <div className={styles.basePrice}>
                                    &#8377;{item.basePrice}
                                </div>
                                <div className={styles.baseDiscountedPrice}>
                                    &#8377;{item.baseDiscountedPrice}
                                </div>
                            </div>
                        </div>
                        <div>
                            {item?.addOns?.map((itemAdd, index) => (
                                <div key={index} className={styles.addOnWrapper}>
                                    <div className={styles.addOnLabel}>
                                        {itemAdd.addOnLabel}
                                    </div>
                                    <div className={styles.addOnPriceWrapper}>
                                        <div className={styles.addOnOrgPrice}>
                                            &#8377;{itemAdd.addOnOrgPrice}
                                        </div>
                                        <div className={styles.addOnDisPrice}>
                                            &#8377;{itemAdd.addOnDisPrice}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.eachServiceFinalPriceWrapper}>
                            <div>
                                Total:
                            </div>
                            <div className={styles.priceWrapper}>
                                <div className={styles.totalOrgPrice}>
                                    &#8377;{parseFloat(item.totalBasePrice) * parseFloat(item.quantity)}
                                </div>
                                <div className={styles.totalFinalPrice}>
                                    &#8377;{parseFloat(item.totalDiscPrice) * parseFloat(item.quantity)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightCol}>
                        {item?.addOns?.map((itemStaff, indexStaff) => (
                            <div className={styles.whoWrapper} key={"Ran"+indexStaff}>
                                <div className={styles.whoWrapperEachCol}>
                                    <div className={styles.staffIcon}>
                                        <FaUser size={24} color="#a4a4a4" />
                                    </div>
                                    <div>
                                        <div className={styles.whoName}>
                                            {itemStaff.consultedByName}
                                        </div>
                                        <div className={styles.colTitle}>
                                            Consulted By
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.whoWrapperEachCol}>
                                    <div className={styles.staffIcon}>
                                        <FaUser size={24} color="#a4a4a4" />
                                    </div>
                                    <div>
                                        <div className={styles.whoName}>
                                            {itemStaff.servedByName}
                                        </div>
                                        <div className={styles.colTitle}>
                                            Served By
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
            <div className={styles.thatsAll}>
                That's All!
            </div>
            <div className={styles.bottomTotalWrap}>
                <div>
                    Grand Total
                </div>
                <div className={styles.finalPriceTagsWrapper}>
                    <div className={styles.orgPriceTotal}>
                        &#8377;{orgPriceTotal}
                    </div>
                    <div className={styles.disPriceTotal}>
                        &#8377;{disPriceTotal}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesListAtBD