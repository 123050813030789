import React, { useRef, useEffect, useState, useContext } from 'react';
import { sanitizeToPositiveNumber } from '../../Helper/calculations';
import { BASE_URL_API } from '../../References/URLs';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AuthContext from '../../store/auth-context';
import styles from './CollectLedgerBalance.module.css';

const CollectLedgerBalance = (props) => {
    const { isOpen, onClose, clientStoreRelationIdentifier, onSuccess } = props;
    const modalRef = useRef(null); // Create a ref for the modal
    const [amount, setAmount] = useState('')
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [isUpdating, setIsUpdating] = useState(false)

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the clicked element is outside the modal
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose(); // Trigger close if clicked outside
            }
        };

        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                onClose(); // Trigger close on Escape key press
            }
        };

        // Attach event listeners for both clicks and keydowns
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscape);

        // Clean up the event listeners when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);

    const collectLedgerBalance = async () => {
        if(window.confirm("Are you sure, you want to update the ledger!")) {
            setIsUpdating(true)
            const collectLedgerBalanceResponse = await fetch(BASE_URL_API + "collectLedgerBalance",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx?.token,
                        clientStoreRelationIdentifier: clientStoreRelationIdentifier,
                        amount: amount
                    })
                });
    
            if (!collectLedgerBalanceResponse?.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const collectLedgerBalanceRespo = await collectLedgerBalanceResponse.json()
                if (collectLedgerBalanceRespo?.status === "success") {
                    onSuccess()
                } else {
                    if (collectLedgerBalanceRespo?.message === "tokenExpired") {
                        authCtx?.logout()
                    } else {
                        console.log(collectLedgerBalanceRespo)
                    }
                }
            }
            setIsUpdating(false)
        }
    }



    if (!isOpen) {
        return null;
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} >
                    <div className={styles.mainContent} ref={modalRef}>
                        <div className={styles.collectBalance}>
                            Collect Balance
                        </div>
                        <div>
                            <div className={styles.amountInput}>
                                <input placeholder="Enter Amount..." autoFocus type="number"
                                    value={amount}
                                    onChange={(e) => setAmount(sanitizeToPositiveNumber(e.target.value))}
                                />
                            </div>
                        </div>
                        <div className={styles.actionsWrapper}>
                            {isUpdating === true ?
                                <div className={styles.updateBtn}>
                                    <button>Updating...</button>
                                </div>
                                :
                                <div className={styles.updateBtn}>
                                    <button onClick={() => collectLedgerBalance()} >Update</button>
                                </div>
                            }
                            <div className={styles.cancelBtn}>
                                <button>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default CollectLedgerBalance;
