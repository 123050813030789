import React, { useState } from 'react'
import styles from './ManageEachStaffHeader.module.css'

const ManageEachStaffHeader = props => {
    const { activeTab, tabToggler } = props;
    return (
        <div className={styles.mainWrapper} >
            <button className={activeTab!=="Attendance" ? styles.tab : styles.activeTab}
                onClick={()=> tabToggler("Attendance")}
            >
                Attendance
            </button>
            <button className={activeTab!=="Work" ? styles.tab : styles.activeTab}
                onClick={()=> tabToggler("Work")}
            >
                Work
            </button>
            <button className={activeTab!=="Incentive" ? styles.tab : styles.activeTab}
                onClick={()=> tabToggler("Incentive")}
            >
                Incentive
            </button>
            <button className={activeTab!=="Performance" ? styles.tab : styles.activeTab}
                onClick={()=> tabToggler("Performance")}
            >
                Performance
            </button>
        </div>
        
    )
}

export default ManageEachStaffHeader