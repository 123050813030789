import React, { createContext, useContext, useState } from 'react';

const ActiveStoreContext = createContext();

export const useActiveStore = () => useContext(ActiveStoreContext);

export const ActiveStoreProvider = ({ children }) => {
    const [activeStore, setActiveStore] = useState(() => {
        // Retrieve the language preference from localStorage, or default to 'en' (English)
        return localStorage.getItem('activeStore') || null;
    });

    const changeStore = (store) => {
        if(store!==null) {
            setActiveStore(JSON.stringify(store));
            localStorage.setItem('activeStore', JSON.stringify(store)); // Save language preference to localStorage
        } else {
            setActiveStore(null)
            localStorage.removeItem('activeStore')
        }
        console.log("setting new active store : ", store)
    };

    return (
        <ActiveStoreContext.Provider value={{ activeStore, changeStore }}>
            {children}
        </ActiveStoreContext.Provider>
    );
};


export default ActiveStoreContext;