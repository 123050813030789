import React, { useContext } from 'react'
import styles from './StoresHeader.module.css'
import { Link, useParams } from 'react-router-dom'
import ActiveStoreContext from '../../store/ActiveStoreContext'

const StoresHeader = (props) => {
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = JSON.parse(asCtx?.activeStore)
    const { storeIdentifier } = useParams();
    const pn = window.location.pathname
    const apn = pn.split('/')
    const crpn = apn[apn.length - 1]

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.leftCol}>
                    <div className={styles.storeName}>{activeStore?.storeName}</div>
                    <div className={styles.storeAddress}>{activeStore?.storeAddress}</div>
                </div>
                <div className={styles.rightCol}>
                    <Link to={`/manage/details`} className={crpn === 'details' ? styles.activeNav : styles.defNav} >Details</Link>
                    <Link to={`/manage/employees`} className={crpn === 'employees' ? styles.activeNav : styles.defNav} >Employees</Link>
                    <Link to={`/manage/price-list`} className={crpn === 'price-list' ? styles.activeNav : styles.defNav} >Price List</Link>
                    <Link to={`/manage/clients`} className={crpn === 'clients' ? styles.activeNav : styles.defNav} >Clients</Link>
                </div>
            </div>
        </div>
    )
}

export default StoresHeader