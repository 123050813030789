import React, { useContext, useState } from 'react'
import ChooseClient from '../../Components/Sessions/ChooseClient'
import AddNewClient from '../../Components/Users/AddNewClient'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from './NewFutureBooking.module.css'

const NewFutureBooking = (props) => {
    const { isOpen, onClose, onSuccess, slotInfo } = props
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [isCreatingEvent, setIsCreatingEvent] = useState(false)
    const [title, setTitle] = useState('')
    const [start, setStart] = useState('')
    const [servicesNotes, setServicesNotes] = useState('')
    const [remarks, setRemarks] = useState('')

    const [chooseClientOpen, setChooseClientOpen] = useState(false)
    const [isUserFormOpen, setIsUserFormOpen] = useState(false)
    const [userDetailsFetched, setUserDetailsFetched] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState({
        otp: ''
    })
    const [clientStoreRelationIdentifier, setClientStoreRelationIdentifier] = useState('')
    const [selectedUser, setSelectedUser] = useState(null)
    const userFormToggle = (k) => setIsUserFormOpen(k)
    const closeModal = () => setIsUserFormOpen(false)
    const abortClientSelection = () => setChooseClientOpen(false)
    const assignClient = (k) => {
        selectUserForBookingHandler(k)
        setClientStoreRelationIdentifier(k)
        abortClientSelection()
    }

    const selectUserForBookingHandler = async (k) => {
        setSelectedUser(k)
        console.log("printing k array : ", k)
    }

    window.onclick = event => event.target.id === "ipshidSuper" ? onClose() : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });

    const [timeSlot, setTimeSlot] = useState(() => {
        if (!slotInfo?.start) return "";

        const localStart = new Date(slotInfo.start);
        // Convert to the format 'YYYY-MM-DDTHH:MM' expected by datetime-local input
        localStart.setDate(localStart.getDate() + 1);

        return localStart.toISOString().slice(0, 16);
    });

    const makeReservations = async () => {
        setIsCreatingEvent(true)
        const makeReservationsResponse = await fetch(BASE_URL_API + "makeReservations",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    clientStoreRelationIdentifier : selectedUser?.clientStoreRelationIdentifier,
                    clientName : selectedUser?.client_name,
                    title: title,
                    start: timeSlot,
                    servicesNotes: servicesNotes,
                    remarks: remarks,
                })
            });

        if (!makeReservationsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const makeReservationsRespo = await makeReservationsResponse.json()
            if (makeReservationsRespo.status === "success") {
                onSuccess()
            } else {
                if (makeReservationsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(makeReservationsRespo)
        }
        setIsCreatingEvent(false)
    }



    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <AddNewClient
                    isOpen={isUserFormOpen}
                    closeModal={closeModal}
                    phone={phoneNumber.otp}
                    assignClient={assignClient}
                />
                <ChooseClient
                    isOpen={chooseClientOpen}
                    abortClientSelection={abortClientSelection}
                    assignClient={assignClient}
                    userFormToggle={userFormToggle}
                />
                <div className={styles.mainContainer} id="ipshidSuper" >
                    <div className={styles.mainContent}>
                        <div className={styles.headerContent}>
                            Make a Reservation
                        </div>
                        <div className={styles.bodyContent}>
                            <div className={styles.fieldWrapper}>
                                <div className={styles.eachInputWrapper}>
                                    {selectedUser !== null ?
                                        <div onClick={() => setChooseClientOpen(true)}
                                            className={styles.selectedUser}
                                            title="Click to change"
                                        >
                                            <div className={styles.clientName}>
                                                {selectedUser?.client_name}
                                            </div>
                                            <div className={styles.clientMobile}>
                                                {selectedUser?.mobile}
                                            </div>
                                        </div>
                                        :
                                        <input placeholder="Enter client phone number..."
                                            // value={clien}
                                            onFocus={() => setChooseClientOpen(true)}
                                        />
                                    }
                                </div>
                                <div className={styles.eachInputWrapper}>
                                    <textarea placeholder="Add services"
                                        value={servicesNotes}
                                        onChange={(e) => setServicesNotes(e.target.value)}
                                    />
                                </div>
                                <div className={styles.eachInputWrapper}>
                                    <textarea placeholder="Add remarks.."
                                        value={remarks}
                                        onChange={(e) => setRemarks(e.target.value)}
                                    />
                                </div>
                                <div className={styles.eachInputWrapper}>
                                    <input type="datetime-local"
                                        value={timeSlot}
                                        onChange={(e) => setTimeSlot(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.footerContent}>
                            {isCreatingEvent === true ?
                                <button >Creating Now...</button>
                                :
                                <button onClick={() => makeReservations()} >Create Now</button>
                            }
                            <div onClick={() => onClose()} className={styles.closeBtn}>
                                Close
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default NewFutureBooking
