import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import styles from '../../Styles/Components/Services/EachServiceAddOns.module.css'
import EachAddedAddOn from './EachAddedAddOn'

const EachServiceAddOns = props => {
    const authCtx = useContext(AuthContext);
    let selectedService = props.selectedService;
    const [addOnsData, setAddOnsData] = useState(null);
    const [isFetchingAddOns, setIsFetchingAddOns] = useState(false)
    const [showAddNewForm, setShowAddNewForm] = useState(false)
    const [label, setLabel] = useState('');
    const [orgPrice, setOrgPrice] = useState(0);
    const [disPrice, setDisPrice] = useState(0);
    const [isAddOnAdding, setIsAddOnAdding] = useState(false);

    let serviceDiscountedBasePrice = 0;

    if (selectedService != null) {
        serviceDiscountedBasePrice = parseFloat(parseFloat(selectedService.basePrice) - parseFloat(parseFloat(parseFloat(selectedService.basePrice) * selectedService.offerPercentage) / 100)).toFixed(2)
    }


    const getServicesAddOns = async () => {
        if (selectedService != null) {
            setIsFetchingAddOns(true);
            const getServicesAddOnsResponse = await fetch(BASE_URL_API + "getServicesAddOns",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token : authCtx.token,
                        serviceIdentifier: selectedService.identifier
                    })
                });

            if (!getServicesAddOnsResponse.ok) {
                console.log("Something went wrong : Server Error");
            } else {
                const getServicesAddOnsRespo = await getServicesAddOnsResponse.json();
                if (getServicesAddOnsRespo.status == "success") {
                    setAddOnsData(getServicesAddOnsRespo.response);
                } else {
                    setAddOnsData(null)
                    console.log(getServicesAddOnsRespo.response);
                }
            }
            setIsFetchingAddOns(false);
        } else {
            console.log("No services has been selected!")
        }
    }

    useEffect(() => {
        getServicesAddOns();
    }, [selectedService])

    // console.log("Add On", addOnsData)
    const addAddOnInitiate = () => {
        setShowAddNewForm(true)
    }

    const cancelAddOnHandler = () => {
        setShowAddNewForm(false)
    }

    const addAddOnHandler = async () => {
        if (selectedService != null) {
            if (label != '') {
                setIsAddOnAdding(true);
                let addOnCategory = 'PRODUCT';
                let addOnType = 'SINGLECHOICE';

                const addServiceAddOnResponse = await fetch(BASE_URL_API + "addServiceAddOn",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            token : authCtx.token,
                            addOnCategory: addOnCategory,
                            subcategoryId: selectedService.identifier,
                            addOnType: addOnType,
                            label: label,
                            orgPrice: orgPrice,
                            disPrice: disPrice
                        })
                    });

                if (!addServiceAddOnResponse.ok) {
                    console.log("Something went wrong : Server Error!");
                } else {
                    const addServiceAddOnRespo = await addServiceAddOnResponse.json();
                    if (addServiceAddOnRespo.status == "success") {
                        getServicesAddOns();
                        setLabel('');
                        setOrgPrice(0);
                        setDisPrice(0);
                        setShowAddNewForm(false)
                    } else {
                        console.log(addServiceAddOnRespo.message)
                    }
                }
                setIsAddOnAdding(false)

            } else {
                alert("Please enter add on label!")
            }
        } else {
            alert("Selected service invalid!");
        }
        let labelId = '';
    }

    if (selectedService == null) {
        return (
            <div> -- </div>
        )
    } else {
        return (
            <div>
                <div className={styles.addOnCatTitle}>
                    Add-Ons
                </div>
                {isFetchingAddOns == false ?
                    <>
                        {addOnsData != null ?
                            <div className={styles.addOnClientsContainer}>
                                {addOnsData.map((item, index) => (
                                    <EachAddedAddOn
                                        key={index}
                                        item={item}
                                        selectedService={selectedService}
                                    />
                                ))}
                            </div>
                            :
                            <div className={styles.noAddOnsFound}>
                                No add-ons added for this service!
                            </div>
                        }
                        {showAddNewForm == false ?
                            <div className={styles.eachAddOnAdd}>
                                <button className={styles.eachAddOnAddBtn}
                                    onClick={() => addAddOnInitiate()}
                                >
                                    Add New Add On
                                </button>
                            </div>
                            :
                            <div className={styles.newAddOnContainer}>
                                <div className={styles.brandNameContainer}>
                                    <input
                                        placeholder="Enter brand name..."
                                        onChange={(e) => setLabel(e.target.value)}
                                        value={label}
                                    />
                                </div>
                                <div className={styles.addOnPriceContainer}>
                                    <input
                                        placeholder="Org price..."
                                        onChange={(e) => setOrgPrice(e.target.value)}
                                        value={orgPrice}
                                    />
                                    <input
                                        placeholder="Dis price..."
                                        onChange={(e) => setDisPrice(e.target.value)}
                                        value={disPrice}
                                    />
                                </div>
                                {selectedService != null &&
                                    <div className={styles.dynamicPriceContainer}>
                                        <div className={styles.sideBySideBase}>
                                            <div>
                                                Service Base Prices
                                            </div>
                                            <div className={styles.pSideBySide}>
                                                <div>
                                                    ₹{selectedService != null && selectedService.basePrice}
                                                </div>
                                                <div>
                                                    ₹{selectedService != null && serviceDiscountedBasePrice}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.sideBySideFinal}>
                                            <div>
                                                Service Final Price
                                            </div>
                                            <div className={styles.pSideBySide}>
                                                <div>
                                                    ₹{parseFloat(selectedService.basePrice) + parseFloat(orgPrice)}
                                                </div>
                                                <div>
                                                    ₹{parseFloat(serviceDiscountedBasePrice) + parseFloat(disPrice)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {isAddOnAdding == true ?
                                    <div className={styles.addAddOnBtn}>
                                        <button>Adding...</button>
                                    </div>
                                    :
                                    <div className={styles.addAddOnBtn}>
                                        <button onClick={() => addAddOnHandler()}>Add</button>
                                        <button onClick={() => cancelAddOnHandler()}>Cancel</button>
                                    </div>
                                }
                            </div>
                        }
                    </>
                    :
                    <div className={styles.addOnClientsContainer}>
                        <div className={styles.loadingTxt}>
                            Loading...
                        </div>
                    </div>
                }

            </div>
        )
    }

}

export default EachServiceAddOns