import React, { useState } from 'react'
import { humanReadableTimePassed } from '../../Helper/calculations'
import styles from '../Feedback/EachFeedbackBox.module.css'

const EachFeedbackBox = props => {
    const item = props.item
    const [showMore, setShowMore] = useState(false)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    {item.selectedStar === '5' && <div className={styles.selectedStarIconFive}> {item.selectedStar} </div>}
                    {item.selectedStar === '4' && <div className={styles.selectedStarIconFour}>{item.selectedStar}</div>}
                    {item.selectedStar === '3' && <div className={styles.selectedStarIconThree}>{item.selectedStar}</div>}
                    {item.selectedStar === '2' && <div className={styles.selectedStarIconTwo}>{item.selectedStar}</div>}
                    {item.selectedStar === '1' && <div className={styles.selectedStarIconOne}>{item.selectedStar}</div>}


                    <div className={styles.headerRightCol}>
                        <div>
                            <div className={styles.clientName}>
                                {item.clientName}
                            </div>
                            <div className={styles.visitedOn}>
                                Visited On : {item.payStatusLastUpdatedAt.substring(0, 10)}
                            </div>
                        </div>

                        <div className={styles.moreDetailsBtn}>
                            {humanReadableTimePassed(item.ratedOn)}
                        </div>
                    </div>
                </div>


                {item.careToShareALilMoreTxt &&
                    <div className={styles.careToShareALilMoreTxt}>
                        {item.careToShareALilMoreTxt}
                    </div>
                }
                <div className={styles.factorsWrapper}>

                    {item.starSelectedSalonEnvironment !== '0' &&
                        <div className={styles.eachFactorWrapperSe}>
                            <div className={styles.factorTitle}>
                                Salon Environment
                            </div>
                            <div className={styles.factorRating}>
                                {item.starSelectedSalonEnvironment}
                            </div>
                        </div>
                    }

                    {item.starSelectedServiceResults !== '0' &&
                        <div className={styles.eachFactorWrapperSr}>
                            <div className={styles.factorTitle}>
                                Service Result
                            </div>
                            <div className={styles.factorRating}>
                                {item.starSelectedServiceResults}
                            </div>
                        </div>
                    }

                    {item.starSelectedStaffBehaviour !== '0' &&
                        <div className={styles.eachFactorWrapperSb}>
                            <div className={styles.factorTitle}>
                                Staff Behaviour
                            </div>
                            <div className={styles.factorRating}>
                                {item.starSelectedStaffBehaviour}
                            </div>
                        </div>
                    }



                </div>

                <div className={styles.moreDetailsWrapper}>
                    {!showMore &&<div className={styles.moreDetailsBtn} onClick={() => setShowMore(true)} >View more details</div>}
                    {showMore &&
                        <div>
                            <a href={"/booking/"+item.bookingIdentifier} target="_blank">Services Details</a>
                            <a href={"/user/"+item.clientId} target="_blank">Client Details</a>
                        </div>
                    }
                    {showMore && <div className={styles.lessDetailsBtn} onClick={() => setShowMore(false)} >Show Less details </div>}
                </div>

            </div>
        </div>
    )
}

export default EachFeedbackBox