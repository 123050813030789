import React from 'react'
import styles from './NotFound.module.css'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.oops}>
                Oops! Something went wrong!
            </div>
            <div className={styles.rest}>
                We couldn't find the page you were looking for.
            </div>
            <div className={styles.rest}>
                Either you have entered an invalid URL or you don't have access to tha page.
            </div>
            <div className={styles.rest}>
                Why not try back to the <Link to={`/`}>Homepage</Link>?
            </div>
        </div>
    )
}

export default NotFound