import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from './ViewFutureBooking.module.css'

const ViewFutureBooking = (props) => {
    const { isOpen, item, onClose, onDeletionReservation } = props
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [isDeleting, setIsDeleting] = useState(false)



    window.onclick = event => event.target.id === "mcCalViewFutureBooking" ? onClose() : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });

    const deleteReservations = async () => {
        setIsDeleting(true)
        const deleteReservationsResponse = await fetch(BASE_URL_API + "deleteReservation",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    eventIdentifier: item?.eventIdentifier
                })
            });

        if (!deleteReservationsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const deleteReservationsRespo = await deleteReservationsResponse.json()
            if (deleteReservationsRespo?.status === "success") {
                onDeletionReservation()
            } else {
                if (deleteReservationsRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    console.log(deleteReservationsRespo)
                }
            }
        }
        setIsDeleting(false)
    }

    if (!isOpen) {
        return null
    }
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} id="mcCalViewFutureBooking" >
                <div className={styles.mainContent}>
                    <div className={styles.actionHeader}>
                        {/* View Future Booking */}
                        {item?.title}
                    </div>
                    <div className={styles.actionBody}>
                        <div className={styles.bookingDate}>
                            {item?.start?.toString()?.substr(0, 24)}
                        </div>
                        <div className={styles.eachRParam}>
                            <div className={styles.rTitle}>Remarks</div>
                            <div className={styles.rValue}>{item?.remarks}</div>
                        </div>
                        <div className={styles.eachRParam}>
                            <div className={styles.rTitle}>Services Notes</div>
                            <div className={styles.rValue}>{item?.servicesNotes}</div>
                        </div>
                    </div>
                    <div className={styles.actionFooter}>
                        {isDeleting === true ?
                            <button className={styles.delete} onClick={() => alert(`Please wait...`)}>Deleting...</button>
                            :
                            <button className={styles.delete} onClick={() => deleteReservations()} >Delete</button>
                        }
                        <button className={styles.okay}>Okay</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewFutureBooking