import React, { useContext, useEffect, useState } from 'react'
import { BiDialpad } from 'react-icons/bi';
import { BsKeyboard } from 'react-icons/bs';
import { FaUser } from 'react-icons/fa';
import { BASE_URL_API } from '../../References/URLs';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AuthContext from '../../store/auth-context';
import styles from '../Sessions/ChooseClient.module.css'
import EachChooseableClient from './EachChooseableClient';

const ChooseClient = (props) => {
    const authCtx = useContext(AuthContext);
    const { isOpen, abortClientSelection, assignClient, userFormToggle, closeModal } = props
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [isSearching, setIsSearching] = useState(false)
    const [searchBy, setSearchBy] = useState('phone')
    const [searchKey, setSearchKey] = useState('')
    const [clientsData, setClientsData] = useState([])

    document.addEventListener('keydown', function (e) {
        if (e.target.tagName === 'INPUT') {
            return;
        }
        if (e.keyCode === 27) {
            abortClientSelection()
        }
    });

    window.onclick = function (event) {
        if (event.target.id === "userSelectionFormForCheckout") {
            abortClientSelection()
        }
        if (event.target.id == "addNewClientForm") {
            userFormToggle(false);
        }

        if (event.target.id === "filtersPanelWrapperMUG") {
            document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft .1s forwards";
        }
    };


    const getClientViaSearch = async (k) => {
        setIsSearching(true)
        const getClientViaSearchResponse = await fetch(BASE_URL_API + "getClientViaSearch",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    searchKey: k
                })
            });

        if (!getClientViaSearchResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getClientViaSearchRespo = await getClientViaSearchResponse.json();
            if (getClientViaSearchRespo.status === 'success') {
                setClientsData(getClientViaSearchRespo.response);
            } else {
                if (getClientViaSearchRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    setClientsData([])
                }
            }
            console.log(getClientViaSearchRespo)
        }
        setIsSearching(false)
    }

    useEffect(() => {
        if (searchKey.length > 1) {
            getClientViaSearch(searchKey)
        }
    }, [searchKey])

    useEffect(() => {
        if (isOpen) {
            getClientViaSearch('')
        }
    }, [isOpen])




    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}
                    id="userSelectionFormForCheckout"
                    style={{ animation: `0.12s cubic-bezier(0, 0.63, 0.26, 0.96) 0s 1 normal forwards running slideTopToBottom` }}
                >
                    <div className={styles.popUpBody}>
                        <div className={styles.pubHeader}>
                            Select Client Details
                        </div>
                        <div className={styles.pubBody}>
                            {searchBy === 'keyboard' ?
                                <div className={styles.searchByHeader}>
                                    <div className={styles.searchBar}>
                                        <input
                                            placeholder="Search for any client..."
                                            autoFocus={true}
                                            onChange={(e) => setSearchKey(e.target.value)}
                                            value={searchKey}
                                        />
                                    </div>
                                    <div className={styles.modeToggleBtn}>
                                        <button onClick={() => setSearchBy('phone')}>
                                            <BiDialpad />
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className={styles.searchByHeader}>
                                    <div className={styles.searchBar}>
                                        <input
                                            placeholder="Search for any client..."
                                            autoFocus={true}
                                            onChange={(e) => setSearchKey(e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.modeToggleBtn}>
                                        <button onClick={() => setSearchBy('keyboard')}>
                                            <BsKeyboard />
                                        </button>
                                    </div>
                                </div>
                            }

                            {isSearching === true ?
                                <div className={styles.searchResultsWrapper}>
                                    Loading...
                                </div>
                                :
                                <div className={styles.searchResultsWrapper}>
                                    {clientsData?.length > 0 ?
                                        <div>
                                            {clientsData.map((item, index) => (
                                                <EachChooseableClient key={index} item={item} assignClient={assignClient} />
                                            ))}
                                        </div>
                                        :
                                        <div className={styles.noClientFound}>
                                            No clients found, try removing some keywords of add new client!
                                        </div>
                                    }

                                    <div onClick={() => userFormToggle(true)}
                                        className={styles.addNewClient}
                                    >
                                        Add New Client
                                    </div>

                                </div>
                            }

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default ChooseClient