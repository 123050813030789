import React, { useContext, useEffect, useState } from 'react'
import NavAtHome from '../../Components/Navigators/NavAtHome'
import styles from '../Membership/AllMembers.module.css'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import { ShimmerTable } from 'react-shimmer-effects'
import { timeLeftInFuture } from '../../Helper/calculations'
import ActiveStoreContext from '../../store/ActiveStoreContext'

const AllMembers = () => {
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [isLoading, setIsLoading] = useState(true)
    const [cardMembers, setCardMembers] = useState([
        {
            "cardNumber": "400003433593895038503"
        }
    ]);
    const [cardType, setCardType] = useState('');
    const [searchKey, setSearchKey] = useState('');
    const [currentStatus, setCurrentStatus] = useState('')



    const setCardTypeHandler = (k) => {
        setCardType(k);
    }


    const getAllMembers = async () => {
        setIsLoading(true)
        const getAllMembersResponse = await fetch(BASE_URL_API + "getAllMembers",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    cardType: cardType,
                    searchKey: searchKey,
                    currentStatus : currentStatus
                })
            });

        if (!getAllMembersResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getAllMembersRespo = await getAllMembersResponse.json();
            if (getAllMembersRespo.status === "success") {
                setCardMembers(getAllMembersRespo.response);
            } else {
                if (getAllMembersRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else if (getAllMembersRespo.message === `you haven't sold any card so far!`) {
                    setCardMembers([])
                } else {

                }
            }
            console.log(getAllMembersRespo)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getAllMembers()
    }, [])

    useEffect(() => {
        getAllMembers();
    }, [activeStore?.storeIdentifier, cardType, currentStatus])

    useEffect(() => {
        if (searchKey.length > 2) {
            getAllMembers();
        }
        if (searchKey === '') {
            getAllMembers();
        }
    }, [searchKey])


    // console.log(cardMembers);
    if (activeStore === null) {
        return (
            <div className={styles.mainWrapper}>
                Please select a store first!
            </div>
        )
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                {/* <NavAtHome /> */}
                <div className={styles.mainBodyWrapper}>
                    <div className={styles.leftCol}>
                        <div className={styles.headerWrapper}>
                            <div className={styles.upperHeaderWrapper}>
                                View all memberships here...
                            </div>
                            <div className={styles.lowerHeaderWrapper}>
                                <div className={styles.inputSearchWrapper}>
                                    <input placeholder="Search for any member..."
                                        onChange={(e) => setSearchKey(e.target.value)}
                                        value={searchKey}
                                    />
                                </div>

                                <div className={styles.optionsWrapper}>
                                    <div className={styles.eachFilterWrapper}>
                                        <div className={styles.eachFilterCaption}>
                                            Status
                                        </div>
                                        <div className={styles.eachFilterOption}>
                                            <select onChange={(e) => setCurrentStatus(e.target.value)}>
                                                <option value="">Please select status</option>
                                                <option value="Active">Active</option>
                                                <option value="Expired">Expired</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={styles.eachFilterWrapper}>
                                        <div className={styles.eachFilterCaption}>
                                            Membership Type
                                        </div>
                                        <div className={styles.eachFilterOption}>
                                            <select onChange={(e) => setCardTypeHandler(e.target.value)}>
                                                <option value="">Select</option>
                                                <option value="GALLANT">GALLANT</option>
                                                <option value="SILVER">SILVER</option>
                                                <option value="RUBY">RUBY</option>
                                                <option value="GOLD">GOLD</option>
                                                <option value="DIAMOND">DIAMOND</option>
                                                <option value="PLATINUM">PLATINUM</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={styles.eachFilterWrapper}>
                                        <div className={styles.addNewMemberWrapper}>
                                            <Link to="/memberships/sell">Add New Member</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className={styles.bodyWrapper}>
                            <div className={styles.verticalRightColWrapper}>
                                {isLoading === true ?
                                    <div className={styles.tableWrapper}>
                                        <ShimmerTable row={5} col={5} />
                                    </div>
                                    :
                                    <div className={styles.tableWrapper}>
                                        <div className={styles.tableContainer}>
                                            <div className={styles.tableHeader}>
                                                <div className={styles.indexCol}>
                                                    #
                                                </div>
                                                <div className={styles.cardNumberCol}>
                                                    Card Number
                                                </div>
                                                <div className={styles.membershipType}>
                                                    Type
                                                </div>
                                                <div className={styles.servicesWorthCol}>
                                                    Worth
                                                </div>
                                                <div className={styles.paidAmountCol}>
                                                    Paid
                                                </div>
                                                <div className={styles.activatedOnCol}>
                                                    Purchased On
                                                </div>
                                                <div className={styles.activatedOnCol}>
                                                    Expiring On
                                                </div>
                                                <div className={styles.actionCol}>
                                                    Action
                                                </div>
                                            </div>
                                            {cardMembers.length > 0 ?
                                                <div className={styles.tableBody}>
                                                    {cardMembers.map((itemCard, index) => (
                                                        <div className={styles.eachTableRow}
                                                            key={index}
                                                        >
                                                            <div className={styles.indexCol}>
                                                                {index + 1}
                                                            </div>
                                                            <div className={styles.cardNumberCol}>
                                                                <Link to={"/membership/" + itemCard.issuedCardNumber} className={styles.cardNumberTxt}>
                                                                    {itemCard.issuedCardNumber}
                                                                </Link>
                                                                <div className={styles.clientName}>
                                                                    {itemCard.clientName}
                                                                </div>
                                                            </div>
                                                            <div className={styles.membershipType}>
                                                                <div className={styles.cardType}>
                                                                    {itemCard.label}
                                                                </div>
                                                            </div>
                                                            <div className={styles.servicesWorthCol}>
                                                                &#8377;{itemCard.worthOff}
                                                            </div>
                                                            <div className={styles.paidAmountCol}>
                                                                &#8377;{itemCard.amountPaid}
                                                            </div>
                                                            <div className={styles.activatedOnCol}>
                                                                {itemCard.issuedOn}
                                                            </div>
                                                            <div className={styles.activatedOnCol}>
                                                                <div className={styles.expiringIn}>
                                                                    {timeLeftInFuture(itemCard.expiryDate) > 0 ?
                                                                        <div className={styles.expiringInTxt}>Expiring in  {timeLeftInFuture(itemCard.expiryDate)} days!</div>
                                                                        :
                                                                        <div className={styles.expiredTxt}>
                                                                            Expired {timeLeftInFuture(itemCard.expiryDate) * -1} days ago!
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={styles.actionCol}>
                                                                View
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                :
                                                <div className={styles.tableBody}>
                                                    <div className={styles.noCardMembersFound}>
                                                        {cardType !== '' ?
                                                            "No card member found, try removing some filters!"
                                                            :
                                                            "No card member found!"
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    <div>
                        Right
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllMembers