import React, { useContext } from 'react'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from './ActiveStoreSelector.module.css'

const ActiveStoreSelector = (props) => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)

    console.log("this is asCtx:  ", asCtx)

    const { isOpen, onClose, onSuccess } = props
    const storesData = JSON.parse(authCtx?.loginData)?.storesData
    window.onclick = event => event.target.id === "astsmcid" ? onClose() : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} id="astsmcid" >
                <div className={styles.mainContent}>
                    <div className={styles.topHeader}>
                        Select Active Store
                    </div>
                    <div className={styles.storesWrapper}>
                        {storesData?.map((item, index) => (
                            <div className={styles.eachStoreWrapper} key={index} onClick={() => onSuccess(item)}>
                                <div className={styles.storeName}>{item?.storeName}</div>
                                <div className={styles.storeAddress}>{item?.storeAddress}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActiveStoreSelector