import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { dateDifference } from '../../Helper/calculations';
import styles from '../UI/SearchableTables.module.css'

const DataTable = ({ data }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(20);
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState({ column: null, order: null });

    const filteredData = data
        .filter(
            (item) =>
                item.client_name.toLowerCase().includes(search.toLowerCase()) ||
                item.mobile.toLowerCase().includes(search.toLowerCase()) ||
                // (item.lastVisit && new Date(item.lastVisit).toString().toLowerCase().includes(search.toLowerCase()))
                (item.lastVisit && item.lastVisit.toLowerCase().includes(search.toLowerCase()))
        )
        .sort((a, b) => {
            let valueA = a[sort.column];
            let valueB = b[sort.column];

            if (typeof valueA === 'string') {
                valueA = valueA.toLowerCase();
            }

            if (typeof valueB === 'string') {
                valueB = valueB.toLowerCase();
            }

            valueA = isNaN(Date.parse(valueA)) ? valueA : new Date(valueA);
            valueB = isNaN(Date.parse(valueB)) ? valueB : new Date(valueB);

            valueA = isNaN(parseFloat(valueA)) ? valueA : parseFloat(valueA);
            valueB = isNaN(parseFloat(valueB)) ? valueB : parseFloat(valueB);

            if (sort.order === 'asc') {
                return valueA > valueB ? 1 : -1;
            } else {
                return valueA < valueB ? 1 : -1;
            }
        });




    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleSearch = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };

    const handleSort = (column) => {
        if (sort.column === column) {
            setSort({
                column,
                order: sort.order === 'asc' ? 'desc' : 'asc',
            });
        } else {
            setSort({
                column,
                order: 'asc',
            });
        }
    };

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.searchBarWrapper}>
                <input
                    type="text"
                    placeholder="Search for any user...."
                    value={search}
                    onChange={handleSearch}
                    className={styles.searchBar}
                />
            </div>
            <table className={styles.tableWrapper}>
                <thead className={styles.tableHeader}>
                    <tr className={styles.topRow}>
                        <th className={styles.eachId} onClick={() => handleSort('id')}>ID</th>
                        <th onClick={() => handleSort('client_name')}>Name</th>
                        <th onClick={() => handleSort('total_bookings')}>Bookings</th>
                        <th onClick={() => handleSort('total_spent')}>Spent</th>
                        <th onClick={() => handleSort('lastVisit')}>Last Visit</th>
                        <th onClick={() => handleSort('firstVisit')}>First Visit</th>
                        <th onClick={() => handleSort('firstVisit')}>Relationship Age</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPosts.map((item, index) => (
                        <tr key={index} className={styles.eachrow}>
                            <td>{item.mobile}</td>
                            <td>
                                <Link to={"/user/" + item.clientStoreRelationIdentifier}>
                                    {item.client_name}
                                </Link>
                            </td>
                            <td>{parseInt(item.total_bookings)}</td>
                            <td>{parseFloat(item.total_spent !== null ? item.total_spent : 0).toFixed(1)}</td>
                            <td>{item.lastVisit}</td>
                            <td>{item.firstVisit}</td>
                            {item?.firstVisit !== null ?
                                <td>
                                    {dateDifference(item.firstVisit).years > 0 && dateDifference(item.firstVisit).years + " years, "}
                                    {dateDifference(item.firstVisit).months > 0 && dateDifference(item.firstVisit).months + " months, "}
                                    {dateDifference(item.firstVisit).days} days
                                </td>
                                :
                                <td>-</td>
                            }
                            {/* <td>{Date.parse(item.lastVisit)}</td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className={styles.paginationWrapper}>
                <div className={styles.paginationContainer}>
                    {[...Array(Math.ceil(filteredData.length / postsPerPage))].map(
                        (page, i) => (
                            <div
                                key={i}
                                className={currentPage === i + 1 ? styles.eachActivePageCount : styles.eachPageCount}
                                onClick={() => paginate(i + 1)}
                            >
                                {i + 1}
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default DataTable;
