import React from 'react'
import styles from '../Bookings/CSServices.module.css'

const CSServices = props => {
    const item = props.item;
    return (
        <div>
            <div>
                <div>

                    Service List
                </div>
                {item.allServices !== null ?
                    <div>
                        {item.allServices.map((itemSer, index) => (
                            <div key={index}>
                                {itemSer.serviceName}
                            </div>
                        ))}
                    </div>
                    :
                    <div>
                        No service added
                    </div>
                }

            </div>

        </div>
    )
}

export default CSServices