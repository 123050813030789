import React from 'react'
import styles from '../Sessions/PriceListCategory.module.css'
import PriceListCategoryServices from './PriceListCategoryServices'

const PriceListCategory = props => {
    const sessionIdentifier = props.sessionIdentifier
    const workersData = props.workersData;
    const item = props.item
    const showDiscount = props.showDiscount
    const cartItems = props.cartItems
    const getCartItems = props.getCartItems
    const fetchingCartItems = props.fetchingCartItems
    return (
        <div className={styles.eachCategoryWrapper}>
            <div className={styles.categoryTitle}>
                <div>
                    {item.categoryName}
                </div>
            </div>
            <div className={styles.categoryServicesWrapper}>
                <div>
                    {item.services?.map((itemServices, indexServices) => (
                        <PriceListCategoryServices 
                            key={indexServices + "S"}
                            itemServices={itemServices} 
                            showDiscount={showDiscount}
                            workersData={workersData}
                            cartItems={cartItems}
                            getCartItems={getCartItems}
                            fetchingCartItems={fetchingCartItems}
                            sessionIdentifier={sessionIdentifier}
                        />
                    ))}
                </div>
            </div>

        </div>
    )
}

export default PriceListCategory