import React from 'react';
import styles from  './FreeHaircutLadies.module.css';

const FreeHaircutLadies = () => {
    return (
        <div className={styles.offerCard}>
            <h2 className={styles.offerTitle}>Free Haircut!!</h2>
            <p className={styles.offerSubtitle}>Yes, You read that Right! 💃</p>
            <p className={styles.offerDescription}>
                Book the Headwash and Blow Dry package and get a <span className={styles.highlight}>free Haircut</span>!!
            </p>
            <ul className={styles.offerList}>
                <li>Haircut</li>
                <li>Headwash</li>
                <li>Blow Dry</li>
            </ul>
            <p className={styles.priceInfo}>
                <span className={styles.originalPrice}>💥 Original Price: ₹1015</span>
                <br />
                <span className={styles.discountedPrice}>💥 Now Only: ₹490</span>
            </p>
            <p className={styles.locationInfo}>
                Visit: <strong>Lokaci - The Salon Company</strong>
                <br />
                Shop No. 101, First Floor, Eldeco Amantran, Near Badami, Sector - 119, Noida, 201307
            </p>
            <p className={styles.validityInfo}>Valid for ladies only.</p>
            <p className={styles.offerExpiry}>Offer valid till <strong>7th November 2024</strong>.</p>
            <p className={styles.disclaimer}>To avail this offer, show this message during billing.</p>
        </div>
    );
};

export default FreeHaircutLadies;
