import React, { useContext, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import MurpEachRow from '../../Components/ClientOnboardingForm/MurpEachRow'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from './ManageUserRegistrationPages.module.css'

const ManageUserRegistrationPages = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [pagesData, setPagesData] = useState([])
    const [isCreatingNew, setIsCreatingNew] = useState(false)
    const [pageUrlToAdd, setPageUrlToAdd] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isCreatingPage, setIsCreatingPage] = useState(false)

    const getAllUserRegistrationPage = async () => {
        const getAllUserRegistrationPageResponse = await fetch(BASE_URL_API + "getAllUserRegistrationPage",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getAllUserRegistrationPageResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllUserRegistrationPageRespo = await getAllUserRegistrationPageResponse.json()
            if (getAllUserRegistrationPageRespo.status === "success") {
                setPagesData(getAllUserRegistrationPageRespo?.response)
            } else {
                if (getAllUserRegistrationPageRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setPagesData([])
                }
            }
            console.log(getAllUserRegistrationPageRespo)
        }
    }

    useEffect(() => {
        getAllUserRegistrationPage()
    }, [activeStore?.storeIdentifier])


    const createNewRegistrationPage = async () => {
        setIsCreatingPage(true)
        const createNewRegistrationPageResponse = await fetch(BASE_URL_API + "createNewRegistrationPage",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    pageUrlToAdd: pageUrlToAdd,
                    storeIdentifier: activeStore?.storeIdentifier,
                    vendorIdentifier: authCtx?.partnersData?.vendorIdentifier,
                    storeName: activeStore?.storeName,
                    storeAddress: activeStore?.storeAddress
                })
            });

        if (!createNewRegistrationPageResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const createNewRegistrationPageRespo = await createNewRegistrationPageResponse.json()
            if (createNewRegistrationPageRespo.status === "success") {
                toast(createNewRegistrationPageRespo?.message)
                getAllUserRegistrationPage()
                setIsCreatingNew(false)
            } else {
                if (createNewRegistrationPageRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    toast(createNewRegistrationPageRespo?.message)
                }
            }
            console.log(createNewRegistrationPageRespo)
        }
        setIsCreatingPage(false)
    }
   
    


    if (activeStore === null) {
        return (
            <div>
                Please select a store!
            </div>
        )
    } else {
        return (
            <div className={styles.mainWrapper}>
                <ToastContainer />
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>

                        <div className={styles.mainHeader}>
                            <div className={styles.titleWrapper}>
                                {pagesData?.length} pages created
                            </div>
                            <div className={styles.btnWrapper}>
                                <button onClick={() => setIsCreatingNew(!isCreatingNew)}>{isCreatingNew === false ? `Create A New Registration Page` : `Cancel`}</button>
                            </div>
                        </div>
                        <div className={styles.mainBody}>
                            <div className={styles.tableWrapper}>
                                {isCreatingNew === true &&
                                    <div className={styles.createNewWrapper}>
                                        <div className={styles.cnInput}>
                                            <input placeholder="Please enter a URL..." autoFocus={true} value={pageUrlToAdd} onChange={(e) => setPageUrlToAdd(e.target.value)} />
                                        </div>
                                        <div className={styles.cnBtn}>
                                            {isCreatingPage === true ?
                                                <button className={styles.create}>Creating now...</button>
                                                :
                                                <button className={styles.create} onClick={() => createNewRegistrationPage()} >Create</button>
                                            }

                                        </div>
                                    </div>
                                }
                                <div className={styles.tableHeader}>
                                    <div className={styles.sn}>#</div>
                                    <div className={styles.pageUrl}>Url</div>
                                    <div className={styles.status}>Status</div>
                                    <div className={styles.actions}>Actions</div>
                                </div>
                                <div className={isCreatingNew === true ? styles.tableBodySmall : styles.tableBody}>
                                    {pagesData?.map((item, index) => (
                                        <MurpEachRow item={item} key={index} count={index} onSuccess={getAllUserRegistrationPage} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ManageUserRegistrationPages