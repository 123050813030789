import React, { useContext, useEffect, useState } from 'react'
import StoresHeader from '../../Components/Stores/StoresHeader'
import styles from './StoreClients.module.css'
import { Link } from 'react-router-dom'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import { MdDelete } from 'react-icons/md'
import { toast, ToastContainer } from 'react-toastify'
import { IoMdEye } from 'react-icons/io'

const StoreClients = () => {
    const asCtx = useContext(ActiveStoreContext)
    const authCtx = useContext(AuthContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [allUsers, setAllUsers] = useState([
        {
            client_name: 'Loading...',
            countryCode: '+91',
            mobile: 'Loading...',
            email: 'Loading...',
            dob: 'Loading...',
            doa: 'Loading...'
        },
        {
            client_name: 'Loading...',
            countryCode: '+91',
            mobile: 'Loading...',
            email: 'Loading...',
            dob: 'Loading...',
            doa: 'Loading...'
        },
        {
            client_name: 'Loading...',
            countryCode: '+91',
            mobile: 'Loading...',
            email: 'Loading...',
            dob: 'Loading...',
            doa: 'Loading...'
        },
        {
            client_name: 'Loading...',
            countryCode: '+91',
            mobile: 'Loading...',
            email: 'Loading...',
            dob: 'Loading...',
            doa: 'Loading...'
        }
    ]);
    const [filteredData, setFilteredData] = useState([])
    const [searchKey, setSearchKey] = useState('')

    const getClientsStats = async () => {
        const getClientsStatsResponse = await fetch(BASE_URL_API + "getClientsStats",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx?.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getClientsStatsResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const getClientsStatsRespo = await getClientsStatsResponse.json();
            if (getClientsStatsRespo.status === "success") {
                setAllUsers(getClientsStatsRespo.response)
                setFilteredData(getClientsStatsRespo.response)
            } else {
                if (getClientsStatsRespo.message === "tokenExpired") {
                    authCtx.logout();
                } else {
                    console.log(getClientsStatsRespo);
                }
            }
        }
    }

    const filterData = (data, searchKey) => {
        setFilteredData(data.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchKey.toLowerCase())
            )
        ))
    }

    useEffect(() => {
        filterData(allUsers, searchKey)
    }, [searchKey])


    useEffect(() => {
        if (activeStore !== null) {
            getClientsStats()
        }
    }, [activeStore?.storeIdentifier])



    const deleteClient = async (item) => {
        // console.log(item?.clientStoreRelationIdentifier)
        if (window.confirm("Press a button!") === true) {
            const deleteClientResponse = await fetch(BASE_URL_API + "deleteClient",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx?.token,
                        clientIdentifier: item?.clientStoreRelationIdentifier
                    })
                });

            if (!deleteClientResponse?.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const deleteClientRespo = await deleteClientResponse.json()
                if (deleteClientRespo.status === "success") {
                    toast(`Successfully deleted the client!`)
                    getClientsStats()
                } else {
                    if (deleteClientRespo.message === "tokenExpired") {
                        authCtx.logout()
                    } else {

                    }
                    console.log(deleteClientRespo)
                }
            }
        } else {
            console.log("You canceled!")
        }
    }


    if (activeStore === null) {
        return (
            <div className={styles.mainWrapper}>
                <StoresHeader />
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            Please select a store!
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.mainWrapper}>
            <StoresHeader />
            <ToastContainer />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.mainHeader}>
                        <div>
                            <div className={styles.title}>
                                Store Clients
                            </div>
                            <div className={styles.subTitle}>
                                <p>All clients of your store.</p>
                            </div>
                        </div>
                        <div className={styles.addNew}>
                            <Link to={`/add-clients`} >+ Add A New Client</Link>
                        </div>
                    </div>
                    <div className={styles.mainBody}>
                        <div className={styles.tableWrapper}>
                            <div className={styles.tableHeader}>
                                <div className={styles.tableTitle}>
                                    <div className={styles.tableSearch}>
                                        <input placeholder="Search for anything... " value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                    </div>
                                    <div className={styles.resultsCount}>
                                        {allUsers?.length} results found!
                                    </div>
                                </div>
                                <div className={styles.tableSubtitle}>
                                    <div className={styles.tCount}>#</div>
                                    <div className={styles.tFullname}>Full Name</div>
                                    <div className={styles.tCode}>Code</div>
                                    <div className={styles.tPhone}>Phone</div>
                                    <div className={styles.tPhone}>Alt. Phone</div>
                                    <div className={styles.tPhone}>WhatsApp</div>
                                    <div className={styles.tEmail}>E-mail</div>
                                    <div className={styles.tBirthdate}>Birthdate</div>
                                    <div className={styles.tAnniversary}>Anniversary</div>
                                    <div className={styles.tAction}>Action</div>
                                </div>
                            </div>
                            <div className={styles.tableData}>
                                {filteredData?.map((item, index) => (
                                    <div className={styles.eachTableRow} key={index} >
                                        <div className={styles.tCount}>{index + 1}.</div>
                                        <div className={styles.tFullname}>{item?.client_name}</div>
                                        <div className={styles.tCode}>{item?.countryCode}</div>
                                        <div className={styles.tPhone}>{item?.mobile}</div>
                                        <div className={styles.tPhone}>{item?.alternateNumber}</div>
                                        <div className={styles.tPhone}>{item?.whatsAppNumber}</div>
                                        <div className={styles.tEmail}>{item?.email}</div>
                                        <div className={styles.tBirthdate}>{item?.dob}</div>
                                        <div className={styles.tAnniversary}>{item?.doa}</div>
                                        <div className={styles.tAction}>
                                            <Link to={`/user/${item?.clientStoreRelationIdentifier}`} 
                                                className={styles.viewBtn}
                                                title="View Full Details"
                                            >
                                                <IoMdEye color="#1d7af4" size={18} />
                                            </Link>
                                            <button className={styles.deleteBtn} onClick={() => deleteClient(item)}>
                                                <MdDelete color="#FF2600bf" size={18} />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StoreClients