import React, { useContext, useEffect, useRef, useState } from 'react'
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import styles from '../Sessions/SessionInitiator.module.css'
import { useNavigate } from 'react-router';
import { FaFemale, FaMale, FaMinus, FaPlus, FaTransgender } from 'react-icons/fa';
import ActiveStoreContext from '../../store/ActiveStoreContext';

const SessionInitiator = props => {
    let navigate = useNavigate()
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    let isOpen = props.isOpen;
    const abortInitiation = props.abortInitiation;
    const initiateBooking = props.initiateBooking;

    const [counts, setCounts] = useState({
        maleCount: 0,
        femaleCount: 0,
        otherCount: 0
    });

    const [bookingInitiationStatus, setBookingInitiationStatus] = useState('NOT_INITIATED'); //INITIATING, INITIATED, INITIATION_FAILED

    const resetSelections = () => {
        console.log("Reset selection called");
        setCounts({
            maleCount: 0,
            femaleCount: 0,
            otherCount: 0
        });
    };

    const handleKeyDown = e => {
        if (e.key === "m") {
            setCounts((prevCounts) => ({
                ...prevCounts,
                maleCount: prevCounts.maleCount + 1,
            }));
        } else if (e.key === "f") {
            setCounts((prevCounts) => ({
                ...prevCounts,
                femaleCount: prevCounts.femaleCount + 1,
            }));
        } else if (e.key === "o") {
            setCounts((prevCounts) => ({
                ...prevCounts,
                otherCount: prevCounts.otherCount + 1,
            }));
        } else if (e.key === "Enter") {
            console.log("Enter pressed");
            console.log("has the booking initiated? ", bookingInitiationStatus);
            if (bookingInitiationStatus === 'NOT_INITIATED') {
                console.log("I'm clicking");
                initiateNow(counts);
                console.log("Counts in the enter : ", counts)
            } else {
                console.log("Booking already initiated, I'm clicking");
            }
        }

        console.log("is count updated", counts)
    };

    const initiateSession = async () => {
        setBookingInitiationStatus('INITIATING')
        const initiateSessionResponse = await fetch(BASE_URL_API + "initiateSession",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    maleCount: counts.maleCount,
                    femaleCount: counts.femaleCount,
                    othersCount: counts.otherCount,
                    createdBy: JSON.parse(authCtx?.loginData)?.welcomeData?.displayName,
                    createdByIdentifier: authCtx?.authIdentifier
                })
            });

        if (!initiateSessionResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const initiateSessionRespo = await initiateSessionResponse.json();
            if (initiateSessionRespo.status === 'success') {
                resetSelections();
                setBookingInitiationStatus('NOT_INITIATED');
                abortInitiation();
                navigate(`/sessions/` + initiateSessionRespo.response.bookingId)
            } else {
                if (initiateSessionRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    setBookingInitiationStatus('INITIATION_FAILED')
                }
            }
            console.log(initiateSessionRespo)
        }
    }

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, counts]);

    useEffect(() => {
        window.onclick = function (event) {
            if (event.target.id === "sessionInitiatorForm") {
                abortInitiation();
                resetSelections();
            }

            if (event.target.id == "filtersPanelWrapperMUG") {
                document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft .1s forwards";
            }
        };
    }, [isOpen])


    document.addEventListener('keydown', function (e) {
        if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
            return;
        }
        if (e.keyCode == 27) {
            abortInitiation();
            resetSelections();
        }

        if (e.keyCode === 83) {
            initiateBooking();
            resetSelections();
        }
    });

    const initiateNow = (counts) => {
        setBookingInitiationStatus('INITIATING');
        console.log(counts)
        let clientCount = counts.maleCount + counts.femaleCount + counts.otherCount;
        console.log(counts, clientCount);
        if (clientCount > 0) {
            initiateSession();
            // console.log("booking started with ", clientCount);
            // resetSelections();
            // setBookingInitiationStatus('INITIATED');
        } else {
            console.log("Invalid client details", clientCount);
            setBookingInitiationStatus('INITIATION_FAILED');
        }
    };



    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}
                    id="sessionInitiatorForm"
                    style={{ animation: `0.12s cubic-bezier(0, 0.63, 0.26, 0.96) 0s 1 normal forwards running slideBottomToTop` }}
                >
                    <div className={styles.popUpBody}>
                        <div className={styles.pubHeader}>
                            Select Client Details
                        </div>
                        <div className={styles.pubBody}>
                            <div className={styles.eachGenderOption}>
                                <div className={styles.genderCaption}>
                                    <FaFemale size={24} />
                                    <div className={styles.genderCaptionTxt}>
                                        Female
                                    </div>
                                </div>
                                <div className={styles.eachGenderCounter}>
                                    <button onClick={() => {
                                        if (counts.femaleCount > 0) {
                                            setCounts((prevCounts) => ({
                                                ...prevCounts,
                                                femaleCount: prevCounts.femaleCount - 1,
                                            }))
                                        }
                                    }}>
                                        <FaMinus />
                                    </button>
                                    <div className={styles.counterCount}>
                                        {counts.femaleCount}
                                    </div>
                                    <button onClick={() => {
                                        if (counts.femaleCount < 999) {
                                            setCounts((prevCounts) => ({
                                                ...prevCounts,
                                                femaleCount: prevCounts.femaleCount + 1,
                                            }))
                                        }
                                    }} >
                                        <FaPlus />
                                    </button>
                                </div>
                            </div>
                            <div className={styles.eachGenderOption}>
                                <div className={styles.genderCaption}>
                                    <FaMale size={24} />
                                    <div className={styles.genderCaptionTxt}>
                                        Male
                                    </div>
                                </div>
                                <div className={styles.eachGenderCounter}>
                                    <button onClick={() => {
                                        if (counts.maleCount > 0) {
                                            setCounts((prevCounts) => ({
                                                ...prevCounts,
                                                maleCount: prevCounts.maleCount - 1,
                                            }))
                                        }
                                    }}>
                                        <FaMinus />
                                    </button>
                                    <div className={styles.counterCount}>
                                        {counts.maleCount}
                                    </div>
                                    <button onClick={() => {
                                        if (counts.maleCount < 999) {
                                            setCounts((prevCounts) => ({
                                                ...prevCounts,
                                                maleCount: prevCounts.maleCount + 1,
                                            }))
                                        }
                                    }} >
                                        <FaPlus />
                                    </button>
                                </div>
                            </div>
                            <div className={styles.eachGenderOption}>
                                <div className={styles.genderCaption}>
                                    <FaTransgender size={24} />
                                    <div className={styles.genderCaptionTxt}>
                                        Others
                                    </div>
                                </div>
                                <div className={styles.eachGenderCounter}>
                                    <button onClick={() => {
                                        if (counts.otherCount > 0) {
                                            setCounts((prevCounts) => ({
                                                ...prevCounts,
                                                otherCount: prevCounts.otherCount - 1,
                                            }))
                                        }
                                    }}
                                    >
                                        <FaMinus />
                                    </button>
                                    <div className={styles.counterCount}>
                                        {counts.otherCount}
                                    </div>
                                    <button onClick={() => {
                                        if (counts.otherCount < 999) {
                                            setCounts((prevCounts) => ({
                                                ...prevCounts,
                                                otherCount: prevCounts.otherCount + 1,
                                            }))
                                        }
                                    }} >
                                        <FaPlus />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={styles.pubFooter}>
                            {bookingInitiationStatus === 'INITIATING' ?
                                <button >Creating A New Booking...</button>
                                :
                                <button onClick={() => initiateNow(counts)} >Create New Booking</button>
                            }
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default SessionInitiator