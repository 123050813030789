import React, { useEffect, useState } from 'react'
import styles from '../Analytics/PlotMonthlyRevenue.module.css'
import { Area, AreaChart, Bar, BarChart, Brush, CartesianGrid, ComposedChart, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

// import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const PlotMonthlyRevenue = props => {
    const { salesData } = props

    const [gtSessions, setGtSessions] = useState(0)
    const [data, setData] = useState([])
    const [chartType, setChartType] = useState('MONTHLY_REVENUE')

    const convertAmountsToNumbers = () => {
        let avgSession = 0;
        let gtSession = 0;
        let gtSessionForAvg = 0
        
        let gtSales = 0
        let avgSales = 0;

        salesData.map((item) => {
            gtSession += parseFloat(item.total_sessions)
        })
        setGtSessions(gtSession)
        
        
        salesData.slice(0, -1).map((item) => {
            gtSessionForAvg += parseFloat(item.total_sessions)
            gtSales += parseFloat(item.total_amount)
        })
        avgSession = gtSessionForAvg/(salesData?.length - 1)
        avgSales = gtSales/(salesData?.length - 1)





        const newData = salesData.map((item) => ({
            ...item,
            total_sessions : parseFloat(item.total_sessions),
            avg_sessions : parseInt(avgSession),
            average_sales : parseInt(avgSales),
            total_amount: parseInt(item.total_amount),
            average_daily_sales: parseFloat(parseFloat(item.total_amount) / parseFloat(item.days_in_month)).toFixed(1),
            monthly_pnl: parseFloat(parseFloat(item.total_amount) - 195000).toFixed(1)
        }));
        setData(newData);
    };


    useEffect(() => {
        convertAmountsToNumbers()
    }, [salesData])

    console.log(data)

    return (
        <div className={styles.mainWrapper}>







            <div className={styles.mainContainer}>

                <div>
                    <div className={styles.mainTitleWrapper}>
                        <div>
                            Show PnL Plot
                        </div>
                    </div>
                    <ResponsiveContainer width="100%" height={350}>
                        <BarChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <ReferenceLine y={0} stroke="#000" />
                            <Bar dataKey="monthly_pnl" fill="#8884d8" />
                            {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
                        </BarChart>
                    </ResponsiveContainer>
                </div>


                <div> ========= </div>



                <div>
                    <div className={styles.mainTitleWrapper}>
                        <div>
                            Monthly Revenue Plot
                        </div>
                    </div>
                    <ResponsiveContainer width="100%" height={350}>
                        <BarChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="total_amount" fill="#8884d8" />
                            <Bar dataKey="total_sessions" fill="#82ca9d" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>



                <div>
                    <div className={styles.mainTitleWrapper}>
                        <div>
                            Monthly Revenue Plot
                        </div>
                    </div>
                    <ResponsiveContainer width="100%" height={350}>
                        <AreaChart
                            width={500}
                            height={350}
                            data={data}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {chartType === "MONTHLY_REVENUE" &&
                                <Area type="monotone" dataKey="total_amount" stackId="1" stroke="#8884d8" fill="#8884d8" />
                            }
                            {chartType === "DAILY_AVERAGE_SALES" &&
                                <Area type="monotone" dataKey="average_daily_sales" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                            }
                            {
                                chartType === "REGISTRATION_BREAKDOWN" &&
                                <>
                                    <Area type="monotone" dataKey="registered_via_blank" stackId="1" stroke="#ffc658" fill="#ffc658" />
                                    <Area type="monotone" dataKey="registered_via_admin" stackId="1" stroke="#8884d8" fill="#8884d8" />
                                </>
                            }
                            <Brush />
                        </AreaChart>



                    </ResponsiveContainer>



                </div>


                <div className={styles.operatingWrapper}>
                    <button onClick={() => setChartType("MONTHLY_REVENUE")}>Show Monthly Sales</button>
                    <button onClick={() => setChartType("DAILY_AVERAGE_SALES")}>Show Daily Average</button>
                    <button onClick={() => setChartType("GROWTH_RATE")}>Show Growth Rate</button>
                </div>

            </div>


            <div className={styles.mainContainer}>
                <div className={styles.mainTitleWrapper}>
                    <div>
                        Monthly Revenue Plot - 2
                    </div>
                </div>
                <ResponsiveContainer width="100%" height={350}>
                    <LineChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" orientation="right" />
                        <Tooltip />
                        <Legend />
                        <Line yAxisId="left" type="monotone" dataKey="total_amount" stroke="#8884d8" activeDot={{ r: 8 }} />
                        <Line yAxisId="left" type="monotone" dataKey="average_sales" stroke="#8884d860" activeDot={{ r: 8 }} />
                        <Line yAxisId="right" type="monotone" dataKey="total_sessions" stroke="#82ca9d" />
                        <Line yAxisId="right" type="step" dataKey="avg_sessions" stroke="#82ca9d60" />
                        <Brush />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default PlotMonthlyRevenue