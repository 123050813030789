import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './ManageEachStaffWorkBookingData.module.css'

const ManageEachStaffWorkBookingData = ({ workDetails }) => {

    const [filteredData, setFilteredData] = useState(null)
    const [servicesFilterGender, setServicesFilterGender] = useState("1")

    const createFilteredData = () => {
        // setFilteredData(workDetails?.dailyTransData[0]?.allServices)
        setFilteredData(workDetails?.dailyTransData[0]?.allServices.filter((item) =>
            item.genderIdentifier === servicesFilterGender
        ))
    }

    const uniqueCategories = filteredData?.reduce((acc, item) => {
        const existingCategory = acc.find(categoryIdentifier => categoryIdentifier.categoryIdentifier === item.categoryIdentifier);

        if (existingCategory) {
            existingCategory.count++;
            return acc;
        }

        const newCategory = {
            categoryName: item.categoryName,
            categoryIdentifier: item.categoryIdentifier,
            count : 1
        };

        return [...acc, newCategory];
    }, []);

    console.log(uniqueCategories)

    useEffect(() => {
        createFilteredData()
    }, [workDetails, servicesFilterGender])


    return (
        <div className={styles.bookingsWrapper}>
            <div className={styles.bookingsHeader}>
                <button onClick={() => setServicesFilterGender("1")}>Gents Services</button>
                <button onClick={() => setServicesFilterGender("2")}>Ladies Services</button>
                {filteredData?.length}

                <div>
                    {uniqueCategories?.map((item, index) => (
                        <button key={index}>{item.categoryName} - ({item.count}) </button>
                    ))}
                </div>

            </div>
            <div className={styles.bookingsContainer}>
                <div className={styles.workTransactionWrapper}>
                    {filteredData?.map((item, index) => (
                        <Link to={"/booking/" + item.bookingIdentifier} className={styles.eachBookingRow}
                            target="_blank"
                            key={index}
                        >
                            <div>
                                {/* {item.bookingIdentifier} */}
                                {item.categoryName} -
                                {item.serviceName}
                                <div>
                                    {item.addOnLabel}
                                </div>
                            </div>
                            <div>
                                <div>
                                    &#8377;{item.totalBasePrice}
                                </div>
                                <div>
                                    &#8377;{item.totalDiscPrice}
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ManageEachStaffWorkBookingData