import React, { useContext } from 'react'
import { BiBell, BiHelpCircle } from 'react-icons/bi'
import { FaUserCircle } from 'react-icons/fa'
import AuthContext from '../../store/auth-context'
import styles from './UpperHeader.module.css'

const UpperHeader = () => {
    const authCtx = useContext(AuthContext)
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.leftCol}>
                    <input placeholder="Search..." />
                </div>
                <div className={styles.rightCol}>
                    <BiHelpCircle size={20} color={`#898989`} />
                    <BiBell size={20} color={`#898989`} />
                    <div className={styles.userWrapper}>
                        <div className={styles.userIcon}>
                            <FaUserCircle size={26} />
                        </div>
                        <div className={styles.userContent}>
                            <div className={styles.username}>{JSON.parse(authCtx?.loginData)?.welcomeData?.displayName}</div>
                            <div className={styles.userdes}>{JSON.parse(authCtx?.loginData)?.welcomeData?.role}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpperHeader