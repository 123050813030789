import React, { useContext, useEffect, useState } from 'react'
import RatingBarGraph from '../../Components/Feedback/RatingBarGraph'
import ReviewsHolder from '../../Components/Feedback/ReviewsHolder'
import NavAtHome from '../../Components/Navigators/NavAtHome'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from '../Feedback/FeedbackHome.module.css'

const FeedbackHome = props => {
    const authCtx = useContext(AuthContext)
    const storeIdentifier = authCtx.storeIdentifier
    const [isLoading, setIsLoading] = useState(true)
    const [feedbackData, setFeedbackData] = useState([])
    const [avgRating, setAvgRating] = useState(5)

    const getAllFeedbacks = async () => {
        setIsLoading(true)
        const getAllFeedbacksResponse = await fetch(BASE_URL_API+"getAllFeedbacks",
        {
            method : 'POST',
            body : JSON.stringify({
                token : authCtx.token,
                storeIdentifier : storeIdentifier
            })
        });

        if(!getAllFeedbacksResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllFeedbacksRespo = await getAllFeedbacksResponse.json();
            if(getAllFeedbacksRespo.status==='success') {
                setFeedbackData(getAllFeedbacksRespo.response)
            } else {
                if(getAllFeedbacksRespo.message==='tokenExpired') {
                    authCtx.logout()
                } else {
                    setFeedbackData([])
                }
            }
            console.log(getAllFeedbacksRespo)
        }
        setIsLoading(false)
    }

    const findAvg = () => {
        let tempSum = 5;
        feedbackData?.map((item, index) => tempSum = tempSum + parseFloat(item.selectedStar))
        setAvgRating(parseFloat(tempSum/(feedbackData.length)).toFixed(1))
    }

    useEffect(() => {
        feedbackData.length>0 && findAvg()
    }, [feedbackData])
    

    useEffect(() => {
        getAllFeedbacks()
    }, [storeIdentifier])
    
    

    return (
        <div className={styles.mainWrapper}>
            <NavAtHome />
            <div className={styles.headerWrapper}>
                All feedbacks
            </div>
            <div className={styles.bodyWrapper}>
                <div className={styles.leftWrapper}>
                    <div>
                        <div className={styles.avgRating}>
                            {avgRating}
                        </div>
                        <div>
                            Based on {feedbackData?.length} reviews
                        </div>
                    </div>

                    <div>
                        apple 
                    </div>

                    <RatingBarGraph />


                    <div>
                        <div>
                            What you should improve?
                        </div>
                        <div>
                            Apple 
                        </div>
                    </div>

                    <div>
                        <div>
                            What is good about you?
                        </div>
                        <div>
                            <div>1</div>
                            <div>2</div>
                            <div>3</div>
                        </div>
                    </div>


                </div>
                <div className={styles.rightWrapper}>
                    <ReviewsHolder isLoading={isLoading} feedbackData={feedbackData} />
                </div>
            </div>
        </div>
    )
}

export default FeedbackHome