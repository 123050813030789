import React, { useContext, useEffect, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { sanitizeUsername, validateUsername } from '../../Helper/calculations'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from './CreateAuthCredentials.module.css'
import { useParams } from 'react-router-dom'

const CreateAuthCredentials = (props) => {
    const { isOpen, onClose, onSuccess } = props
    const authCtx = useContext(AuthContext)
    const vendorIdentifier = JSON.parse(authCtx.loginData)?.partnersData?.vendorIdentifier
    const { employeeIdentifier } = useParams()


    window.onclick = event => event.target.id === "cacmcid" ? onClose() : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });

    const [displayName, setDisplayName] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState(null)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [isGeneratingCredentials, setIsGeneratingCredentials] = useState(false)
    const [fieldsValidated, setFieldsValidated] = useState(false)


    const generatePassword = (length) => {
        const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789$@#";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        setPassword(result)
    }

    const generateStaffAuth = async () => {
        setIsGeneratingCredentials(true)
        const generateStaffAuthResponse = await fetch(BASE_URL_API + "generateStaffAuth",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    staffIdentifier: employeeIdentifier,
                    vendorIdentifier : vendorIdentifier,
                    displayName: displayName,
                    username: username,
                    password: password,
                    role: role
                })
            });

        if (!generateStaffAuthResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const generateStaffAuthRespo = await generateStaffAuthResponse.json()
            if (generateStaffAuthRespo.status === "success") {
                onSuccess()
            } else {
                if (generateStaffAuthRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(generateStaffAuthRespo)
        }
        setIsGeneratingCredentials(false)
    }

    const validateFieldHandler = () => {
        if(displayName?.length>1) {
            if(validateUsername(username)) {
                if(password!=='') {
                    if(role!==null) {
                        setFieldsValidated(true)
                    } else {
                        setFieldsValidated(false)
                    }
                } else {
                    setFieldsValidated(false)
                }
            } else {
                setFieldsValidated(false)
            }
        } else {
            setFieldsValidated(false)
        }
    }

    useEffect(() => {
        validateFieldHandler()
    }, [displayName, username, password, role])
    


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} id="cacmcid">
                    <div className={styles.mainContent}>
                        <div className={styles.formWrapper}>
                            <div className={styles.eachInput}>
                                <input placeholder="Enter display name..." autoFocus 
                                    onChange={(e) => setDisplayName(e.target.value)}
                                    value={displayName}
                                />
                            </div>
                            <div className={styles.eachInput}>
                                <input placeholder="Enter username..." value={username} onChange={(e) => setUsername(sanitizeUsername(e.target.value))} />
                            </div>
                            <div className={styles.eachPassword}>
                                <input placeholder="Enter password..." type={isPasswordVisible === true ? `text` : `password`} value={password} readOnly />
                                <div className={styles.passwordToggle} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                                    {isPasswordVisible === true ?
                                        <FaEye size={20} color="#818181" />
                                        :
                                        <FaEyeSlash size={20} color="#818181" />
                                    }
                                </div>
                                <button onClick={() => generatePassword(10)} >Re-generate</button>
                            </div>
                            <div className={styles.roleWrapper}>
                                <select onChange={(e) => setRole(e.target.value)}
                                    defaultValue={null}
                                >
                                    <option value={null}>Select Role</option>
                                    <option value={`SUPERADMIN`}>Super Admin</option>
                                    <option value={`ADMIN`}>Admin</option>
                                    <option value={`MANAGER`}>Manager</option>
                                    <option value={`STAFF`}>Staff</option>
                                    <option value={`DOCTOR`}>Doctor</option>
                                </select>
                            </div>
                            <div className={styles.actionWrapper}>
                                <div>
                                    {isGeneratingCredentials === true ?
                                        <button className={styles.done}>Generating Now...</button>
                                        :
                                        <button className={styles.done} onClick={() => generateStaffAuth()} >Generate</button>
                                    }
                                </div>
                                <div>
                                    <button className={styles.cancel} onClick={() => onClose()}>Cancel</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default CreateAuthCredentials