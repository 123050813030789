import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from './ManageAccess.module.css'
import { Link, useParams } from 'react-router-dom'
import { FaToggleOff, FaToggleOn } from "react-icons/fa"
import { toast, ToastContainer } from 'react-toastify'

const ManageAccess = () => {
    const authCtx = useContext(AuthContext)
    const loginData = JSON.parse(authCtx?.loginData)
    const { authIdentifier } = useParams()
    const [accessData, setAccessData] = useState([])
    const [storeAccessData, setStoreAccessData] = useState([])
    const [employeeRecords, setEmployeeRecords] = useState([])
    const [isLoadingData, setIsLoadingData] = useState(false)
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
    const [activeTab, setActiveTab] = useState('Store')

    const getStaffAccessibilityStatus = async () => {
        setIsLoadingData(true)
        const getStaffAccessibilityStatusResponse = await fetch(BASE_URL_API + "getStaffAccessibilityStatus",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    authIdentifier: authIdentifier,
                    vendorIdentifier: loginData?.partnersData?.vendorIdentifier
                })
            });

        if (!getStaffAccessibilityStatusResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getStaffAccessibilityStatusRespo = await getStaffAccessibilityStatusResponse.json()
            if (getStaffAccessibilityStatusRespo.status === "success") {
                setAccessData(getStaffAccessibilityStatusRespo?.response?.allAccess)
                setEmployeeRecords(getStaffAccessibilityStatusRespo?.response?.employeeRecords)
            } else {
                if (getStaffAccessibilityStatusRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getStaffAccessibilityStatusRespo)
        }
        setIsLoadingData(false)
    }

    const togglePageAccessStatus = async (k) => {
        setIsUpdatingStatus(true)
        const togglePageAccessStatusResponse = await fetch(BASE_URL_API + "togglePageAccessStatus",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    authIdentifier: authIdentifier,
                    pageUrl: k?.pageUrl
                })
            });

        if (!togglePageAccessStatusResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const togglePageAccessStatusRespo = await togglePageAccessStatusResponse.json()
            if (togglePageAccessStatusRespo.status === "success") {
                toast("Access successfully updated!")
                getStaffAccessibilityStatus()
            } else {
                if (togglePageAccessStatusRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(togglePageAccessStatusRespo)
        }
        setIsUpdatingStatus(false)
    }



    useEffect(() => {
        getStaffAccessibilityStatus()
    }, [authIdentifier])

    const getStoreAccessibilityStatus = async () => {
        setIsLoadingData(true)
        const getStoreAccessibilityStatusResponse = await fetch(BASE_URL_API + "getStoreAccessibilityStatus",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    authIdentifier: authIdentifier,
                    vendorIdentifier: loginData?.partnersData?.vendorIdentifier
                })
            });

        if (!getStoreAccessibilityStatusResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getStoreAccessibilityStatusRespo = await getStoreAccessibilityStatusResponse.json()
            if (getStoreAccessibilityStatusRespo.status === "success") {
                setStoreAccessData(getStoreAccessibilityStatusRespo?.response?.allAccess)
            } else {
                if (getStoreAccessibilityStatusRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setStoreAccessData([])
                }
            }
            console.log(getStoreAccessibilityStatusRespo)
        }
        setIsLoadingData(false)
    }

    useEffect(() => {
        if (activeTab === 'Store') {
            getStoreAccessibilityStatus()
        }
    }, [activeTab])


    const toggleStoreAccessStatus = async (k) => {
        setIsUpdatingStatus(true)
        const toggleStoreAccessStatusResponse = await fetch(BASE_URL_API + "toggleStoreAccessStatus",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    authIdentifier: authIdentifier,
                    storeIdentifier: k?.storeIdentifier
                })
            });

        if (!toggleStoreAccessStatusResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const toggleStoreAccessStatusRespo = await toggleStoreAccessStatusResponse.json()
            if (toggleStoreAccessStatusRespo.status === "success") {
                toast("Store access successfully updated!")
                getStoreAccessibilityStatus()
            } else {
                if (toggleStoreAccessStatusRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    console.log(toggleStoreAccessStatusRespo)
                }
            }
        }
        setIsUpdatingStatus(false)
    }



    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainHeader}>
                <div className={styles.detailsWrapper}>
                    <div className={styles.displayName}>
                        {employeeRecords?.displayName}
                    </div>
                    <div className={styles.role}>
                        {employeeRecords?.role}
                    </div>
                </div>
                <Link to={`/store/employee/${employeeRecords?.staffIdentifier}`} className={styles.loginWrapper}>
                    <div className={styles.username}>
                        {employeeRecords?.username}
                    </div>
                    {employeeRecords?.isLoginEnabled === "1" ?
                        <div className={styles.loginEnabled}>
                            Login Enabled
                        </div>
                        :
                        <div className={styles.loginDisabled}>
                            Login Disabled
                        </div>
                    }
                </Link>
            </div>
            <div className={styles.tabOption} >
                <div className={activeTab === `Store` ? styles.eachTabOptionActive : styles.eachTabOption}
                    onClick={() => setActiveTab('Store')}
                >
                    Store Access
                </div>
                <div className={activeTab === `Navigation` ? styles.eachTabOptionActive : styles.eachTabOption}
                    onClick={() => setActiveTab('Navigation')}
                >
                    Navigation Access
                </div>
                <div className={activeTab === `Pages` ? styles.eachTabOptionActive : styles.eachTabOption}
                    onClick={() => setActiveTab('Pages')}
                >
                    Pages Access
                </div>
            </div>

            {activeTab === "Store" &&
                <div className={styles.featureTable}>
                    <div className={styles.featureHeader}>
                        <div className={styles.sn}>
                            #
                        </div>
                        <div className={styles.storeDetailsCol}>
                            Store Details
                        </div>
                        <div className={styles.isAccessible}>
                            Status
                        </div>
                    </div>

                    <div className={styles.tableDataWrapper}>
                        {storeAccessData?.map((item, index) => (
                            <div key={index} className={styles.eachFeatureRow}>
                                <div className={styles.sn}>
                                    {index + 1}.
                                </div>
                                <div className={styles.storeDetailsCol}>
                                    <div>
                                    {item?.storeName}
                                    </div>
                                    <div className={styles.storeAddress}>
                                        {item?.storeAddress}
                                    </div>
                                </div>
                                <div className={styles.isAccessible}
                                    onClick={() => toggleStoreAccessStatus(item)}
                                >
                                    {item?.isAccessible === "false" ?
                                        <FaToggleOff color={`#818181`} size={22} />
                                        :
                                        <FaToggleOn color={`#027FFF`} size={22} />
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }

            {activeTab === "Pages" &&
                <div className={styles.featureTable}>
                    <div className={styles.featureHeader}>
                        <div className={styles.sn}>
                            #
                        </div>
                        <div className={styles.featureLabel}>
                            Feature
                        </div>
                        <div className={styles.pageUrl}>
                            Page Url
                        </div>
                        <div className={styles.isAccessible}>
                            Status
                        </div>
                    </div>

                    <div className={styles.tableDataWrapper}>
                        {accessData?.map((item, index) => (
                            <div key={index} className={styles.eachFeatureRow}>
                                <div className={styles.sn}>
                                    {index + 1}.
                                </div>
                                <div className={styles.featureLabel}>
                                    {item?.featureLabel}
                                </div>
                                <div className={styles.pageUrl}>
                                    {item?.pageUrl}
                                </div>
                                <div className={styles.isAccessible}
                                    onClick={() => togglePageAccessStatus(item)}
                                >
                                    {item?.isAccessible === "false" ?
                                        <FaToggleOff color={`#818181`} size={22} />
                                        :
                                        <FaToggleOn color={`#027FFF`} size={22} />
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }

        </div>
    )
}

export default ManageAccess