import React from 'react'
import { FiMoreVertical } from 'react-icons/fi';
import styles from '../Bookings/CSEachBookings.module.css'
import CSBookingDetails from './CSBookingDetails';
import CSServices from './CSServices';
import { Link } from 'react-router-dom'

const CSEachBookings = props => {
    const item = props.item;
    return (
        <Link to={item?.bookingDetails['currentStatus'] === "BOOKING_INITIATED" ? `/sessions/${item?.bookingDetails["bookingId"]}` : `/booking/` + item.bookingDetails["bookingId"]}
            className={styles.eachBookingWrapper}
        >
            <div className={styles.moreWrapper}>
                <FiMoreVertical />
            </div>
            <CSBookingDetails item={item} />
            <CSServices item={item} />
        </Link>
    )
}

export default CSEachBookings