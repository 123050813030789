import React, { useContext, useState } from 'react'
import { FaMinus, FaPlus, FaUser } from 'react-icons/fa';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import styles from '../Sessions/EachItemQuantUpdate.module.css'

const EachItemQuantUpdate = props => {
    let item = props.item;
    const getCartItems = props.getCartItems
    const authCtx = useContext(AuthContext)
    const [isRemoving, setIsRemoving] = useState(false)
    const fetchingCartItems = props.fetchingCartItems

    const quantityChangeHandler = async (k, a) => {
        setIsRemoving(true)
        const updateCartItemQuantityResponse = await fetch(BASE_URL_API + "updateCartItemQuantity",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    cartItemIdentifier: k,
                    actionType: a
                })
            });

        if (!updateCartItemQuantityResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateCartItemQuantityRespo = await updateCartItemQuantityResponse.json();
            if (updateCartItemQuantityRespo.status === 'success') {
                getCartItems()
            } else {
                if (updateCartItemQuantityRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    console.log(updateCartItemQuantityRespo)
                }
            }
        }
        setIsRemoving(false)
    }

    return (
        <div className={styles.eachRemovableItem}>
            <div>
                <div className={styles.serviceName}>
                    {item.serviceName}
                </div>
                <div className={styles.price}>
                    &#8377;{item.totalDiscPrice}
                </div>
                <div>
                    {item.addOns.map((itemAddOns, indexAddOns) => (
                        <div key={indexAddOns + "s"} >
                            <div className={styles.addOnLabel}>
                                {itemAddOns.addOnLabel}
                            </div>
                            <div className={styles.servedByName}>
                                <FaUser size={10} />&nbsp;{itemAddOns.servedByName}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.buttonWrapper}>
                {isRemoving === false ?
                    <>
                        {fetchingCartItems === false ?
                            <div className={styles.buttonContainer}>
                                <button onClick={() => quantityChangeHandler(item.cartItemIdentifier, 'dec')}>
                                    <FaMinus size={12} />
                                </button>
                                <div>
                                    {item.quantity}
                                </div>
                                <button onClick={() => quantityChangeHandler(item.cartItemIdentifier, 'inc')}>
                                    <FaPlus size={12} />
                                </button>

                            </div>
                            :
                            <div className={styles.loaderBtn}>
                                <ShimmerThumbnail height={24} rounded />
                            </div>
                        }
                    </>
                    :
                    <div className={styles.loaderBtn}>
                        <ShimmerThumbnail height={24} rounded />
                    </div>
                }
            </div>
        </div>
    )
}

export default EachItemQuantUpdate