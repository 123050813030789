import React, { useContext, useEffect, useState } from 'react'
import { getAge } from '../../Helper/calculations'
import styles from './UserDetails.module.css'
import { isEqual } from 'lodash';
import { countryCodesHolder } from '../../Helper/countryCodes';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import { toast, ToastContainer } from 'react-toastify';

const UserDetails = (props) => {
    const { clientDetails: item, getClientFullDetails } = props
    const [clientDetailsToUpdate, setClientDetailsToUpdate] = useState(item)
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [hasEdited, setHasEdited] = useState(false)
    const [mode, setMode] = useState('View')
    const [isUpdating, setIsUpdating] = useState(false)

    useEffect(() => {
        if (isEqual(item, clientDetailsToUpdate)) {
            setHasEdited(false);
        } else {
            setHasEdited(true);
        }
    }, [clientDetailsToUpdate]);


    const [ageYear, setAgeYear] = useState('')
    const [ageMonth, setAgeMonth] = useState('')
    const [years, setYears] = useState([]);

    useEffect(() => {
        const yearsArray = [];

        for (let year = 120; year >= 0; year--) {
            yearsArray.push(year);
        }

        setYears(yearsArray);
    }, []);

    const getPastDate = (year, month) => {
        // Get the current date
        const currentDate = new Date();

        // Create a new Date object for the given year and month, setting the day to 1
        let date = new Date(currentDate.getFullYear() - year, month - 1, 1);

        // If the generated date is in the future, adjust the year backward by 1 year
        if (date > currentDate) {
            date.setFullYear(date.getFullYear() - 1);
        }

        // Format the date as "yyyy-MM-dd"
        const formattedDate = date.toISOString().split('T')[0];

        setClientDetailsToUpdate((prevDetails) => ({
            ...prevDetails,
            client_birthday: formattedDate + " 00:00:01",
        }));
    };

    useEffect(() => {
        if(ageYear!=='') {
            getPastDate(ageYear, ageMonth)
        }
    }, [ageYear, ageMonth])


    const updateClientDetails = async () => {
        // console.log(clientDetailsToUpdate)
        setIsUpdating(true)
        const updateClientDetailsResponse = await fetch(BASE_URL_API + "updateClientDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    clientStoreRelationIdentifier: clientDetailsToUpdate?.clientStoreRelationIdentifier,
                    salutation: clientDetailsToUpdate?.salutation,
                    client_name: clientDetailsToUpdate?.client_name,
                    countryCode: clientDetailsToUpdate?.countryCode,
                    mobile: clientDetailsToUpdate?.mobile,
                    whatsAppNumber: clientDetailsToUpdate?.whatsAppNumber,
                    alternateNumber: clientDetailsToUpdate?.alternateNumber,
                    client_email: clientDetailsToUpdate?.client_email,
                    gender: clientDetailsToUpdate?.gender,
                    client_address: clientDetailsToUpdate?.client_address,
                    client_birthday: clientDetailsToUpdate?.client_birthday,
                    client_address: clientDetailsToUpdate?.client_address
                })
            });

        if (!updateClientDetailsResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const updateClientDetailsRespo = await updateClientDetailsResponse.json()
            if (updateClientDetailsRespo.status === "success") {
                toast(`Successfully updated`)
                setMode('View')
                getClientFullDetails()
            } else {
                if (updateClientDetailsRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateClientDetailsRespo)
        }
        setIsUpdating(false)
    }

    console.log(clientDetailsToUpdate)

    return (
        <div className={styles.detailsWrapper}>
            <ToastContainer />
            <div className={styles.actionWrapper}>
                <button onClick={() => setMode(mode === `View` ? 'Editing' : `View`)} >{mode === `View` ? `Edit` : `Cancel`}</button>
                {mode === `Editing` &&
                    <div>
                        {hasEdited === false ? <button disabled>Update</button>
                            :
                            isUpdating === true ?
                                <button onClick={() => alert(`Please wait...`)} className={styles.updateActive} >Updating..</button>
                                :
                                <button onClick={() => updateClientDetails()} className={styles.updateActive} >Update</button>
                        }
                    </div>
                }
            </div>
            {mode === `Editing` &&
                <div className={styles.contentWrapper}>
                    <div className={styles.nameWrapper}>
                        <select
                            value={item?.salutation}
                            onChange={(e) => {
                                setClientDetailsToUpdate((prevDetails) => ({
                                    ...prevDetails,
                                    salutation: e.target.value,
                                }));
                            }}
                        >
                            <option disabled>Salutation</option>
                            <option>Ms</option>
                            <option>Mr.</option>
                            <option>Mrs.</option>
                            <option>Baby</option>
                            <option>Dr.</option>
                        </select>
                        <input placeholder="Please enter name"
                            autoFocus
                            className={styles.clientName}
                            value={clientDetailsToUpdate?.client_name}
                            onChange={(e) => {
                                setClientDetailsToUpdate((prevDetails) => ({
                                    ...prevDetails,
                                    client_name: e.target.value,
                                }));
                            }}
                        />
                    </div>
                    <div className={styles.primaryPhoneWrapper}>
                        <div className={styles.countryCode}>
                            <select
                                defaultValue={clientDetailsToUpdate?.countryCode}
                                onChange={(e) => {
                                    setClientDetailsToUpdate((prevDetails) => ({
                                        ...prevDetails,
                                        countryCode: e.target.value,
                                    }));
                                }}
                            >
                                {countryCodesHolder?.map((item, index) => (
                                    <option value={item?.code}>{item?.country} - {item?.code}</option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.mobile}>
                            <input placeholder="Please enter phone number"
                                value={clientDetailsToUpdate?.mobile}
                                onChange={(e) => {
                                    setClientDetailsToUpdate((prevDetails) => ({
                                        ...prevDetails,
                                        mobile: e.target.value,
                                    }));
                                }}
                            />
                        </div>
                    </div>

                    <div className={styles.phoneNumbers} >
                        <div>
                            <input placeholder="Enter alternate phone number..."
                                value={clientDetailsToUpdate?.alternateNumber}
                                onChange={(e) => {
                                    setClientDetailsToUpdate((prevDetails) => ({
                                        ...prevDetails,
                                        alternateNumber: e.target.value,
                                    }));
                                }}
                            />
                        </div>
                        <div>
                            <input placeholder="Enter whatsApp phone number..."
                                value={clientDetailsToUpdate?.whatsAppNumber}
                                onChange={(e) => {
                                    setClientDetailsToUpdate((prevDetails) => ({
                                        ...prevDetails,
                                        whatsAppNumber: e.target.value,
                                    }));
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.emailWrapper}>
                        <div>
                            <input placeholder="Enter e-mail"
                                value={clientDetailsToUpdate?.client_email}
                                onChange={(e) => {
                                    setClientDetailsToUpdate((prevDetails) => ({
                                        ...prevDetails,
                                        client_email: e.target.value,
                                    }));
                                }}
                            />
                        </div>
                        <div>
                            <select
                                onChange={(e) => {
                                    setClientDetailsToUpdate((prevDetails) => ({
                                        ...prevDetails,
                                        gender: e.target.value,
                                    }));
                                }}
                                value={item?.gender}
                            >
                                <option disabled>Select Gender</option>
                                <option>Female</option>
                                <option>Male</option>
                                <option>LGBTQ+</option>
                                <option>Others</option>
                            </select>
                        </div>
                    </div>

                    <div className={styles.ageInputWrapper}>
                        <div className={styles.yearsWrapper}>
                            <select onChange={(e) => setAgeYear(e.target.value)}
                                value={ageYear}
                            >
                                <option value={null}>Select Age (Year)</option>
                                {years?.map((item, index) => (
                                    <option key={index} value={item}>{item} years</option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.monthsWrapper}>
                            <select onChange={(e) => setAgeMonth(e.target.value)}
                                value={ageMonth}
                            >
                                <option value={null}>Select Age (Month)</option>
                                <option value={0} >0 Month</option>
                                <option value={1} >1 Month</option>
                                <option value={2} >2 Months</option>
                                <option value={3} >3 Months</option>
                                <option value={4} >4 Months</option>
                                <option value={5} >5 Months</option>
                                <option value={6} >6 Months</option>
                                <option value={7} >7 Months</option>
                                <option value={8} >8 Months</option>
                                <option value={9} >9 Months</option>
                                <option value={10} >10 Months</option>
                                <option value={11} >11 Months</option>
                                <option value={12} >12 Months</option>
                            </select>
                        </div>
                    </div>

                    <div className={styles.address}>
                        <textarea placeholder="Please enter address"
                            value={clientDetailsToUpdate?.client_address}
                            onChange={(e) => {
                                setClientDetailsToUpdate((prevDetails) => ({
                                    ...prevDetails,
                                    client_address: e.target.value,
                                }));
                            }}
                            rows={4}
                        />
                    </div>
                </div>
            }
            {mode === `View` &&
                <div className={styles.contentWrapper}>
                    <div className={styles.fullName}>
                        <div>
                            {item?.salutation}
                        </div>
                        <div>
                            {item?.client_name}
                        </div>
                    </div>
                    <div className={styles.updatemobile}>
                        <div>
                            {item?.countryCode}
                        </div>
                        <div>
                            {item?.mobile}
                        </div>
                    </div>

                    <div className={styles.uPhones}>
                        <div>
                            <div className={styles.fTitle}>
                                Alternate Phone
                            </div>
                            <div className={styles.fValue}>
                                {item?.alternateNumber}
                            </div>
                        </div>
                        <div>
                            <div className={styles.fTitle}>
                                WhatsApp Number
                            </div>
                            <div className={styles.fValue}>
                                {item?.whatsAppNumber}
                            </div>
                        </div>
                    </div>

                    <div className={styles.otherField}>
                        <div>
                            <div className={styles.fTitle}>
                                E-mail
                            </div>
                            <div className={styles.fValue}>
                                {item?.client_email}
                            </div>
                        </div>
                        <div>
                            <div className={styles.fTitle}>
                                Age
                            </div>
                            <div className={styles.fValue}>
                                {getAge(item?.client_birthday)} years old
                            </div>
                        </div>
                        <div>
                            <div className={styles.fTitle}>
                                Gender
                            </div>
                            <div className={styles.fValue}>
                                {item?.gender}
                            </div>
                        </div>
                    </div>


                    <div>
                        <div className={styles.fTitle}>
                            Address
                        </div>
                        <div className={styles.fValue}>
                            {item?.client_address}
                        </div>
                    </div>

                </div>
            }
        </div>
    )
}

export default UserDetails