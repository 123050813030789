import React, { useState, useMemo, useRef, useEffect } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import styles from '../Sessions/PriceListCategoryServices.module.css';
import AddOnsSelector from './AddOnsSelector';
import RemoveCartItem from './RemoveCartItem';

const PriceListCategoryServices = ({ sessionIdentifier, getCartItems, workersData, itemServices, showDiscount, cartItems, fetchingCartItems }) => {
    const [isAddOnOpen, setIsAddOnOpen] = useState(false);
    const [isRemoveOpen, setIsRemoveOpen] = useState(false);
    const [itemServicesCurrent, setItemServicesCurrent] = useState(itemServices)
    const [isEditingPrice, setIsEditingPrice] = useState(false)
    const inputRef = useRef(null);


    // Calculate whether the item has been added and the total quantity
    const { hasAdded, quantity, removableItem } = useMemo(() => {
        let removableItem = [];
        let quantity = 0;
        const hasAdded = cartItems.some(item => item.serviceIdentifier === itemServicesCurrent.identifier);

        if (hasAdded) {
            cartItems.forEach(item => {
                if (item.serviceIdentifier === itemServicesCurrent.identifier) {
                    quantity += parseInt(item.quantity);
                    removableItem.push(item);
                }
            });
        }
        return { hasAdded, quantity, removableItem };
    }, [cartItems, itemServicesCurrent.identifier]);

    const addToCartInitiator = () => setIsAddOnOpen(true);
    const abortAddToCart = () => setIsAddOnOpen(false);
    const removeItemInitiator = () => setIsRemoveOpen(true);
    const abortItemRemoval = () => setIsRemoveOpen(false);

    const calculateDiscountedPrice = (basePrice, offerPercentage) => {
        const discount = parseFloat(offerPercentage || 0);
        return basePrice - (basePrice * discount / 100);
    };

    const updateItemServiceBasePrice = (k) => {
        setItemServicesCurrent((prevDetails) => ({
            ...prevDetails,
            basePrice: k
        }))
    }


    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setIsEditingPrice(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div className={styles.mainWrapper}>
            <AddOnsSelector
                isOpen={isAddOnOpen}
                abortAddToCart={abortAddToCart}
                item={itemServicesCurrent}
                workersData={workersData}
                getCartItems={getCartItems}
                fetchingCartItems={fetchingCartItems}
                sessionIdentifier={sessionIdentifier}
            />
            <RemoveCartItem
                isOpen={isRemoveOpen}
                abortItemRemoval={abortItemRemoval}
                removableItem={removableItem}
                getCartItems={getCartItems}
                fetchingCartItems={fetchingCartItems}
            />

            <div className={styles.serviceInfo}>
                <div className={styles.serviceName}>{itemServicesCurrent.serviceName}</div>
                {itemServicesCurrent?.categoryName !== 'MEDICINE' && (
                    <div className={styles.serviceDuration}>
                        {itemServicesCurrent?.serviceDuration || itemServicesCurrent?.serviceTime} {itemServicesCurrent?.serviceDurationUnit || 'minutes'}
                    </div>
                )}
                <div className={styles.description}>{itemServicesCurrent?.description}</div>
            </div>

            <div className={styles.siRightWrapper}>
                <div className={styles.serviceAmount}>
                    <div className={showDiscount ? styles.cutOrgPrice : styles.basePrice}
                    >
                        {isEditingPrice === false ?
                            <div onClick={() => setIsEditingPrice(true)}>₹{itemServicesCurrent.basePrice}</div>
                            :
                            <div className={styles.editingMode}>
                                ₹{itemServices?.basePrice}
                                <input
                                    ref={inputRef}
                                    onBlur={() => setIsEditingPrice(false)}
                                    value={itemServicesCurrent?.basePrice}
                                    onChange={(e) => updateItemServiceBasePrice(e.target.value)}
                                />
                            </div>
                        }
                    </div>
                    {showDiscount && (
                        <div className={styles.discountedPrice}>
                            ₹{calculateDiscountedPrice(parseFloat(itemServicesCurrent.basePrice), parseFloat(itemServicesCurrent.offerPercentage))}
                        </div>
                    )}
                </div>

                {hasAdded ? (
                    <div className={styles.modifyQuantity}>
                        <button onClick={removeItemInitiator}>
                            <FaMinus size={12} />
                        </button>
                        <div>{quantity}</div>
                        <button onClick={addToCartInitiator}>
                            <FaPlus size={12} />
                        </button>
                    </div>
                ) : (
                    <div className={styles.addToCartBtn}>
                        <button onClick={addToCartInitiator}>Add +</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PriceListCategoryServices;
