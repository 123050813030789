import React, { useEffect, useState } from 'react'
import styles from '../Feedback/ReviewsHolder.module.css'
import EachFeedbackBox from './EachFeedbackBox'

const ReviewsHolder = props => {
    const { isLoading, feedbackData } = props

    const [selectedStar, setSelectedStar] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [isSalonEnvironment, setIsSalonEnvironment] = useState(false)
    const [isServiceResult, setIsServiceResult] = useState(false)
    const [isStaffBehavior, setIsStaffBehavior] = useState(false)

    const [selectedInterval, setSelectedInterval] = useState('');


    const filtersHandler = () => {
        let temp = feedbackData
        if (selectedStar !== '') {
            temp = temp.filter((item) => item.selectedStar === selectedStar)
        }

        if (isSalonEnvironment) {
            temp = temp.filter((item) => item.starSelectedSalonEnvironment !== '0')
        }

        if (isServiceResult) {
            temp = temp.filter((item) => item.starSelectedServiceResults !== '0')
        }

        if (isStaffBehavior) {
            temp = temp.filter((item) => item.starSelectedStaffBehaviour !== '0')
        }

        /// this is for client review counts
        temp = temp.filter(review => {
            const count = temp.filter(r => r.clientId === review.clientId).length;
            switch (selectedInterval) {
                case 'Clients with 1 review':
                    return count === 1;
                case 'Clients with more than 1 review':
                    return count > 1;
                case 'Clients with more than 2 reviews':
                    return count > 2;
                case 'Clients with 5 reviews':
                    return count === 5;
                case 'Clients with more than 5 reviews':
                    return count > 5;
                default:
                    return true; // No interval selected, return all reviews
            }
        })

        setFilteredData(temp)

    }
    console.log(filteredData)


    useEffect(() => {
        filtersHandler()
    }, [selectedStar, isSalonEnvironment, isServiceResult, isStaffBehavior, feedbackData, selectedInterval])



    

    // Create intervals based on the number of reviews
    const intervals = [
        'Clients with 1 review',
        'Clients with more than 1 review',
        'Clients with more than 2 reviews',
        'Clients with 5 reviews',
        'Clients with more than 5 reviews',
    ];





    return (
        <div>
            <div className={styles.rwHeader}>
                <div>
                    <select onChange={(e) => setSelectedStar(e.target.value)} value={selectedStar}>
                        <option value="">All Stars</option>
                        <option value="5">5</option>
                        <option value="4">4</option>
                        <option value="3">3</option>
                        <option value="2">2</option>
                        <option value="1">1</option>
                    </select>
                </div>

                <div className={styles.optionWrapper}>
                    <div>
                        <button onClick={() => setIsSalonEnvironment(!isSalonEnvironment)} 
                            className={isSalonEnvironment===false ? styles.defaultBtn : styles.selectedBtn }
                        >
                            Salon Environment
                        </button>
                        <button onClick={() => setIsServiceResult(!isServiceResult)} 
                            className={isServiceResult===false ? styles.defaultBtn : styles.selectedBtn }
                        >
                            Service Results
                        </button>
                        <button onClick={() => setIsStaffBehavior(!isStaffBehavior)} 
                            className={isStaffBehavior===false ? styles.defaultBtn : styles.selectedBtn }
                        >
                            Staff Behaviour
                        </button>
                    </div>

                    <select value={selectedInterval} onChange={e => setSelectedInterval(e.target.value)}>
                        <option value="">All reviews</option>
                        {intervals.map(interval => (
                            <option key={interval} value={interval}>{interval}</option>
                        ))}
                    </select>
                </div>


                <div>{filteredData.length}</div>
            </div>
            <div className={styles.rwBody}>
                {isLoading === false ?
                    <div>
                        {filteredData?.map((item, index) => (
                            <EachFeedbackBox key={index} item={item} />
                        ))}
                    </div>
                    :
                    <div>
                        Loading....
                    </div>
                }
            </div>
        </div>
    )
}

export default ReviewsHolder