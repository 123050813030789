import React, { useContext, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import styles from '../../Styles/Components/Services/EachAddedService.module.css'
import { Link } from 'react-router-dom'

const EachAddedService = props => {
    const authCtx = useContext(AuthContext);
    const { item, selectServiceHandler, getServices, sections, activeSection } = props

    const [showDiscountForm, setShowDiscountForm] = useState(false);
    const [discountPercentage, setDiscountPercentage] = useState(item.offerPercentage);
    const [amountToBeDiscounted, setAmountToBeDiscounted] = useState(0);
    const [discountedBasePrice, setDiscountedBasePrice] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false)

    const addDiscountInitiate = () => {
        setShowDiscountForm(true)
    }

    const addDiscountPercentageHandler = (k) => {
        if (k < 0) {
            k = 0;
        } else if (k > 100) {
            k = 100;
        }
        if (k == '') {
            k = 0;
            let atbd = parseFloat(item.basePrice) * parseFloat(k) / 100;
            setAmountToBeDiscounted(parseFloat(atbd).toFixed(2))
            setDiscountedBasePrice(parseFloat(item.basePrice - atbd).toFixed(2))
        } else {
            let atbd = parseFloat(item.basePrice) * parseFloat(k) / 100;
            setAmountToBeDiscounted(parseFloat(atbd).toFixed(2))
            setDiscountedBasePrice(parseFloat(item.basePrice - atbd).toFixed(2))
        }
        setDiscountPercentage(k);
    }

    useEffect(() => {
        addDiscountPercentageHandler(discountPercentage)
    }, [])


    const addDiscountHandler = async () => {
        const updateDiscountOfferResponse = await fetch(BASE_URL_API + "updateDiscountOffer",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    identifier: item.identifier,
                    offerPercentage: discountPercentage
                })
            });

        if (!updateDiscountOfferResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const updateDiscountOfferRespo = await updateDiscountOfferResponse.json();
            if (updateDiscountOfferRespo.status == "success") {
                setShowDiscountForm(false);
                addDiscountPercentageHandler(discountPercentage)
            } else {
                console.log(updateDiscountOfferRespo.message)
            }
        }
    }

    const deleteService = async () => {
        setIsDeleting(true)
        const deleteServiceResponse = await fetch(BASE_URL_API + "deleteService",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    identifier: item?.identifier
                })
            });

        if (!deleteServiceResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const deleteServiceRespo = await deleteServiceResponse.json()
            if (deleteServiceRespo.status === "success") {
                toast(`Successfully deleted the service!`)
                getServices()
            } else {
                if (deleteServiceRespo.message === "tokenExpired") {
                    authCtx?.logout()
                } else {
                    console.log(deleteServiceRespo)
                }
            }
        }
        setIsDeleting(false)
    }


    if (item === null) {
        return (
            <div>Null</div>
        )
    }
    return (
        <div className={styles.mainContainer}
            onClick={() => selectServiceHandler(item)}
        >
            <div>
                <ToastContainer />
                <div className={styles.topContainer}>
                    <div>
                        {item.serviceName}
                    </div>
                    <div>
                        ₹{item.basePrice}
                    </div>
                </div>
                <div className={styles.discountedContainer}>
                    <div>
                        Discounted Price ({discountPercentage}%) [-₹{amountToBeDiscounted}]
                    </div>
                    <div>
                        ₹{discountedBasePrice}
                    </div>
                </div>
                <div>
                    <div>
                        {item?.serviceDuration} - {item?.serviceDurationUnit}
                    </div>
                </div>
                <div>
                    <div className={styles.actionContainer}>
                        <div>
                            {showDiscountForm == false ?
                                <div>
                                    <button className={styles.eachActionBtn}
                                        onClick={() => addDiscountInitiate()}
                                    >
                                        Add Discount
                                    </button>
                                </div>
                                :
                                <div>
                                    <input
                                        placeholder="Add discount %"
                                        onChange={(e) => addDiscountPercentageHandler(e.target.value)}
                                        value={discountPercentage}
                                        type="number"
                                    />
                                    <button onClick={() => addDiscountHandler()} >Update</button>
                                    <button onClick={() => setShowDiscountForm(false)}>Cancel</button>
                                </div>
                            }
                        </div>
                        <Link to={`/manage/price-list/update-item/${item?.identifier}`} className={styles.eachActionBtn}>
                            Edit Service
                        </Link>
                        {isDeleting === false ?
                            <div className={styles.eachActionBtn}
                                onClick={() => deleteService()}
                            >
                                Delete Service
                            </div>
                            :
                            <div className={styles.eachActionBtn}>
                                Deleting...
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EachAddedService