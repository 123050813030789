import React, { useContext } from 'react'
import { FaUser, FaUserEdit } from 'react-icons/fa'
import { MdOutlineDelete } from 'react-icons/md'
import { getAge } from '../../Helper/calculations'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from './EachFamilyMember.module.css'

const EachFamilyMember = (props) => {
    const { item, onDeletion } = props
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();


    const deleteFamilyMember = async () => {
        if (window.confirm(`Are you sure, you want to delete ${item?.firstName} from family members!`) === true) {
            const deleteFamilyMemberResponse = await fetch(BASE_URL_API + "deleteClientRelative",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        relativeIdentifier: item?.relativeIdentifier
                    })
                });

            if (!deleteFamilyMemberResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const deleteFamilyMemberRespo = await deleteFamilyMemberResponse.json()
                if (deleteFamilyMemberRespo.status === "success") {
                    onDeletion(item?.firstName)
                } else {
                    if (deleteFamilyMemberRespo.message === "tokenExpired") {
                        authCtx.logout()
                    } else {

                    }
                    console.log(deleteFamilyMemberRespo)
                }
            }
        }
    }


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.userIcon}>
                    <FaUser size={28} />
                </div>
                <div className={styles.userDetails}>
                    <div className={styles.fullName}>{item?.salutation} {item?.firstName} {item?.middleName} {item?.lastName}</div>
                    <div className={styles.age}>{getAge(item?.dateOfBirth)} years old</div>
                </div>
                <div className={styles.relation}>
                    <div className={styles.relationToFamilyHead}>
                    {item?.relationToFamilyHead}
                    </div>
                </div>
                <div className={styles.actionsWrapper} >
                    {/* <button>
                        <FaUserEdit size={20} />
                    </button> */}
                    <button onClick={() => deleteFamilyMember()}>
                        <MdOutlineDelete size={20} />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EachFamilyMember