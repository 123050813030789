import React, { useContext, useEffect, useState } from 'react'
import { BiFemale } from 'react-icons/bi'
import { BsGenderMale } from 'react-icons/bs'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from '../StaffManagement/StarPerformers.module.css'


const StarPerformers = props => {
    const authCtx = useContext(AuthContext)
    const { dates } = props
    const [performanceData, setPerformanceData] = useState([
        {
            servedByIdentifier: "",
            servedByName: "",
            servedByGender: ""
        }
    ])
    const maleUserIcon = "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/user_male2-512.png"
    const femaleUserIcon = "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/user_female2-512.png"

    const getStaffPerformanceSummary = async () => {
        const getStaffPerformanceSummaryResponse = await fetch(BASE_URL_API + "getStaffPerformanceSummary",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: authCtx.storeIdentifier,
                    startDate: dates.startDate,
                    endDate: dates.endDate
                })
            });

        if (!getStaffPerformanceSummaryResponse.status === 'success') {
            console.log("Something went wrong : Server Error")
        } else {
            const getStaffPerformanceSummaryRespo = await getStaffPerformanceSummaryResponse.json()
            if (getStaffPerformanceSummaryRespo.status === 'success') {
                if (getStaffPerformanceSummaryRespo.response !== null) {
                    setPerformanceData(getStaffPerformanceSummaryRespo.response)
                }
            } else {
                if (getStaffPerformanceSummaryRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {

                }
                console.log(getStaffPerformanceSummaryRespo)
            }
            console.log(getStaffPerformanceSummaryRespo)
        }
    }

    useEffect(() => {
        getStaffPerformanceSummary()
    }, [dates])



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.starWrapper}>
                    <div className={styles.starContainer}>
                        <div className={styles.bigGenderIcon}>
                            {performanceData[0]?.servedByGender === 'Male' ? <img src={maleUserIcon} /> : <img src={femaleUserIcon} />}
                        </div>
                        <div >
                            <div className={styles.servedByNameBig}>
                                {performanceData[0]?.servedByName}
                            </div>
                            <div>
                                &#8377;{performanceData[0]?.totalSales}
                            </div>
                            <div>
                                {performanceData[0]?.totalBookings}
                            </div>
                        </div>
                        <div className={styles.positionIconBig}>
                            <img src="https://images.vexels.com/media/users/3/129153/isolated/lists/da985521b2ffcbb95a2ca9d3c9f39afa-1st-place-laurel-badge.png" />
                        </div>
                    </div>
                </div>
                <div className={styles.runnerWrapper}>
                    {performanceData?.slice(1, 3).map((item, index) => (
                        <div key={index} className={styles.eachRunnerWrapper}>
                            <div className={styles.smallGenderIcon}>
                                {item?.servedByGender === 'Male' ? <img src={maleUserIcon} /> : <img src={femaleUserIcon} />}
                            </div>
                            <div className={styles.labelWrapper}>
                                <div className={styles.servedByNameRunner}>
                                    {item?.servedByName}
                                </div>
                                <div className={styles.valueWrapper}>
                                    <div>
                                        &#8377;{item?.totalSales}
                                    </div>
                                    <div>
                                        {item?.totalBookings}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.indiWrapper}>
                    {performanceData?.slice(3)?.map((item, index) => (
                        <div key={index} className={styles.eachIndiWrapper}>
                            <div className={styles.smallGenderIcon}>
                                {item?.servedByGender === 'Male' ? <img src={maleUserIcon} /> : <img src={femaleUserIcon} />}
                            </div>
                            <div>
                                <div className={styles.servedByNameSmall}>
                                    {item?.servedByName}
                                </div>
                                <div>
                                    &#8377;{item?.totalSales}
                                </div>
                                <div>
                                    {item?.totalBookings}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default StarPerformers