import React, { useContext, useEffect, useState } from 'react'
import OtpInput from 'react-otp-input';
import styles from '../Membership/ExtendCard.module.css'
import { HiArrowRight } from "react-icons/hi";
import { GiCash } from "react-icons/gi";
// import { BsQrCode } from "react-icons/bs";
import { MdQrCodeScanner } from "react-icons/md";
import { BiCreditCard, BiLink } from "react-icons/bi";
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import { ShimmerThumbnail } from 'react-shimmer-effects'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ExtendCard = props => {
    const { cardIdentifier, isExtending, toggleIsExtending, getClientMembershipDetails, getCardsUsageAndBalance } = props;
    const authCtx = useContext(AuthContext)
    const [minAmount, setMinAmount] = useState(0)
    const [availableAddOn, setAvailableAddOn] = useState(null)
    const [isLoadingAddOns, setIsLoadingAddOns] = useState(false)
    const [payModeVia, setPayModeVia] = useState({
        viaCash: 0,
        viaQr: 0,
        viaCard: 0,
        viaLink: 0
    })
    const [totalPayCal, setTotalPayCal] = useState(0)
    const [isRenewing, setIsRenewing] = useState(false)

    const [phoneNumber, setPhoneNumber] = useState({ otp: '' })
    const [storePinStatus, setStorePinStatus] = useState('unverified')
    const [spsTxt, setSpsTxt] = useState('Please enter store pin to verify')
    const [renewingStatus, setRenewingStatus] = useState('NOT_RENEWING')
    // const [renewingStatus, setRenewingStatus] = useState('SUCCESS')
    // const [renewingStatus, setRenewingStatus] = useState('FAILED')

    const pinHandler = (otp) => {
        setPhoneNumber({ otp });
    }
    // console.log(phoneNumber)

    window.onclick = function (event) {
        if (event.target.id === "extendCont") {
            toggleIsExtending()
        }

        if (event.target.id === "filtersPanelWrapperMUG") {
            document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft .1s forwards";
        }
    }

    function resizeInput() {
        var input = document.getElementById('amountInp');
        input.style.width = (input.value.length + 1) * 18 + 'px';
        setMinAmount(Number(input.value).toString())
        if (input.value === '0') {
            input.select();
        } else {
            getCardsValueAddition(input.value);
        }
    }

    const getCardsValueAddition = async (k) => {
        setIsLoadingAddOns(true)
        const getCardsValueAdditionResponse = await fetch(BASE_URL_API + "getCardsValueAddition",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: authCtx.storeIdentifier,
                    minAmount: k
                })
            });

        if (!getCardsValueAdditionResponse.ok) {
            console.log("Something went wrong, server error");
        } else {
            const getCardsValueAdditionRespo = await getCardsValueAdditionResponse.json();
            if (getCardsValueAdditionRespo.status === "success") {
                setAvailableAddOn(getCardsValueAdditionRespo.response)
            } else {
                if (getCardsValueAdditionRespo.message === "tokenExpired") {
                    authCtx.logout();
                } else {
                    setAvailableAddOn(null)
                }
            }

            console.log(getCardsValueAdditionRespo)
        }
        setIsLoadingAddOns(false)
    }


    useEffect(() => {
        if (isExtending) {
            resizeInput()
            document.getElementById('amountInp').select()
        }
    }, [isExtending])

    const closeNReset = () => {
        setPayModeVia({
            viaCash: 0,
            viaQr: 0,
            viaCard: 0,
            viaLink: 0
        })
        setMinAmount(0)
        setRenewingStatus('NOT_RENEWING')
        setPhoneNumber({ otp: '' })
        toggleIsExtending()
    }

    const payModeHandler = (val, mode) => {
        setPayModeVia({ ...payModeVia, [mode]: val !== '' ? Number(val).toString() : 0 })
    }

    const totalPayCalculateHandler = () => {
        let allSource = 0
        allSource = Object.values(payModeVia).reduce((acc, val) => parseFloat(acc) + parseFloat(val), 0);
        console.log(allSource)
        setTotalPayCal(allSource)
    }

    useEffect(() => {
        totalPayCalculateHandler()
    }, [payModeVia])

    useEffect(() => {
        setStorePinStatus("unverified")
        setSpsTxt("Please enter a valid store pin to verify")
    }, [phoneNumber])



    const extendCardHandler = async () => {
        setIsRenewing(true)
        const renewCardResponse = await fetch(BASE_URL_API + "renewCard",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    cardNumber: cardIdentifier,
                    storeIdentifier: authCtx.storeIdentifier,
                    minAmount: minAmount,
                    paidViaCash: payModeVia.viaCash,
                    paidViaCard: payModeVia.viaCard,
                    paidViaQr: payModeVia.viaQr,
                    paidViaLink: payModeVia.viaLink,
                    storePin: phoneNumber.otp
                })
            });

        if (!renewCardResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const renewCardRespo = await renewCardResponse.json()
            if (renewCardRespo.status === 'success') {
                setRenewingStatus('SUCCESS')
                toast.success("Congratulations!! Card successfully renewed!")
                closeNReset()
                getCardsUsageAndBalance()
                getClientMembershipDetails()
            } else {
                if (renewCardRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else if (renewCardRespo.message === "invalid store pin") {
                    setStorePinStatus("invalid")
                    setSpsTxt("Invalid store pin entered. Please enter the correct pin.")
                } else {
                    setRenewingStatus('FAILED')
                }
            }
            console.log(renewCardRespo)
        }
        setIsRenewing(false)
        // console.log(phoneNumber.otp, payModeVia, minAmount, authCtx.storeIdentifier, cardIdentifier)
    }

    // console.log(payModeVia.viaQr)
    // console.log(parseFloat(minAmount ?? 0) )
    // console.log( parseFloat(availableAddOn?.valueAdditionAmount ?? 0))
    // console.log(totalPayCal, minAmount)

    
    if (!isExtending) {
        return null
    }
    
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}
                id="extendCont"
                style={{
                    animation: "insertFromRight .1s forwards"
                }}
            >
                {isRenewing === false ?
                    renewingStatus === "FAILED" ?
                        <div className={styles.extenderWrapper}>
                            <div className={styles.extenderWrapperHeader}>
                                failed
                            </div>
                            <div>
                                <button onClick={() => closeNReset()}>
                                    Retry
                                </button>
                            </div>
                        </div>
                        :
                        <div className={styles.extenderWrapper}>
                            <div className={styles.extenderWrapperHeader}>
                                <button onClick={() => toggleIsExtending()}>
                                    Close
                                </button>
                            </div>

                            <div className={styles.extenderWrapperBody}>
                                <div className={styles.ewbTitle}>
                                    <div className={styles.ewbTitleVs}>
                                        Extending
                                    </div>
                                    <div className={styles.ewbTitleSmall}>
                                        {cardIdentifier.substring(0, 4)}-{cardIdentifier.substring(4, 8)}-{cardIdentifier.substring(8, 12)}-{cardIdentifier.substring(12, 16)}
                                    </div>
                                </div>
                                <div className={styles.amountWrapper}>
                                    <div className={styles.amountContainer}>
                                        <div className={styles.rupeeIcon}>&#8377;</div>
                                        <input autoFocus id="amountInp" onChange={() => resizeInput()}
                                            type="number"
                                            value={minAmount}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className={styles.valueAdditionWrapper}>
                                        +<span>Additional Benefit</span>&nbsp;
                                        <span>&#8377;{availableAddOn?.valueAdditionAmount ?? 0}</span>
                                    </div>
                                    <div className={styles.creditableAmountWrapper}>
                                        <div> Total Creditable Amount&nbsp;</div>
                                        {isLoadingAddOns ?
                                            <ShimmerThumbnail height={20} className={styles.creditableAmountLoader} />
                                            :
                                            <div className={styles.creditableAmountTxt}>&#8377;{parseFloat(isNaN(minAmount) ? 0 : minAmount !== '' ? minAmount : 0) + parseFloat(availableAddOn?.valueAdditionAmount ?? 0)}/-</div>
                                        }
                                    </div>
                                </div>
                                <div className={styles.payModeWrapper}>
                                    <div className={styles.eachPayMode}>
                                        <div className={styles.epmLeft}>
                                            <div className={styles.leftIconWrapper}>
                                                <GiCash size={24} />
                                            </div>
                                            <div className={styles.modeTxt}>
                                                Cash
                                            </div>
                                        </div>
                                        <div className={styles.epmRight}>
                                            <input type="number" onChange={(e) => payModeHandler(e.target.value, "viaCash")} value={payModeVia.viaCash}

                                            />
                                        </div>
                                    </div>

                                    <div className={styles.eachPayMode}>
                                        <div className={styles.epmLeft}>
                                            <div className={styles.leftIconWrapper}>
                                                <MdQrCodeScanner size={24} />
                                            </div>
                                            <div className={styles.modeTxt}>
                                                QR
                                            </div>
                                        </div>
                                        <div className={styles.epmRight}>
                                            <input type="number" onChange={(e) => payModeHandler(e.target.value, "viaQr")} value={payModeVia.viaQr}

                                            />
                                        </div>
                                    </div>

                                    <div className={styles.eachPayMode}>
                                        <div className={styles.epmLeft}>
                                            <div className={styles.leftIconWrapper}>
                                                <BiCreditCard size={24} />
                                            </div>
                                            <div className={styles.modeTxt}>
                                                Card
                                            </div>
                                        </div>
                                        <div className={styles.epmRight}>
                                            <input type="number" onChange={(e) => payModeHandler(e.target.value, "viaCard")} value={payModeVia.viaCard}

                                            />
                                        </div>
                                    </div>

                                    <div className={styles.eachPayMode}>
                                        <div className={styles.epmLeft}>
                                            <div className={styles.leftIconWrapper}>
                                                <BiLink size={24} />
                                            </div>
                                            <div className={styles.modeTxt}>
                                                Link
                                            </div>
                                        </div>
                                        <div className={styles.epmRight}>
                                            <input type="number" onChange={(e) => payModeHandler(e.target.value, "viaLink")} value={payModeVia.viaLink}

                                            />
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    {totalPayCal}
                                </div>
                            </div>

                            {totalPayCal === Number(minAmount) && totalPayCal !== 0 ?
                                <div className={styles.extenderWrapperFooter}>
                                    <div className={styles.pinWrapper}>
                                        <div className={storePinStatus === "invalid" ? styles.pinTitleWrapperDanger : styles.pinTitleWrapper}>
                                            {spsTxt}
                                        </div>
                                        <div className={styles.upperRow}>
                                            <OtpInput
                                                numInputs={4}
                                                isInputNum={true}
                                                value={phoneNumber.otp}
                                                onChange={(otp) => pinHandler(otp)}
                                                className={styles.pinBoxes}
                                                autoFocus={true}
                                            />
                                        </div>
                                        <div className={styles.lowerRow}>
                                            <button className={styles.rightArrowBtn}
                                                onClick={() => extendCardHandler()}
                                            >
                                                <HiArrowRight color="white" size={20} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div style={{ height: '30svh' }}></div>
                            }
                        </div>
                    :
                    <div className={styles.extenderWrapper}>
                        <div className={styles.extenderWrapperHeader}>
                            <button>Hold On...</button>
                        </div>
                        <div className={styles.extenderWrapperBodyLoader}>
                            <div className={styles.ewbTitle}>
                                <div className={styles.ewbTitleVs}>
                                    Extending
                                </div>
                                <div className={styles.ewbTitleSmall}>
                                    {cardIdentifier.substring(0, 4)}-{cardIdentifier.substring(4, 8)}-{cardIdentifier.substring(8, 12)}-{cardIdentifier.substring(12, 16)}
                                </div>
                            </div>
                            <div className={styles.loaderImg}>
                                <img src="https://res.cloudinary.com/iamironman/image/upload/v1630782519/web-icons/Ajax-loader_w2minh.gif" />
                            </div>
                            <div>
                                <div className={styles.valueAdditionWrapper}>
                                    <ShimmerThumbnail height={20} width={160} />
                                </div>
                                <div className={styles.creditableAmountWrapper}>
                                    <ShimmerThumbnail height={20} width={220} className={styles.creditableAmountLoader} />
                                </div>
                            </div>
                            <div className={styles.payModeWrapper}>
                                <div className={styles.eachPayModeLoader}>
                                    <ShimmerThumbnail height={34} width={340} className={styles.eachModeLoader} />
                                </div>
                                <div className={styles.eachPayModeLoader}>
                                    <ShimmerThumbnail height={34} width={340} className={styles.creditableAmountLoader} />
                                </div>
                                <div className={styles.eachPayModeLoader}>
                                    <ShimmerThumbnail height={34} width={340} className={styles.creditableAmountLoader} />
                                </div>
                                <div className={styles.eachPayModeLoader}>
                                    <ShimmerThumbnail height={34} width={340} className={styles.creditableAmountLoader} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.extenderWrapperFooter}>
                            <div className={styles.pinWrapper}>
                                <div className={styles.pinTitleWrapper}>
                                    Renewing Card... Please wait..
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ExtendCard