import React from 'react'
import styles from '../Feedback/RatingBarGraph.module.css'

const RatingBarGraph = () => {
    return (
        <div className={styles.mainWrapper}>
            <div>

            </div>
            <div className={styles.graphWrapper}>
                <div className={styles.eachBarWrapper}>
                    <div className={styles.title}>
                        5
                    </div>
                    <div className={styles.barWrapper}>
                        <div className={styles.emptyBar}></div>
                        <div className={styles.filledBar}></div>
                    </div>
                    <div className={styles.legends}>
                        80%
                    </div>
                </div>
                <div className={styles.eachBarWrapper}>
                    <div className={styles.title}>
                        4
                    </div>
                    <div className={styles.barWrapper}>
                        <div className={styles.emptyBar}></div>
                        <div className={styles.filledBar}></div>
                    </div>
                    <div className={styles.legends}>
                        80%
                    </div>
                </div>
                <div className={styles.eachBarWrapper}>
                    <div className={styles.title}>
                        3
                    </div>
                    <div className={styles.barWrapper}>
                        <div className={styles.emptyBar}></div>
                        <div className={styles.filledBar}></div>
                    </div>
                    <div className={styles.legends}>
                        80%
                    </div>
                </div>
                <div className={styles.eachBarWrapper}>
                    <div className={styles.title}>
                        2
                    </div>
                    <div className={styles.barWrapper}>
                        <div className={styles.emptyBar}></div>
                        <div className={styles.filledBar}></div>
                    </div>
                    <div className={styles.legends}>
                        80%
                    </div>
                </div>
                <div className={styles.eachBarWrapper}>
                    <div className={styles.title}>
                        1
                    </div>
                    <div className={styles.barWrapper}>
                        <div className={styles.emptyBar}></div>
                        <div className={styles.filledBar} style={{ width : 30+"%" }} ></div>
                    </div>
                    <div className={styles.legends}>
                        80%
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RatingBarGraph