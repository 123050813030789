import React, { useEffect, useState } from 'react'
import { BsCheckSquare, BsCheckSquareFill, BsSquare } from 'react-icons/bs'
import ConfigMembershipCNCCategoryAndServices from './ConfigMembershipCNCCategoryAndServices'
import ConfigMembershipCNCEachStore from './ConfigMembershipCNCEachStore'
import styles from './ConfigMembershipCNCStoreAndServicesWrapper.module.css'

const ConfigMembershipCNCStoreAndServicesWrapper = () => {
    const [storesList, setStoresList] = useState([1, 2, 3, 4, 12, 13, 14, 23, 24, 34, 35, 4443, 334])
    const [selectedStores, setSelectedStores] = useState([])
    const [activeStore, setActiveStore] = useState(null)

    const [servicesList, setServicesList] = useState([1, 2, 3, 4, 5, 6, 7])
    const [selectedServices, setSelectedServices] = useState([])
    const [activeService, setActiveService] = useState(null)
    const selectServiceHandler = (k) => {
        setActiveService(activeService === k ? null : k)
    }


    const [addedServices, setAddedServices] = useState([])

    const toggleStoreHandler = (k) => {
        setSelectedStores(selectedStores.includes(k) ? selectedStores.filter((item) => item !== k) : [...selectedStores, k])
    }
    const toggleAllStoreSelector = () => {
        // setSelectedStores(selectedStores => [...selectedStores.splice(0, selectedStores.length), ...storesList.map(item => item.id)])
        setSelectedStores(storesList.length === selectedStores.length ? selectedStores => selectedStores.splice(0, selectedStores.length) : selectedStores => [...selectedStores.splice(0, selectedStores.length), ...storesList.map(item => item)])
    }
    const toggleActiveStoreHandler = (k) => {
        setActiveStore(activeStore === k ? null : k)
        if (selectedStores.includes(k) === false) {
            setSelectedStores([...selectedStores, k])
        }

        // setSelectedStores(selectedStores.includes(k) && [...selectedStores, k] )
    }


    const toggleServiceHandler = (k) => {
        setAddedServices(addedServices.includes(k) === true ? addedServices.filter((item) => item !== k) : [...addedServices, k])
    }

    useEffect(() => {
        if (activeStore === null) { setActiveService(null) }
    }, [activeStore])



    console.log("This is added services, ", addedServices)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.storeListColumn}>
                <div className={styles.slcHeader}>
                    <div onClick={() => toggleAllStoreSelector()} className={styles.checkBoxMain}>
                        {storesList.length === selectedStores.length ?
                            <BsCheckSquareFill /> :
                            <BsSquare />
                        }
                    </div>
                    <div>
                        <div className={styles.headerTitle}>
                            Stores List ({selectedStores.length}/{storesList.length})
                        </div>
                        <div className={styles.headerSubTitle}>
                            Please select store at which you want this card to be activated.
                        </div>
                    </div>
                </div>
                <div className={styles.slcBody}>
                    {storesList.map((item, index) => (
                        <ConfigMembershipCNCEachStore
                            key={index}
                            item={item}
                            isSelected={selectedStores.includes(item) === true ? true : false}
                            toggleStoreHandler={toggleStoreHandler}
                            toggleActiveStoreHandler={toggleActiveStoreHandler}
                        />
                    ))}
                </div>
            </div>

            {activeStore !== null &&
                <div className={styles.storeServiceCategoryColumn}>
                    <div className={styles.storeServiceCategoryHeader}>
                        <div className={styles.searchTabCategory}>
                            <input placeholder="Search for the services.."
                                autoFocus={true}
                            />
                        </div>
                        <div>
                            <button>F</button>
                            <button>M</button>
                        </div>
                    </div>
                    <div>
                        {servicesList.map((item, index) => (
                            <ConfigMembershipCNCCategoryAndServices
                                key={index}
                                item={item}
                                selectServiceHandler={selectServiceHandler}
                            />
                        ))}
                    </div>
                </div>
            }

            {activeService !== null &&
                <div className={styles.storeServiceAddonColumn}>
                    Add On List {activeService}
                    <div>
                        <div>
                            Haircut
                        </div>
                        <div>
                            <button onClick={() => toggleServiceHandler(1)} >Add </button>
                        </div>
                    </div>
                </div>
            }

            {addedServices.length>0 &&
                <div className={styles.storeServiceSelected}>
                    Selected Services
                </div>
            }

        </div>
    )
}

export default ConfigMembershipCNCStoreAndServicesWrapper