import React, { useContext, useEffect, useState } from 'react'
import { FaRupeeSign } from 'react-icons/fa'
import { MdArrowRight, MdCalendarToday, MdCalendarViewDay, MdCalendarViewWeek, MdMonetizationOn, MdMoreHoriz, MdSearch } from 'react-icons/md'
import TransactionsTable from '../../Components/Membership/TransactionsTable'
import NavAtHome from '../../Components/Navigators/NavAtHome'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from '../Membership/CardDetails.module.css'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import ExtendCard from '../../Components/Membership/ExtendCard'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TbRecharging } from 'react-icons/tb'
import { FiSettings } from 'react-icons/fi'

const CardDetails = () => {
    const [isLoading, setIsLoading] = useState(true);
    const authCtx = useContext(AuthContext);
    const params = useParams();
    const cardIdentifier = params.purchaseCardIdentifier;


    const [clientDetails, setClientDetails] = useState({
        clientName: ""
    })
    const [otherCards, setOtherCards] = useState([])
    const [dcb, setDcb] = useState(null)
    const [isExtending, setIsExtending] = useState(false)
    const toggleIsExtending = () => {
        setIsExtending(!isExtending)
    }

    const getClientMembershipDetails = async () => {
        setIsLoading(true)
        const getClientMembershipDetailsResponse = await fetch(BASE_URL_API + "getClientMembershipDetails",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    cardIdentifier: cardIdentifier
                })
            });

        if (!getClientMembershipDetailsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getClientMembershipDetailsRespo = await getClientMembershipDetailsResponse.json();
            if (getClientMembershipDetailsRespo.status === "success") {
                setOtherCards(getClientMembershipDetailsRespo.response.otherCards)
                setClientDetails(getClientMembershipDetailsRespo.response.clientDetails)
            } else {
                if (getClientMembershipDetailsRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else if (getClientMembershipDetailsRespo.message === "invalid token parts") {
                    authCtx.logout()
                } else {
                }
            }
            console.log(getClientMembershipDetailsRespo);
        }
        setIsLoading(false)
    }

    const getCardsUsageAndBalance = async () => {
        const getCardsUsageAndBalanceResponse = await fetch(BASE_URL_API + "getCardsUsageAndBalance",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    cardIdentifier: cardIdentifier
                })
            });

        if (!getCardsUsageAndBalanceResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCardsUsageAndBalanceRespo = await getCardsUsageAndBalanceResponse.json();
            if (getCardsUsageAndBalanceRespo.status === 'success') {
                setDcb(getCardsUsageAndBalanceRespo.response)
            } else {
                setDcb(null)
            }
            console.log(getCardsUsageAndBalanceRespo)
        }
    }

    useEffect(() => {
        getClientMembershipDetails().then(getCardsUsageAndBalance())
    }, [cardIdentifier])


    console.log(dcb)

    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            {/* <NavAtHome /> */}
            <ExtendCard
                isExtending={isExtending}
                toggleIsExtending={toggleIsExtending}
                cardIdentifier={cardIdentifier}
                getClientMembershipDetails={getClientMembershipDetails}
                getCardsUsageAndBalance={getCardsUsageAndBalance}

            />
            <div className={styles.mainContainer}>
                <div className={styles.upperWrapper}>
                    <div className={styles.mainHeader}>
                        <div className={styles.mhLeft}>
                            <div className={styles.clientNameTitle}>
                                Welcome
                            </div>
                            <div className={styles.clientName}>
                                {clientDetails?.clientName}
                            </div>
                            <div className={styles.mobGenWrapper}>
                                <div className={styles.mobile}>{clientDetails?.mobile}</div>
                                <div className={styles.gender}>{clientDetails?.gender}</div>
                            </div>
                        </div>
                        <div className={styles.mhRight}>
                            <div className={styles.eachNavOption}
                                onClick={() => setIsExtending(true)}
                            >
                                <div className={styles.enoIcon}>
                                    <TbRecharging size={22} />
                                </div>
                                <div className={styles.enoTxt}>
                                    Renew
                                </div>
                            </div>
                            <div className={styles.eachNavOption}>
                                <div className={styles.enoIcon}>
                                    <FiSettings size={22} />
                                </div>
                                <div className={styles.enoTxt}>
                                    Setting
                                </div>
                            </div>
                            <div className={styles.eachNavOption}>
                                <div className={styles.enoIcon}>
                                    <MdMoreHoriz size={22} />
                                </div>
                                <div className={styles.enoTxt}>
                                    More
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.lowerWrapper}>
                    <div className={styles.centerPage}>

                        <div className={styles.mainBody}>
                            {isLoading === true ?
                                <div className={styles.cardStrip}>
                                    <div className={styles.cardStripLeft}>
                                        <div className={styles.csUpper}>
                                            <ShimmerThumbnail height={13} rounded width={100} className={styles.csUpperLoader} />
                                        </div>
                                        <div className={styles.csValue}>
                                            <ShimmerThumbnail height={22} rounded width={140} className={styles.csUpperLoader} />
                                        </div>
                                        <div className={styles.csLower}>
                                            <ShimmerThumbnail height={11} rounded width={70} className={styles.csUpperLoader} />
                                        </div>
                                    </div>
                                    <div className={styles.cardStripRight}>
                                        <div className={styles.viewDetailsBtn}>
                                            Renew Card <MdArrowRight />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className={styles.cardStrip}>
                                    <div className={styles.cardStripLeft}>
                                        <div className={styles.csUpper}>
                                            Available Balance
                                        </div>
                                        <div className={styles.csValue}>
                                            &#8377;{dcb?.currentBalance}/-
                                        </div>
                                        <div className={styles.csLower}>
                                            1 Card
                                        </div>
                                    </div>
                                    <div className={styles.cardStripRight}>
                                        <div className={styles.viewDetailsBtn}
                                            onClick={() => setIsExtending(true)}
                                        >
                                            Renew Card <MdArrowRight />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className={styles.cardDetailsSummaryWrapper}>
                                <div>
                                    <div className={styles.cdsTitle}>
                                        <div className={styles.cdsTitleCaption}>
                                            Card Details
                                        </div>
                                        <div>
                                            <MdCalendarToday />
                                        </div>
                                    </div>

                                </div>





                                <div className={styles.balanceSummaryWrapper}>
                                    <div className={styles.eachBs}>
                                        <div>

                                        </div>
                                        <div>
                                            <div className={styles.bsCaption}>
                                                Total Earned
                                            </div>
                                            <div className={styles.bsValue}>
                                                <FaRupeeSign />{dcb?.allCredits}/-
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.eachBs}>
                                        <div>

                                        </div>
                                        <div>
                                            <div className={styles.bsCaption}>
                                                Total Added
                                            </div>
                                            <div className={styles.bsValue}>
                                                <FaRupeeSign />{dcb?.totalPaidAmount}/-
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.eachBs}>
                                        <div>

                                        </div>
                                        <div>
                                            <div className={styles.bsCaption}>
                                                Total Savings
                                            </div>
                                            <div className={styles.bsValue}>
                                                <FaRupeeSign />{parseFloat(dcb?.allCredits ?? 0) - parseFloat(dcb?.totalPaidAmount ?? 0)}/-
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.eachBs}>
                                        <div>

                                        </div>
                                        <div>
                                            <div className={styles.bsCaption}>
                                                Total Spend
                                            </div>
                                            <div className={styles.bsValue}>
                                                <FaRupeeSign />{dcb?.allDebits}/-
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className={styles.cdsTitle}>
                                        <div className={styles.cdsTitleCaption}>
                                            Other Cards
                                        </div>
                                        <div>
                                            {/* <MdCalendarToday /> */}
                                        </div>
                                    </div>
                                    <div className={styles.boughtCardWrapper}>
                                        {isLoading !== true ?
                                            <div className={styles.boughtCardContainer}>
                                                {otherCards.map((item, index) => (
                                                    <Link to={`/membership/` + item.issuedCardNumber} className={styles.eachCardWrapper}>
                                                        <div className={styles.cardName}>
                                                            GALLANT
                                                        </div>
                                                        <div>
                                                            <div className={styles.cardNumber}>
                                                                <div>
                                                                    {item.issuedCardNumber.substring(0, 4)}
                                                                </div>
                                                                <div>
                                                                    {item.issuedCardNumber.substring(4, 8)}
                                                                </div>
                                                                <div>
                                                                    {item.issuedCardNumber.substring(8, 12)}
                                                                </div>
                                                                <div>
                                                                    {item.issuedCardNumber.substring(12, 16)}
                                                                </div>
                                                            </div>
                                                            <div className={styles.expiryDate}>
                                                                25-Jan-2028
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))}

                                                <Link to="/memberships/add" className={styles.buyNewCardWrapper}>
                                                    Buy New Card
                                                </Link>
                                            </div>
                                            :
                                            <div className={styles.boughtCardContainer}>
                                                <ShimmerThumbnail height={139} width={282} className={styles.thumbnail} rounded />
                                                <ShimmerThumbnail height={139} width={282} className={styles.thumbnail} rounded />
                                            </div>
                                        }
                                    </div>

                                </div>

                            </div>



                            {/* 
                        <div className={styles.singleRow}>
                            hey
                        </div> */}

                        </div>
                    </div>
                    <div className={styles.rightCol}>
                        <TransactionsTable
                            cardIdentifier={cardIdentifier}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardDetails