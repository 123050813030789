import React, { useContext, useEffect, useState } from 'react'
import { RiBuildingLine } from 'react-icons/ri'
import styles from './AllStores.module.css'
import { Link, useNavigate } from 'react-router-dom'
import AuthContext from '../../store/auth-context'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'

const AllStores = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const nav = useNavigate()
    const [allStores, setAllStores] = useState([1])

    const getAllStores = async () => {
        const getAllStoresResponse = await fetch(BASE_URL_API + "getAllStores",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: JSON.parse(authCtx?.loginData)?.partnersData?.vendorIdentifier
                })
            });

        if (!getAllStoresResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllStoresRespo = await getAllStoresResponse.json()
            if (getAllStoresRespo.status === "success") {
                setAllStores(getAllStoresRespo?.response)
            } else {
                if (getAllStoresRespo.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
            console.log(getAllStoresRespo)
        }
    }

    useEffect(() => {
        getAllStores()
    }, [])


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.mainHeader}>
                        <div className={styles.mainTitle}>
                            All Stores / Locations
                        </div>
                        <div className={styles.createNewStore}>
                            <button>+ Create New Store</button>
                        </div>
                    </div>
                    <div className={styles.chooseLocationWrapper}>
                        <div className={styles.chooseLocationTitle}>
                            <div>
                                <h2>Choose A Location</h2>
                            </div>
                            <div className={styles.clSubTitle}>
                                Select a location to add / edit the details.
                            </div>
                        </div>
                        <div className={styles.storesWrapper}>
                            <div className={styles.storesContainer}>
                                {allStores?.map((item, index) => (
                                    <div onClick={() => {
                                        asCtx?.changeStore(item)
                                        nav(`/manage/details`)
                                    }} to={`/store/${item?.storeIdentifier}/details`} className={styles.eachStoreWrapper} key={index}>
                                        <div className={styles.storeIcon}>
                                            <RiBuildingLine size={50} color={`#84818A`} />
                                        </div>
                                        <div className={styles.storeTxt}>
                                            <div>
                                                {item?.storeName}
                                            </div>
                                            <div className={styles.storeAddress}>
                                                {item?.storeAddress}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllStores