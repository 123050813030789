import React, { useState } from 'react'
import ConfigMembershipCNCStoreAndServicesWrapper from './ConfigMembershipCNCStoreAndServicesWrapper'
import styles from './ConfigMembershipCreateNewCard.module.css'

const ConfigMembershipCreateNewCard = () => {
    const [activeTab, setActiveTab] = useState('BD')


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainLoaderWrapper}>
                <div className={styles.mainLoaderContent}>
                    <div className={styles.contentBox}>
                        <div className={styles.awesomeTitle}>
                            Awesome,
                        </div>
                        <div className={styles.awesomeSubTitle}>
                            Let's create some smart cards..
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.mainContentWrapper}>
                <div className={styles.mainContentHeader}>
                    <div className={styles.mainContentHeaderContainer}>
                        <button onClick={() => setActiveTab('BD')} >Basic Details</button>
                        <button onClick={() => setActiveTab('StoresAndServices')} >Stores & Services Included</button>
                        <button onClick={() => setActiveTab('PriceAndValidity')} >Price & Validity</button>
                        <button onClick={() => setActiveTab('TnCs')} >Terms Of Use</button>
                        <button onClick={() => setActiveTab('Confirm')} >Confirm</button>
                    </div>
                </div>

                {activeTab === 'BD' &&
                    <div className={styles.mainContent} >
                        <select>
                            <option>Select Type of card</option>
                            <option>Truly Unlimited</option>
                            <option>Value Addition</option>
                        </select>
                    </div>
                }
                {activeTab==="StoresAndServices" && <ConfigMembershipCNCStoreAndServicesWrapper />}


            </div>
        </div>
    )
}

export default ConfigMembershipCreateNewCard