import React, { useContext, useEffect, useState } from 'react'
import { BsStack } from 'react-icons/bs';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import styles from '../StaffManagement/SelectStaff.module.css'

const SelectStaff = props => {
    const { isOpen, closeStaffPopup, staffSelectingHandler, closeModal, selectedStaff } = props;
    const authCtx = useContext(AuthContext)
    const [workers, setWorkers] = useState([])

    const getWorkersData = async () => {
        const getWorkersDataResponse = await fetch(BASE_URL_API + "getWorkersData",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: authCtx.storeIdentifier
                })
            });

        if (!getWorkersDataResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getWorkersDataRespo = await getWorkersDataResponse.json();
            if (getWorkersDataRespo.status === 'success') {
                setWorkers(getWorkersDataRespo.response);
            } else {
                if (getWorkersDataRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getWorkersDataRespo)
        }
    }


    useEffect(() => {
        window.onclick = function (event) {
            if (event.target.id === "staffSelectingWrapper") {
                if (typeof closeStaffPopup === 'function') {
                    closeStaffPopup();
                } else {
                    console.log("Something wrong ")
                }
            }

            if (event.target.id === "filtersPanelWrapperMUG") {
                document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft .1s forwards";
            }

            if (event.target.id == "dateRangeSelector") {
                closeModal();
            }
        };

        // Clean up the event listener when the component is unmounted
        return () => {
            window.onclick = null;
        }
    }, [isOpen, closeStaffPopup])

    useEffect(() => {
        getWorkersData()
    }, [])



    if (!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}
                id="staffSelectingWrapper"
                style={{ animation: `0.12s cubic-bezier(0, 0.63, 0.26, 0.96) 0s 1 normal forwards running slideBottomToTop` }}
            >
                <div className={styles.mainContent}>
                    <div className={styles.popUpTitle}>
                        Select Staff
                    </div>
                    <div>
                        <div className={styles.allWorkersWrapper}>
                            <div className={selectedStaff === 'Summary' ? styles.eachStaffWrapperSelected : styles.eachStaffWrapper}
                                onClick={() => staffSelectingHandler('Summary')}
                            >
                                <div className={styles.workersImg}>
                                    <BsStack size={34} />
                                </div>
                                <div>
                                    Summary
                                </div>
                            </div>
                            {workers.map((item, index) => (
                                <div key={index} className={selectedStaff.id === item.id ? styles.eachStaffWrapperSelected : styles.eachStaffWrapper}
                                    onClick={() => staffSelectingHandler(item)}
                                >
                                    <div className={styles.workersImg}>
                                        <img src={item.profilePic} />
                                    </div>
                                    <div>
                                        {item.name}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                    <div>
                        <button onClick={() => closeStaffPopup()}>Close</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SelectStaff