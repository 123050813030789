import React, { useContext, useEffect, useState } from 'react'
import { BiDotsHorizontal } from 'react-icons/bi';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context'
import styles from '../Sessions/PriceList.module.css'
import Cart from './Cart';
import PriceListCategory from './PriceListCategory';
import { Link } from 'react-router-dom'
import ActiveStoreContext from '../../store/ActiveStoreContext';

const PriceList = props => {
    const sessionIdentifier = props.sessionIdentifier;
    const workersData = props.workersData;
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const authCtx = useContext(AuthContext);

    const [showDiscount, setShowDiscount] = useState(false)

    const [priceList, setPriceList] = useState([])
    const [genderFilter, setGenderFilter] = useState('2');
    const [cartItems, setCartItems] = useState([])

    const [filteredArray, setFilteredArray] = useState([]);
    const [searchTerm, setSearchTerm] = useState('')
    const [fetchingCartItems, setFetchingCartItems] = useState(false)


    const [isFetchingSection, setIsFetchingSection] = useState(false)
    const [sections, setSections] = useState([])
    const [activeSection, setActiveSection] = useState(null)

    const getAllServicesSection = async () => {
        setIsFetchingSection(true)
        console.log(authCtx.token)
        const getAllServicesSectionResponse = await fetch(BASE_URL_API + "getAllServicesSection",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getAllServicesSectionResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getAllServicesSectionRespo = await getAllServicesSectionResponse.json();
            if (getAllServicesSectionRespo.status === "success") {
                setSections(getAllServicesSectionRespo.response);
                setGenderFilter(getAllServicesSectionRespo?.response[0]?.sectionIdentifier)
            } else {
                if (getAllServicesSectionRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    console.log(getAllServicesSectionRespo)
                }
            }
        }
        setIsFetchingSection(false)
    }

    useEffect(() => {
        getAllServicesSection();
    }, [])



    const generateFilteredArray = () => {
        let filteredData = priceList.filter(item => item.genderIdentifier === genderFilter);

        if (searchTerm !== '') {
            filteredData = filteredData.filter(item => {
                const categoryNameMatch = item.categoryName.toLowerCase().includes(searchTerm.toLowerCase());
                const serviceNameMatch = (item.services && item.services.some(service => service.serviceName.toLowerCase().includes(searchTerm.toLowerCase()))) || false;
                const labelMatch = (item.services && item.services.some(service => (service.addOns && service.addOns.some(addOn => addOn.label.toLowerCase().includes(searchTerm.toLowerCase())))) || false);

                return categoryNameMatch || serviceNameMatch || labelMatch;
            });
        }
        setFilteredArray(filteredData)
    }

    const getPriceList = async () => {
        const getPriceListResponse = await fetch(BASE_URL_API + "getPriceList",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getPriceListResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getPriceListRespo = await getPriceListResponse.json();
            if (getPriceListRespo.status === 'success') {
                setPriceList(getPriceListRespo.response)
            } else {
                if (getPriceListRespo.message === 'tokenExpired') {
                    authCtx.logout();
                } else {

                }
            }

            // console.log(getPriceListRespo);
        }
    }

    const getCartItems = async () => {
        setFetchingCartItems(true)
        const getCartItemsResponse = await fetch(BASE_URL_API + "getCartItems",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    bookingIdentifier: sessionIdentifier
                })
            });

        if (!getCartItemsResponse.ok) {
            console.log("Something went wrong, Server Error");
        } else {
            const getCartItemsRespo = await getCartItemsResponse.json();
            if (getCartItemsRespo.status === 'success') {
                setCartItems(getCartItemsRespo.response)
            } else {
                if (getCartItemsRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else if (getCartItemsRespo.message === 'no item added') {
                    setCartItems([])
                }
            }
            console.log(getCartItemsRespo);
        }
        setFetchingCartItems(false)
    }


    useEffect(() => {
        if (sessionIdentifier !== undefined) {
            getPriceList()
            getCartItems()
            document.getElementById("priceListSearchBar").select()
        }
    }, [sessionIdentifier])

    useEffect(() => {
        if (sessionIdentifier !== undefined) {
            generateFilteredArray()
        }
    }, [priceList, genderFilter, searchTerm])


    useEffect(() => {
        document.addEventListener('keydown', function (e) {
            if (e.key === "/") {
                document.getElementById("priceListSearchBar").focus()
                document.getElementById("priceListSearchBar").value = ''
            }
        })
    }, [sessionIdentifier])


    // console.log(filteredArray)
    // console.log(sessionIdentifier)

    if (sessionIdentifier === undefined) {
        return (
            <div>
                Please select a booking to start!
            </div>
        )
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.priceListWrapper}>
                <div className={styles.priceListHeader}>
                    <div className={styles.plhUpper}>
                        <div className={styles.searchBar}>
                            <input placeholder="Search for any service..."
                                onChange={(e) => setSearchTerm(e.target.value)}
                                value={searchTerm}
                                id="priceListSearchBar"
                                autoFocus={true}
                            />
                        </div>
                        <div className={styles.filterWrapper}>
                            <div className={styles.plhLower}>
                                {sections?.map((item, index) => (
                                    <button onClick={() => setGenderFilter(item?.sectionIdentifier)} className={genderFilter === item?.sectionIdentifier ? styles.eachFilterBtnActive : styles.eachFilterBtn}>
                                        {item?.section}
                                    </button>
                                ))}
                            </div>
                            <button onClick={() => setShowDiscount(!showDiscount)} className={styles.moreBtn} >
                                <BiDotsHorizontal color="white" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className={styles.priceListBody}>
                    {filteredArray.length > 0 ?
                        <div className={styles.priceListContainer}>
                            {filteredArray.map((item, index) => (
                                <PriceListCategory
                                    item={item}
                                    key={index}
                                    showDiscount={showDiscount}
                                    workersData={workersData}
                                    cartItems={cartItems}
                                    getCartItems={getCartItems}
                                    fetchingCartItems={fetchingCartItems}
                                    sessionIdentifier={sessionIdentifier}
                                />
                            ))}

                        </div>
                        :
                        <div className={styles.noPriceListWrapper}>
                            <div className={styles.noPriceListTxt}>
                                No price list found!
                            </div>
                            <div className={styles.goToServicesBtn}>
                                <Link to="/services" className={styles.addServices} >Add Services</Link>
                            </div>

                        </div>
                    }
                </div>
            </div>
            <div className={styles.cartWrapper}>
                <Cart
                    cartItems={cartItems}
                    sessionIdentifier={sessionIdentifier}
                    getCartItems={getCartItems}
                    fetchingCartItems={fetchingCartItems}
                />
            </div>
        </div>
    )
}

export default PriceList