import React from 'react'
import FreeHaircutLadies from '../../Components/Communications/ViewPromos/FreeHaircutLadies'
import styles from './ViewPromo.module.css'

const ViewPromo = () => {
    return (
        <div>
            <div>
                <FreeHaircutLadies />
            </div>
            <a href="tel:+91-8766321368">
                <div className={styles.callNowBtn}>
                    Call Now
                </div>
            </a>
        </div>
    )
}

export default ViewPromo