import React, { useEffect, useState } from 'react'
import NavAtHome from '../../Components/Navigators/NavAtHome'
import EachAddedAddOn from '../../Components/Services/EachAddedAddOn'
import EachServiceAddOns from '../../Components/Services/EachServiceAddOns'
import EachServiceList from '../../Components/Services/EachServiceList'
import EachServicesCategory from '../../Components/Services/EachServicesCategory'
import { BASE_URL_API } from '../../References/URLs'
import styles from '../../Styles/Content/Services/ManageServices.module.css'

const ManageServices = () => {
    let storeIdentifier = '';

    return (
        <div className={styles.mainContainer}>
            <NavAtHome />
            <div className={styles.leftBody}>
                <div>Manage Services</div>
            </div>
            <div className={styles.centerBody}>
                <EachServiceList storeIdentifier={storeIdentifier} />
            </div>
        </div>
    )
}

export default ManageServices