import React, { useContext, useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa';
import NavAtHome from '../../Components/Navigators/NavAtHome';
import DataTable from '../../Components/UI/SearchableTables';
import AddNewClient from '../../Components/Users/AddNewClient';
import { BASE_URL_API } from '../../References/URLs';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AuthContext from '../../store/auth-context';
import styles from '../Users/Users.module.css'

const Users = () => {
    const authCtx = useContext(AuthContext);
    const [isUserFormOpen, setIsUserFormOpen] = useState(false);
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const closeModal = () => {
        setIsUserFormOpen(false)
    }
    const [allUsers, setAllUsers] = useState([]);

    const getClientsStats = async () => {
        const getClientsStatsResponse = await fetch(BASE_URL_API+"getClientsStats",
        {
            method : 'POST',
            body : JSON.stringify({
                token : authCtx.token,
                storeIdentifier : activeStore?.storeIdentifier
            })
        });

        if(!getClientsStatsResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else{
            const getClientsStatsRespo = await getClientsStatsResponse.json();
            if(getClientsStatsRespo.status==="success") {
                setAllUsers(getClientsStatsRespo.response)
            } else {
                if(getClientsStatsRespo.message==="tokenExpired") {
                    authCtx.logout();
                } else {

                }
            }
            console.log(getClientsStatsRespo);
        }
    }

    useEffect(() => {
        getClientsStats()
    }, [])
    

    return (
        <div className={styles.mainWrapper}>
            <NavAtHome />
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    <div className={styles.addNewClientBtn}>
                        <button onClick={() => setIsUserFormOpen(true)}>Add New&nbsp;<FaPlus /></button>
                    </div>
                    <AddNewClient
                        isOpen={isUserFormOpen}
                        closeModal={closeModal}
                        phone=""
                    />
                </div>
                <div className={styles.bodyContainer}>

                    {/* <div className={styles.clientTable}>
                    Users
                        {allUsers.map((item, index) => (
                            <div>
                                Name : {item.client_name}
                            </div>
                        ))}
                    </div> */}


                    <DataTable data={allUsers} />
                </div>
            </div>
        </div>
    )
}

export default Users