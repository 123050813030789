import React, { useContext, useState } from 'react'
import AuthContext from '../../store/auth-context'
import styles from '../Navigators/NavAtHome.module.css'
import { BiLeftArrow, BiLogOut, BiMenuAltLeft } from 'react-icons/bi'
import { FaBackward, FaCalendarTimes, FaHome, FaPlus, FaTimesCircle, FaUsers, FaUserTimes, FaWallet } from 'react-icons/fa'
import { AiOutlineUnorderedList } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { BsBack } from 'react-icons/bs'
import { MdCardMembership, MdClose } from 'react-icons/md'

const NavAtHome = () => {
    const authCtx = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false)


    const openFilterPanel = () => {
        // document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft 0.5s forwards";
        document.getElementById("filtersPanelWrapperMUG").style.animation = "slideLeftToRight 0.2s forwards";
    }

    const closeFilterPanel = () => {
        document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft .1s forwards";
    }

    window.onclick = function (event) {
        if (event.target.id == "filtersPanelWrapperMUG") {
            document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft .1s forwards";
        }
    }

    document.addEventListener('keydown', function (e) {
        if (e.keyCode == 27) {
            document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft .1s forwards";
        }
    });

    const navOptions = [
        {
            "label": "Home",
            "route": "/",
            "icon": <FaHome />
        },
        {
            "label": "Sessions / Bookings",
            "route": "/sessions",
            "icon": <FaPlus />
        },
        {
            "label": "Clients",
            "route": "/users",
            "icon": <FaUsers />
        },
        {
            "label": "Memberships",
            "route": "/memberships",
            "icon": <MdCardMembership />
        },
        {
            "label": "Completed Bookings",
            "route": "/bookings",
            "icon": <FaCalendarTimes />
        },
        {
            "label": "Manage Price List",
            "route": "/services",
            "icon": <AiOutlineUnorderedList />
        },
        {
            "label": "Store Ledger",
            "route": "/ledger",
            "icon": <AiOutlineUnorderedList />
        },
        {
            "label": "Staff Management",
            "route": "/staff",
            "icon": <AiOutlineUnorderedList />
        },
        {
            "label": "Summary ",
            "route": "/analytics",
            "icon": <AiOutlineUnorderedList />
        },

    ]

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <button onClick={() => openFilterPanel()} className={styles.openMenuBtn}>
                    <BiMenuAltLeft size={28} />
                </button>

                <div className={styles.filtersPanelWrapper}
                    id="filtersPanelWrapperMUG"
                >
                    <div className={styles.mainLeft}>
                        <div className={styles.menuWrapper}>
                            <div className={styles.hamburger}>
                                <button onClick={() => closeFilterPanel()} className={styles.closeBtn}>
                                    <MdClose size={20} />
                                </button>
                            </div>
                            <div className={styles.menuBodyWrapper}>
                                {navOptions.map((item, index) => (
                                    <Link to={item.route} key={index}>
                                        <div className={styles.eachOption}>
                                            <div className={styles.menuIcons}>
                                                {item.icon}
                                            </div>
                                            <div className={styles.menuTxt}>
                                                {item.label}
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                            <div className={styles.footerWrapper}>
                                <button
                                    onClick={() => authCtx.logout()}
                                >
                                    <BiLogOut size={18} />&nbsp;Logout
                                </button>
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    )
}

export default NavAtHome