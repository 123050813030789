import React from 'react'
import { AiOutlineToTop, AiOutlineVerticalAlignBottom } from 'react-icons/ai'
import { BiRupee } from 'react-icons/bi'
import { FiMoreHorizontal } from 'react-icons/fi'
import { monthNumToTxtSmall, toDayMonthYear } from '../../Helper/calculations'
import styles from '../Membership/EachTransactions.module.css'

const EachTransactions = props => {
    const item = props.item
    return (
        <div className={styles.eachTransactions}>
            <div className={styles.transIconWrapper}>
                <div className={styles.transIconBox}>
                    {item?.transactionType === 'debit' ?
                        <AiOutlineToTop color="black" size={20} />
                        :
                        <AiOutlineVerticalAlignBottom color="black" size={20} />
                    }
                </div>
            </div>

            <div className={styles.captionWrapper}>
                <div className={styles.transactionCaption}>
                    {toDayMonthYear(item.transactionDate)[0]}-
                    {monthNumToTxtSmall(toDayMonthYear(item.transactionDate)[1])}'
                    {toDayMonthYear(item.transactionDate)[2]}
                </div>
                <div className={styles.transactionType}>
                    {item.transactionType}
                </div>
            </div>
            <div className={styles.timeWrapper}>
                {/* 05:45 P.M. */}
                <BiRupee />{item.transactionAmount}/-
            </div>
            <div className={styles.amountWrapper}>
                <BiRupee />{item.currentBalance}/-
            </div>
            <div className={styles.actionsWrapper}>
                <div className={styles.eachActionWrapper}>
                    <FiMoreHorizontal color="#E4EDE8" />
                </div>
            </div>
        </div>
    )
}

export default EachTransactions