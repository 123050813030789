import React, { useContext, useState } from 'react'
import { FaMinus, FaPlus, FaUser } from 'react-icons/fa';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import styles from '../Sessions/RemoveCartItem.module.css'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import EachItemQuantUpdate from './EachItemQuantUpdate';

const RemoveCartItem = props => {
    const isOpen = props.isOpen;
    const abortItemRemoval = props.abortItemRemoval;
    const removableItem = props.removableItem;
    const getCartItems = props.getCartItems
    const fetchingCartItems = props.fetchingCartItems

    if (removableItem.length > 0) {
        // console.log(removableItem)
    }



    if (!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    Remove Cart Item
                </div>
                <div className={styles.mainBody}>
                    {removableItem.map((item, index) => (
                        <EachItemQuantUpdate
                            key={index}
                            item={item}
                            getCartItems={getCartItems}
                            fetchingCartItems={fetchingCartItems}
                        />
                    ))}
                </div>

                <div className={styles.mainFooter}>
                    <button onClick={() => abortItemRemoval()}>
                        Close
                    </button>
                </div>

            </div>
        </div>
    )
}

export default RemoveCartItem