import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from './DeleteBookingConfirmation.module.css'

const DeleteBookingConfirmation = (props) => {
    const authCtx = useContext(AuthContext)
    const { isOpen, toggleOpen, bookingIdentifier } = props
    const [isLoading, setIsLoading] = useState(false)

    const deleteBookingSession = async () => {
        setIsLoading(true)
        const deleteBookingSessionResponse = await fetch(BASE_URL_API+"deleteBookingSession",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                bookingIdentifier : bookingIdentifier
            })
        });

        if(!deleteBookingSessionResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const deleteBookingSessionRespo = await deleteBookingSessionResponse.json()
            if(deleteBookingSessionRespo.status==="success") {
                toast("Booking has been successfully deleted!")
                toggleOpen()
            } else {
                if(deleteBookingSessionRespo.message==="tokenExpired") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(deleteBookingSessionRespo)

        }
        setIsLoading(false)
    }


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                
                <div className={styles.mainContainer}>
                    {!isLoading ?
                    <div className={styles.mainContent}>
                        <div className={styles.title}>Are you sure, you want to delete this booking?</div>
                        <div className={styles.actions}>
                            <div className={styles.cancelBtn}>
                                <button onClick={() => toggleOpen()}>Cancel</button>
                            </div>
                            <div className={styles.deleteBtn}>
                            <button onClick={() => deleteBookingSession()}>Delete</button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={styles.mainContent}>
                        <div className={styles.title}>Please wait while the booking is being deleted!</div>
                        <div className={styles.actions}>
                            <div className={styles.cancelBtn}>
                                {/* <button onClick={() => toggleOpen()}>Cancel</button> */}
                            </div>
                            <div className={styles.deleteBtn}>
                                <button >Deleting...</button>
                            </div>
                        </div>

                    </div>
                    }

                </div>
            </div>
        )
    }
}




export default DeleteBookingConfirmation