import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import { ToastContainer, toast } from 'react-toastify';
import { countryCodesHolder } from '../../Helper/countryCodes';
import { BASE_URL_API } from '../../References/URLs';
import styles from './CofAlpha.module.css'
import { useParams } from 'react-router-dom'

const CofAlpha = () => {
    const { pageKey } = useParams()
    const [pageStatus, setPageStatus] = useState(null) /// validate / expired
    const [otp, setOtp] = useState('');
    const [countryCode, setCountryCode] = useState('+91')
    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [doa, setDoa] = useState('')
    const [dob, setDob] = useState('')
    const [isAddingClient, setIsAddingClient] = useState(false)
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [pincode, setPincode] = useState('')

    const addNewClient = async () => {
        setIsAddingClient(true)
        const addNewClientResponse = await fetch(BASE_URL_API + "addNewClientOut",
            {
                method: "POST",
                body: JSON.stringify({
                    pageKey: pageKey,
                    countryCode: countryCode,
                    mobile: otp,
                    firstName: firstName,
                    middleName: middleName,
                    lastName: lastName,
                    address: address,
                    email: email,
                    doa: doa,
                    dob: dob,
                    city : city,
                    state : state,
                    pincode : pincode
                })
            });

        if (!addNewClientResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addNewClientRespo = await addNewClientResponse.json()
            if (addNewClientRespo.status === "success") {
                toast("Successfully added a client!")
                setOtp('')
                setFirstName('')
                setMiddleName('')
                setLastName('')
                setEmail('')
                setAddress('')
                setDoa('')
                setDob('')
            } else {
                toast(addNewClientRespo?.message)
            }
        }
        setIsAddingClient(false)

    }

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#fffff");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#ffffff";
            document.head.appendChild(meta);
        }
    }, []);

    const validateUrPage = async () => {
        const validateUrPageResponse = await fetch(BASE_URL_API + "validateUrPage",
            {
                method: "POST",
                body: JSON.stringify({
                    pageKey: pageKey
                })
            });

        if (!validateUrPageResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const validateUrPageRespo = await validateUrPageResponse.json()
            if (validateUrPageRespo?.status === "success") {
                setPageStatus('valid')
            } else {
                setPageStatus('invalid')
            }
        }
    }

    useEffect(() => {
        validateUrPage()
    }, [pageKey])

    if (pageStatus === null) {
        return (
            <div className={styles.mainWrapper}>
                <div>
                    Loading...
                    Please wait!
                </div>
            </div>
        )
    }

    if (pageStatus === 'invalid') {
        return (
            <div className={styles.mainWrapper}>
                <div>
                    Invalid Page!
                </div>
            </div>
        )
    }


    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.customerRegisterationForm}>
                <div className={styles.crfContainer}>
                    <div className={styles.crfTitle}>
                        Customer Registration
                    </div>
                    <div className={styles.inputWrappers}>
                        <div className={styles.eachInputWrapper}>
                            <div className={styles.countryCode}>
                                <select id="countryCode" onChange={(e) => setCountryCode(e.target.value)} defaultValue="+91">
                                    {countryCodesHolder?.map((item, index) => (
                                        <option key={index} value={`${item?.code}`}>{`${item?.country} - (${item?.code})`}</option>
                                    ))}
                                </select>
                            </div>
                            <div className={styles.phoneNumber}>
                                <OtpInput
                                    id="phoneNumber"
                                    numInputs={10}
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                    value={otp}
                                    onChange={setOtp}
                                />
                            </div>
                        </div>

                        <div className={styles.nameInputWrapper}>
                            <div className={styles.nameInputEach}>
                                <label htmlFor="firstName">First Name</label>
                                <input id="firstName" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            </div>
                            <div className={styles.nameInputEach}>
                                <label htmlFor="lastName">Last Name</label>
                                <input id="lastName" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            </div>
                        </div>

                        <div className={styles.emailInputWrapper}>
                            <label htmlFor="email">Email Address</label>
                            <input id="email" type="email" placeholder="E-mail Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>

                        <div className={styles.addressInputWrapper}>
                            <label htmlFor="address">Address</label>
                            <textarea id="address" placeholder="Please enter address..." value={address} onChange={(e) => setAddress(e.target.value)} ></textarea>
                        </div>

                        <div className={styles.dateInputWrapper}>
                            <div className={styles.dobWrapper}>
                                <label htmlFor="dob">Date of Birth (DOB)</label>
                                <input id="dob" type="date" value={dob} onChange={(e) => setDob(e.target.value)} />
                            </div>
                            <div className={styles.dobWrapper}>
                                <label htmlFor="doa">Anniversary (DOA)</label>
                                <input id="doa" type="date" value={doa} onChange={(e) => setDoa(e.target.value)} />
                            </div>
                        </div>

                        <div className={styles.cityPincodeStateWrapper}>
                            <div className={styles.cityWrapper}>
                                <label htmlFor="city">City</label>
                                <div>
                                    <input id="city" value={city} onChange={(e) => setCity(e.target.value)} placeholder="City.." />
                                </div>
                            </div>
                            <div className={styles.pincodeWrapper}>
                                <label htmlFor="pincode">Pincode</label>
                                <div>
                                    <input id="pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} placeholder="Pincode.." />
                                </div>
                            </div>
                            <div className={styles.stateWrapper}>
                                <label htmlFor="state">State</label>
                                <div>
                                    <input id="state" value={state} onChange={(e) => setState(e.target.value)} placeholder="State.." />
                                </div>
                            </div>
                        </div>

                        <div className={styles.registerWrapper}>
                            {isAddingClient === true ?
                                <button>Please wait, registering...</button>
                                :
                                <button onClick={() => addNewClient()}>Register</button>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CofAlpha
