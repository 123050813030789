import React from 'react'
import styles from './ConfigMembershipCardsHeader.module.css'
import { useNavigate } from 'react-router';

const ConfigMembershipCardsHeader = props => {
    const { selectedCard } = props
    const navigate = useNavigate()

    const selectCardHandler = (val) => {
        if (val === "create-a-new-card") {
            navigate("/config/membership-card/create-a-new-card")
        } else if (val === "select-a-card") {
            navigate("/config/membership-card/select-a-card")
        } else {
            navigate("/config/membership-card/" + val)
        }
    }


    return (
        <div className={styles.mainHeader}>
            <div className={styles.selectACard}>
                <select onChange={(e) => selectCardHandler(e.target.value)} value={selectedCard} >
                    <option value="select-a-card">Select A Card</option>
                    <option>A</option>
                    <option value="create-a-new-card">Create A New Card</option>
                </select>
            </div>
        </div>
    )
}

export default ConfigMembershipCardsHeader