import React, { useContext } from 'react'
import styles from './StoreDetails.module.css'
import StoresHeader from '../../Components/Stores/StoresHeader'
import AuthContext from '../../store/auth-context'
import ActiveStoreContext from '../../store/ActiveStoreContext'

const StoreDetails = () => {
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = JSON.parse(asCtx?.activeStore)


    if (activeStore === null) {
        return (
            <div className={styles.mainWrapper}>
                <StoresHeader />
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            Please select a store!
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className={styles.mainWrapper}>
            <StoresHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.mainHeader}>
                        <div className={styles.title}>
                            Store Details
                        </div>
                        <div className={styles.subTitle}>
                            <p>All details about your store / outlet</p>
                        </div>
                    </div>
                    <div className={styles.mainBody}>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default StoreDetails