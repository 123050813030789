import React, { useContext, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from './AllFamilyMembers.module.css'
import EachFamilyMember from './EachFamilyMember'

const AllFamilyMembers = (props) => {
    const { clientStoreRelationIdentifier } = props
    const [isAddingNew, setIsAddingNew] = useState(false)
    const [isAddingNow, setIsAddingNow] = useState(false)
    const [allMembers, setAllMembers] = useState([
        {
            fullName: 'Loading...',
            dateOfBirth: '1999-07-25 00:00:01',
            relationToFamilyHead: 'Loading...'
        }
    ])

    const relations = [
        // Self
        "self",

        // Immediate Family
        "father",
        "mother",
        "brother",
        "sister",
        "son",
        "daughter",

        // Extended Family
        "grandfather",
        "grandmother",
        "uncle",
        "aunt",
        "nephew",
        "niece",
        "cousin",

        // In-Laws
        "father-in-law",
        "mother-in-law",
        "brother-in-law",
        "sister-in-law",
        "son-in-law",
        "daughter-in-law",

        // Step Family
        "stepfather",
        "stepmother",
        "stepbrother",
        "stepsister",
        "stepson",
        "stepdaughter",

        // Spousal Relationships
        "husband",
        "wife",
        "partner",

        // Grandchildren
        "grandson",
        "granddaughter",

        // Great Grandparents/Grandchildren
        "great-grandfather",
        "great-grandmother",
        "great-grandson",
        "great-granddaughter",

        // Ancestors (Beyond immediate and extended family)
        "ancestor",
        "descendant",

        // Non-blood relations
        "guardian",
        "ward",
        "godfather",
        "godmother",
        "godson",
        "goddaughter",

        // Professional/Personal
        "friend",
        "colleague",
        "mentor",
        "mentee",
        "boss",
        "employee",

        // Miscellaneous
        "fiancé",
        "fiancée",
        "roommate",
        "neighbor"
    ];

    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [ageYear, setAgeYear] = useState('')
    const [ageMonth, setAgeMonth] = useState('')
    const [years, setYears] = useState([]);

    const [salutation, setSalutation] = useState('Mr.')
    const [fullName, setFullName] = useState('')
    const [gender, setGender] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [relationToFamilyHead, setRelationToFamilyHead] = useState('')

    useEffect(() => {
        const yearsArray = [];

        for (let year = 120; year >= 0; year--) {
            yearsArray.push(year);
        }

        setYears(yearsArray);
    }, []);

    const getPastDate = (year, month) => {
        // Get the current date
        const currentDate = new Date();

        // Create a new Date object for the given year and month, setting the day to 1
        let date = new Date(currentDate.getFullYear() - year, month - 1, 1);

        // If the generated date is in the future, adjust the year backward by 1 year
        if (date > currentDate) {
            date.setFullYear(date.getFullYear() - 1);
        }

        // Format the date as "yyyy-MM-dd"
        const formattedDate = date.toISOString().split('T')[0];

        setDateOfBirth(formattedDate);
    };

    useEffect(() => {
        getPastDate(ageYear, ageMonth)
    }, [ageYear, ageMonth])

    const getClientRelatives = async () => {
        const getClientRelativesResponse = await fetch(BASE_URL_API + "getClientRelatives",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    clientStoreRelationIdentifier: clientStoreRelationIdentifier
                })
            });

        if (!getClientRelativesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getClientRelativesRespo = await getClientRelativesResponse.json()
            if (getClientRelativesRespo.status === "success") {
                setAllMembers(getClientRelativesRespo?.response)
            } else {
                if (getClientRelativesRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setAllMembers([])
                }
            }
            console.log(getClientRelativesRespo)
        }
    }

    const addClientRelatives = async () => {
        setIsAddingNow(true)
        const addClientRelativesResponse = await fetch(BASE_URL_API + "addClientRelatives",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    clientStoreRelationIdentifier: clientStoreRelationIdentifier,
                    salutation: salutation,
                    fullName: fullName,
                    dateOfBirth: dateOfBirth,
                    gender: gender,
                    relationToFamilyHead: relationToFamilyHead
                })
            });

        if (!addClientRelativesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addClientRelativesRespo = await addClientRelativesResponse.json()
            if (addClientRelativesRespo.status === "success") {
                toast(`Successfully added the member!`)
                getClientRelatives()
                setIsAddingNew(false)
            } else {
                if (addClientRelativesRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
                console.log(addClientRelativesRespo)
            }
        }

        setIsAddingNow(false)
    }

    useEffect(() => {
        getClientRelatives()
    }, [clientStoreRelationIdentifier])

    const onClientDeletion = (k) => {
        toast(`Success removed ${k} from family members.`)
        getClientRelatives()
    }


    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainTitle}>
                All Family Members ({allMembers?.length})
            </div>
            <div className={styles.allMembersWrapper}>
                {allMembers?.map((item, index) => (
                    <EachFamilyMember key={index} item={item} onDeletion={onClientDeletion} />
                ))}
            </div>

            {isAddingNew === true ?
                <div className={styles.addNewMemberWrapper} >
                    <div>
                        <div className={styles.fullName}>
                            <select onChange={(e) => setSalutation(e.target.value)} >
                                <option disabled>Title</option>
                                <option>Mr.</option>
                                <option>Ms</option>
                                <option>Mrs.</option>
                                <option>Baby</option>
                            </select>

                            <input placeholder="Type full name..." autoFocus autoCapitalize="true"
                                onChange={(e) => setFullName(e.target.value)}
                                value={fullName}
                            />

                            <div className={styles.ageInputWrapper}>
                                <div className={styles.yearsWrapper}>
                                    <select onChange={(e) => setAgeYear(e.target.value)}>
                                        <option value={null}>Select Age (Year)</option>
                                        {years?.map((item, index) => (
                                            <option key={index} value={item}>{item} years</option>
                                        ))}
                                    </select>
                                </div>
                                <div className={styles.monthsWrapper}>
                                    <select onChange={(e) => setAgeMonth(e.target.value)}>
                                        <option value={null}>Select Age (Month)</option>
                                        <option value={0} >0 Month</option>
                                        <option value={1} >1 Month</option>
                                        <option value={2} >2 Months</option>
                                        <option value={3} >3 Months</option>
                                        <option value={4} >4 Months</option>
                                        <option value={5} >5 Months</option>
                                        <option value={6} >6 Months</option>
                                        <option value={7} >7 Months</option>
                                        <option value={8} >8 Months</option>
                                        <option value={9} >9 Months</option>
                                        <option value={10} >10 Months</option>
                                        <option value={11} >11 Months</option>
                                        <option value={12} >12 Months</option>
                                    </select>
                                </div>
                            </div>
                            <div className={styles.selectGenderWrapper}>
                                <select onChange={(e) => setGender(e.target.value)}>
                                    <option >Select Gender</option>
                                    <option>Female</option>
                                    <option>Male</option>
                                    <option>LGBTQ+</option>
                                    <option>Others</option>
                                </select>
                            </div>
                            <div className={styles.selectRelationWrapper}>
                                <select
                                    onChange={(e) => setRelationToFamilyHead(e.target.value)}
                                >
                                    <option >Relation to Family Head</option>
                                    {relations?.map((item, index) => (
                                        <option key={index}>{item}</option>
                                    ))}
                                </select>
                            </div>

                        </div>
                    </div>
                    <div className={styles.actionWrapper}>
                        <button onClick={() => setIsAddingNew(false)} className={styles.cancelAction} >
                            Cancel
                        </button>
                        {isAddingNow === false ?
                            <button className={styles.completeAction} onClick={() => addClientRelatives()}>
                                Submit
                            </button>
                            :
                            <button className={styles.completeAction}>
                                Please wait...
                            </button>
                        }
                    </div>
                </div>
                :
                <div className={styles.addNewMemberWrapper} >
                    <div className={styles.addNewBtn}>
                        <button onClick={() => setIsAddingNew(true)} >Add New</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default AllFamilyMembers