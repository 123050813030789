import React, { useContext, useEffect, useState } from 'react'
import NavAtHome from '../../Components/Navigators/NavAtHome'
import SessionInitiator from '../../Components/Sessions/SessionInitiator'
import styles from '../Sessions/ActiveSessions.module.css'
import { useParams } from 'react-router';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import PriceList from '../../Components/Sessions/PriceList';
import { Link } from 'react-router-dom'
import { humanReadableTimePassed } from '../../Helper/calculations';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import { MdDelete } from 'react-icons/md';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom'

const ActiveSessions = props => {
    const { sessionIdentifier } = useParams()
    const [openModal, setOpenModal] = useState(false)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const nav = useNavigate()
    const authCtx = useContext(AuthContext);
    const [currentSessions, setCurrentSessions] = useState([]);
    const [completedSessions, setCompletedSessions] = useState([])
    const [isDeleting, setIsDeleting] = useState(false)

    const [workersData, setWorkersData] = useState([])

    const initiateBooking = () => {
        setOpenModal(true)
    }

    const abortInitiation = () => {
        setOpenModal(false)
    }

    const getCurrentSessions = async () => {
        const getCurrentSessionsResponse = await fetch(BASE_URL_API + "getCurrentSessions",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getCurrentSessionsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getCurrentSessionsRespo = await getCurrentSessionsResponse.json();
            if (getCurrentSessionsRespo.status === "success") {
                getCurrentSessionsRespo.response[0] !== null ? setCurrentSessions(getCurrentSessionsRespo.response[0]) : setCurrentSessions([])
                getCurrentSessionsRespo.response[1] !== null ? setCompletedSessions(getCurrentSessionsRespo.response[1]) : setCompletedSessions([]);

            } else {
                if (getCurrentSessionsRespo.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
            console.log(getCurrentSessionsRespo);

        }
    }

    useEffect(() => {
        getCurrentSessions()
    }, [sessionIdentifier])



    const getWorkersData = async () => {
        const getWorkersDataResponse = await fetch(BASE_URL_API + "getWorkersData",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getWorkersDataResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getWorkersDataRespo = await getWorkersDataResponse.json();
            if (getWorkersDataRespo.status === 'success') {
                setWorkersData(getWorkersDataRespo.response)
            } else {
                if (getWorkersDataRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getWorkersDataRespo)
        }
    }

    useEffect(() => {
        !!sessionIdentifier && getWorkersData()
    }, [sessionIdentifier])

    useEffect(() => {
        getCurrentSessions()
        getWorkersData()
    }, [activeStore?.storeIdentifier])

    const deleteBookingHandler = async (k) => {
        if (window.confirm("Are you sure, you want to delete this booking?")) {
            setIsDeleting(true)
            const deleteBookingSessionResponse = await fetch(BASE_URL_API + "deleteBookingSession",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        bookingIdentifier: k
                    })
                });

            if (!deleteBookingSessionResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const deleteBookingSessionRespo = await deleteBookingSessionResponse.json()
                if (deleteBookingSessionRespo.status === "success") {
                    toast("Booking has been successfully deleted!")
                    nav(`/sessions`)
                    getCurrentSessions()
                } else {
                    if (deleteBookingSessionRespo.message === "tokenExpired") {
                        authCtx.logout()
                    } else {

                    }
                }
                console.log(deleteBookingSessionRespo)
            }
            setIsDeleting(false)
        } else {
            console.log("Not deleting...", k)
        }
    }




    if (activeStore === null) {
        return (
            <div>
                Please select a store!
            </div>
        )
    }


    return (
        <div className={styles.mainWrapper}>
            {/* <NavAtHome /> */}
            <ToastContainer />
            <div className={styles.sessionStoreWrapper}>
                <div className={styles.initiateBookingBtn}>
                    <button onClick={() => initiateBooking()}>Start A Booking</button>
                </div>
                {currentSessions.map((item, index) => (
                    <div className={sessionIdentifier === item?.bookingDetails?.bookingId ? styles.eachSessionWrapperSelected : styles.eachSessionWrapper} key={index}>
                        <Link to={`/sessions/` + item?.bookingDetails?.bookingId} >
                            <div className={styles.startedOnWrapper}>
                                <div>Started </div>
                                <div>{humanReadableTimePassed(item?.bookingDetails?.createdOn)}</div>
                            </div>
                            <div>
                                <div>
                                    {item?.bookingDetails?.clientName}
                                </div>
                                {/* <div>
                                    {item?.bookingDetails?.bookingId}
                                </div> */}
                            </div>
                            <div className={styles.clientCount}>
                                {item.bookingDetails.femaleQuant > 0 &&
                                    <div className={styles.eachCol}>
                                        <div>
                                            Female :
                                        </div>
                                        <div>
                                            {item.bookingDetails.femaleQuant}
                                        </div>
                                    </div>
                                }
                                {item.bookingDetails.maleQuant > 0 &&
                                    <div className={styles.eachCol}>
                                        <div>
                                            Male :
                                        </div>
                                        <div>
                                            {item.bookingDetails.maleQuant}
                                        </div>
                                    </div>
                                }
                                {item.bookingDetails.othersQuant > 0 &&
                                    <div className={styles.eachCol}>
                                        <div>
                                            Others :
                                        </div>
                                        <div>
                                            {item.bookingDetails.othersQuant}
                                        </div>
                                    </div>
                                }
                            </div>
                            {item?.allServices?.length > 0 &&
                                <div className={styles.servicesSection}>
                                    <div>
                                        {item?.allServices?.map((itemC, indexC) => (
                                            <div key={indexC} className={styles.eachService}>
                                                {itemC?.categoryName} - {itemC?.serviceName}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }

                            <div className={styles.actionsWrapper}>
                                <button className={styles.deleteBookingBtn}
                                    onClick={() => deleteBookingHandler(item?.bookingDetails?.bookingId)}
                                >
                                    <MdDelete size={20} />
                                </button>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
            <div className={styles.sessionDetailsWrapper}>
                {!!sessionIdentifier ?
                    <PriceList
                        sessionIdentifier={sessionIdentifier}
                        workersData={workersData}
                    />
                    :
                    <div className={styles.shortCutKeyWrapper}>
                        <div className={styles.shortCutKeyTitle}>
                            Shortcut Keys
                        </div>
                        <div>
                            <div className={styles.sckWrapper}>
                                <div>
                                    Press "S" to start a new booking
                                </div>
                                <div>
                                    Press "M" to select male client
                                </div>
                                <div>
                                    Press "F" to select female client
                                </div>
                                <div>
                                    Press "O" to select other client
                                </div>
                                <div>
                                    Press "Enter" to initiate a booking / session
                                </div>
                                <div>
                                    Press "Esc" to close popups
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <SessionInitiator
                    isOpen={openModal}
                    abortInitiation={abortInitiation}
                    initiateBooking={initiateBooking}
                />

            </div>
        </div>
    )
}

export default ActiveSessions