import React, { useEffect, useRef, useState } from 'react'
import { FaClosedCaptioning, FaWindowClose } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import styles from '../Sessions/AddFlatDiscount.module.css'


const AddFlatDiscount = props => {
    const isFlatDiscountOpen = props.isFlatDiscountOpen
    const flatDiscountBoxController = props.flatDiscountBoxController
    const flatDiscountBy = props.flatDiscountBy
    const toggleDiscountBy = props.toggleDiscountBy
    const netPayableAmount = props.netPayableAmount

    const flatDiscount = props.flatDiscount
    const flatDiscountPercentage = props.flatDiscountPercentage
    const updateFlatDiscount = props.updateFlatDiscount
    const updateFlatDiscountPercentage = props.updateFlatDiscountPercentage

    const totalServiceDiscountedAmount = props.totalServiceDiscountedAmount

    const [inputVal, setInputVal] = useState(0)
    const discInput = useRef(null)

    const flatDiscountHandler = () => {
        if (flatDiscountBy === 'A') {
            updateFlatDiscount(inputVal!== '' ? inputVal : 0)
            let dByP = parseFloat((inputVal / totalServiceDiscountedAmount) * 100).toFixed(1)
            updateFlatDiscountPercentage(isNaN(dByP) ? 0 : dByP)
        } else {
            updateFlatDiscountPercentage(inputVal!== '' ? inputVal : 0)
            let dByA = totalServiceDiscountedAmount * (inputVal / 100)
            dByA = parseFloat(dByA).toFixed(1)
            updateFlatDiscount(isNaN(dByA) ? 0 : dByA)
        }
    }

    useEffect(() => {
        flatDiscountHandler()
    }, [inputVal, flatDiscountBy])


    useEffect(() => {
        window.onclick = function (event) {
            if (event.target.id === "flatDiscountBox") {
                flatDiscountBoxController()
            }

            if (event.target.id == "filtersPanelWrapperMUG") {
                document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft .1s forwards";
            }
        };

        document.addEventListener('keydown', function (e) {
            if (e.keyCode == 27) {
                flatDiscountBoxController()
            }
        });

        discInput?.current?.select()
    }, [isFlatDiscountOpen])


    if (isFlatDiscountOpen === false) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}
                    id="flatDiscountBox"
                    style={{ animation: `0.12s cubic-bezier(0, 0.63, 0.26, 0.96) 0s 1 normal forwards running slideBottomToTop` }}
                >
                    <div className={styles.mainContent}>
                        <div className={styles.fdHead}>
                            <div className={styles.closeBtnWrapper}>
                                <button onClick={() => flatDiscountBoxController()} >
                                    <MdClose size={24} />
                                </button>
                            </div>
                            <div className={styles.headTitle}>
                                Add Flat Discount
                            </div>
                        </div>
                        <div className={styles.fDCols}>
                            <div className={styles.netPayableNowWrapper}>
                                <div>
                                    <div>
                                        &#8377;{totalServiceDiscountedAmount}/-
                                    </div>
                                    <div>
                                        Total Payabale Amount
                                    </div>
                                </div>
                                <div className={styles.netPayableNow}>
                                    &#8377;{netPayableAmount}/-
                                </div>
                                <div>
                                    After {flatDiscountBy === 'A' ? <span> {flatDiscountPercentage}% </span>: <span>&#8377;{flatDiscount}</span>} additional discount
                                </div>
                            </div>
                            <div>
                                <div className={styles.dByWrapper}>
                                    <div className={flatDiscountBy === 'P' ? styles.activeDy : styles.defaultDy}
                                        onClick={() => toggleDiscountBy('P')}
                                    >
                                        By Percentage
                                    </div>
                                    <div className={flatDiscountBy === 'A' ? styles.activeDy : styles.defaultDy}
                                        onClick={() => toggleDiscountBy('A')}
                                    >
                                        By Amount
                                    </div>
                                </div>
                                <div className={styles.discountInputWrapper}>
                                    <input placeholder="Enter discount value..."
                                        autoFocus={true}
                                        onChange={(e) => setInputVal(e.target.value)}
                                        value={inputVal}
                                        ref={discInput}
                                    />
                                </div>
                                <div className={styles.applyBtnWrapper}>
                                    <button onClick={() => flatDiscountBoxController()} >
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddFlatDiscount