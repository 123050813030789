import React, { useContext, useEffect, useState } from 'react'
import NavAtHome from '../../Components/Navigators/NavAtHome'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from '../StoreLedger/Ledger.module.css'

const Ledger = () => {
    const authCtx = useContext(AuthContext)
    const storeIdentifier = authCtx.storeIdentifier;
    const [allGroups, setAllGroups] = useState([])

    const getStoreLedger = async () => {
        const getStoreLedgerResponse = await fetch(BASE_URL_API + "getStoreLedger",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: storeIdentifier
                })
            });

        if (!getStoreLedgerResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getStoreLedgerRespo = await getStoreLedgerResponse.json();
            if (getStoreLedgerRespo.status === 'success') {
                setAllGroups(getStoreLedgerRespo.response)
            } else {
                if (getStoreLedgerRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getStoreLedgerRespo)
        }
    }

    useEffect(() => {
        if (storeIdentifier !== undefined) {
            getStoreLedger()
        }
    }, [storeIdentifier])




    return (
        <div className={styles.mainWrapper}>
            <NavAtHome />
            <div>
                {allGroups.map((item, index) => (
                    <div key={index} className={styles.eachTransactionWrapper}>
                        <div className={styles.clientName} >
                            {item?.client_name}
                        </div>
                        <div className={styles.mobile}>
                            {item?.mobile}
                        </div>
                        <div className={styles.gender}>
                            {item?.gender}
                        </div>
                        <div className={styles.td}>
                            {item?.transactionDate}
                        </div>
                        <div className={styles.tt}>
                            {item?.transactionType}
                        </div>
                        <div className={styles.amount}>
                            {item?.amount<0 ? `-₹${(item?.amount)*(-1)}` : `₹${item?.amount}`}
                        </div>
                        <div className={styles.currentBalance}>
                            {item?.currentBalance<0 ? `-₹${(item?.currentBalance)*(-1)}` : `₹${item?.currentBalance}`}
                        </div>
                    </div>

                ))}
            </div>
        </div>
    )
}

export default Ledger