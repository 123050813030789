import React from 'react'
import styles from '../Bookings/CSBookingDetails.module.css'

const CSBookingDetails = props => {
    const item = props.item
    return (
        <div className={styles.mainWrapper}>
            <div>
                Created On : {item.bookingDetails["createdOn"]}
            </div>
            <div>
                Current Status : {item.bookingDetails["currentStatus"]}
            </div>
            <div>
                Booking ID : {item.bookingDetails["bookingId"]}
            </div>
            <div>
                Client Name : {item.bookingDetails['clientName']}
            </div>
        </div>
    )
}

export default CSBookingDetails