import React, { useContext, useEffect, useState } from 'react'
import { BiCalendarEvent } from 'react-icons/bi';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import styles from '../Membership/TransactionsTable.module.css';
import EachTransactions from './EachTransactions';

const TransactionsTable = props => {
    const authCtx = useContext(AuthContext)
    const cardNumber = props.cardIdentifier;
    const [transactions, setTransactions] = useState([])

    const getAllCardsTransactions = async () => {
        const getAllCardsTransactionsResponse = await fetch(BASE_URL_API + "getAllCardsTransactions",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    cardIdentifier: cardNumber
                })
            });

        if (!getAllCardsTransactionsResponse.ok) {

        } else {
            const getAllCardsTransactionsRespo = await getAllCardsTransactionsResponse.json();
            if (getAllCardsTransactionsRespo.status === 'success') {
                setTransactions(getAllCardsTransactionsRespo.response)
            } else {
                if (getAllCardsTransactionsRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    setTransactions([])
                }
            }
            console.log(getAllCardsTransactionsRespo)
        }
    }

    useEffect(() => {
        getAllCardsTransactions()
    }, [cardNumber])


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div className={styles.mainHeaderUpper}>
                    <div className={styles.headerTitle}>Transactions</div>
                    <div className={styles.headerIcon}>
                        <BiCalendarEvent />
                    </div>
                </div>
                <div className={styles.mainHeaderLower}>
                    <button className={styles.eachFilter}>
                        All
                    </button>
                    <button className={styles.eachFilter}>
                        Added
                    </button>
                    <button className={styles.eachFilter}>
                        Expenses
                    </button>
                </div>
            </div>
            <div className={styles.mainBody}>
                {transactions.length > 0 ?
                    transactions.map((item, index) => (
                        <EachTransactions 
                            item={item}
                            key={index}
                        />
                    ))
                    :
                    <div>
                        No transactions found!
                    </div>
                }
            </div>
        </div>
    )
}

export default TransactionsTable