import React from 'react'
import styles from './ConfigMembershipCNCCategoryAndServices.module.css'

const ConfigMembershipCNCCategoryAndServices = ({item, selectServiceHandler }) => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div onClick={() => selectServiceHandler(item)} >
                    Services of {item}
                </div>
            </div>
        </div>
    )
}

export default ConfigMembershipCNCCategoryAndServices