import React from 'react'
import { BsCheckSquareFill, BsSquare } from 'react-icons/bs'
import styles from './ConfigMembershipCNCEachStore.module.css'

const ConfigMembershipCNCEachStore = ({ item, isSelected, toggleStoreHandler, toggleActiveStoreHandler }) => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} onClick={() => toggleActiveStoreHandler(item)} >
                <div onClick={() => toggleStoreHandler(item)} className={styles.checkBoxIcon}>
                    {isSelected ? <BsCheckSquareFill size={16} color="#00000090" /> : <BsSquare size={16} color="#dfdfdf" />}
                </div>
                <div>
                    <div>
                        Each Store - {item}
                    </div>
                    <div className={styles.address}>
                        Shop 101, Eldeco
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfigMembershipCNCEachStore