import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from './AllPurchaseCardsAtClientPage.module.css'
import { Link } from "react-router-dom";


const AllPurchaseCardsAtClientPage = props => {
    const { clientStoreRelationIdentifier } = props
    const authCtx = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)
    const [allCards, setAllCards] = useState([])

    const getCardsForPaymentCollection = async () => {
        setIsLoading(true)
        const getCardsForPaymentCollectionResponse = await fetch(BASE_URL_API + "/getCardsForPaymentCollection",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    clientStoreRelationIdentifier: clientStoreRelationIdentifier
                })
            });

        if (!getCardsForPaymentCollectionResponse.ok) {
            console.log("Something went wrong, Server Error")
        } else {
            const getCardsForPaymentCollectionRespo = await getCardsForPaymentCollectionResponse.json()
            if (getCardsForPaymentCollectionRespo.status === 'success') {
                setAllCards(getCardsForPaymentCollectionRespo.response)
            } else {
                if (getCardsForPaymentCollectionRespo.status === 'Invalid token, please login again') {
                    authCtx.logout()
                } else {
                    setAllCards([])
                }
            }
            console.log(getCardsForPaymentCollectionRespo)
        }
        setIsLoading(false)
    }


    useEffect(() => {
        getCardsForPaymentCollection()
    }, [clientStoreRelationIdentifier])



    return (
        <div>
            {isLoading === true ?
                <div>
                    Loading cards...
                </div>
                :
                <div>
                    <div>
                        {allCards.length > 0 ?
                            <div className={styles.showingTxt}>
                                Showing All ({allCards.length}) available cards
                            </div>
                            :
                            <div className={styles.showingTxt}>
                                No cards available
                            </div>
                        }
                    </div>
                    <div className={styles.allCardsWrapper}>
                        {allCards.map((item, index) => (
                            <Link to={`/membership/` + item.cardIdentifier} key={index} className={styles.eachCardWrapper}>
                                <div className={styles.cardIdentifier}>
                                    <span>
                                        {item.cardIdentifier.substring(0, 4)}&nbsp;&nbsp;
                                    </span>
                                    <span>
                                        {item.cardIdentifier.substring(4, 8)}&nbsp;&nbsp;
                                    </span>
                                    <span>
                                        {item.cardIdentifier.substring(8, 12)}&nbsp;&nbsp;
                                    </span>
                                    <span>
                                        {item.cardIdentifier.substring(12, 16)}
                                    </span>
                                </div>
                                <div className={styles.bottomRow}>
                                    <div className={styles.cardName}>
                                        {item.cardName}
                                    </div>
                                    <div className={styles.availableBalance}>
                                        &#8377;{item.availableBalance}
                                    </div>
                                </div>
                            </Link>
                        ))}
                        <Link to="/memberships/sell" className={styles.addNewCardWrapper}>
                            Add A New Card +
                        </Link>
                    </div>
                </div>
            }
        </div>
    )
}

export default AllPurchaseCardsAtClientPage